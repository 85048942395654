import { Typography } from '@mui/material'
import { date as dateFormatter } from '@trr/frontend-datetime'
import React from 'react'
import ShareButtons from 'apps/Site/components/ShareButtons'

import {
  DetailsBarWrapper,
  TopContent,
  Bar,
  ScreenReaderOnlyText,
  BottomContent,
} from './DetailsBar.styles'

export interface DetailsBarProps {
  category?: string
  date?: string
  isPublicPage: boolean
}

const DetailsBar = ({ category, date, isPublicPage }: DetailsBarProps) => {
  const formattedDate =
    date &&
    dateFormatter.format(date, { style: 'numeric' })?.replaceAll('-', '/')

  return (
    <DetailsBarWrapper>
      <TopContent>
        <div>
          {category && (
            <>
              <ScreenReaderOnlyText>Kategori</ScreenReaderOnlyText>
              <Typography
                variant="subtitle2"
                component="p"
                sx={{ color: 'text.secondary' }}
              >
                {category}
              </Typography>
            </>
          )}
        </div>
        <>
          <ScreenReaderOnlyText>Dela</ScreenReaderOnlyText>
          <ShareButtons isPublicPage={isPublicPage} />
        </>
      </TopContent>

      <Bar />
      {formattedDate && (
        <BottomContent>
          <Typography variant="subtitle2" component="h2">
            Publicerad
          </Typography>
          <Typography
            variant="subtitle2"
            component="p"
            sx={{ color: 'text.secondary' }}
          >
            {formattedDate}
          </Typography>
        </BottomContent>
      )}
    </DetailsBarWrapper>
  )
}

export default DetailsBar
