import React from 'react'
import { ListItem } from '@mui/material'
import { FontIcon } from '@internal-helpers'
import LinkedIn from '@mui/icons-material/LinkedIn'
import { visitorContext } from 'slices/VisitorContext/types'
import { useAuthentication } from 'features/Authentication/Hooks'
import { FooterLink } from 'PublicWebApi/Settings/types'
import { useSliceStateSelector } from 'slices/hooks'
import { ContactLink } from '../ContactLinksColumn.styles'

interface ContactLinksProps {
  links: FooterLink[]
}

const ContactLinks = ({ links }: ContactLinksProps) => {
  const { visitorContextStatus } = useSliceStateSelector(
    (state) => state.slices.visitorContexts
  )

  const { isLoggedIn } = useAuthentication()

  const getLoginUrl = (
    loginBaseUrl: string,
    context: visitorContext
  ): string => {
    const loginUrl =
      context === 'privatperson' ? loginBaseUrl : loginBaseUrl + '/arbetsgivare'

    return loginUrl
  }

  return (
    <>
      {links?.map((link, index) =>
        link.linkText === 'Logga in' && isLoggedIn ? null : (
          <ListItem key={`contactlink-${index.toString()}`}>
            <ContactLink
              data-testid={`contactlink-${link.linkText}`}
              href={
                link.linkText === 'Logga in'
                  ? getLoginUrl('/login', visitorContextStatus)
                  : link.url
              }
              target={link.linkText === 'LinkedIn' ? '__blank' : undefined}
            >
              {link.iconName === 'LinkedIn' ? (
                <LinkedIn data-testid="footer-linkedin-icon" />
              ) : (
                <FontIcon
                  iconName={link.iconName}
                  data-testid={`icon-${link.iconName}`}
                />
              )}
              {link.linkText}
            </ContactLink>
          </ListItem>
        )
      )}
    </>
  )
}

export default ContactLinks
