import React from 'react'

import { FooterLink } from 'PublicWebApi/Settings/types'
import { Link, List, ListItem, styled } from '@mui/material'
import Surfly from 'apps/Site/Surfly/Surfly'
import { useCachedFeatureFlagsState } from 'PublicWebApi'

interface LegalLinksProps {
  links: FooterLink[]
}

const LegalLinksWrapper = styled(List)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: `${theme.spacing(5.25)} ${theme.spacing(1)}`,
  gap: theme.spacing(2),

  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(3),
    padding: `${theme.spacing(3.5)} 0`,
    flexDirection: 'row',
  },
}))

const LegalLink = styled(Link)(({ theme }) => ({
  ...theme.typography.subtitle2,
  padding: theme.spacing(0.25),
  color: 'white',
  whiteSpace: 'nowrap',
  border: 'solid',
  borderRadius: '2px',
  borderColor: 'transparent',
  borderWidth: '1px',

  '&:hover, &:active': {
    textDecoration: 'underline',
  },

  '&:focus': {
    outline: 'none',
    borderColor: 'white',

    '&:not(:focus-visible)': {
      borderColor: 'transparent',
    },
  },
}))

const LegalLinks = ({ links }: LegalLinksProps) => {
  const surflyFeature = useCachedFeatureFlagsState().includes('surfly')
  return (
    <LegalLinksWrapper>
      {links?.map((link, index) => (
        <ListItem key={`legallinkitem-${index.toString()}`}>
          <LegalLink data-testid={`legallink-${link.linkText}`} href={link.url}>
            {link.linkText}
          </LegalLink>
        </ListItem>
      ))}
      {surflyFeature && (
        <ListItem>
          <Surfly />
        </ListItem>
      )}
    </LegalLinksWrapper>
  )
}

export default LegalLinks
