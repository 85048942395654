import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { MenusState, smallDeviceMenuStatus } from './types'

const initialState: MenusState = { smallDeviceMenuStatus: 'none' }

const menusSlice = createSlice({
  name: 'menus',
  initialState,
  reducers: {
    setSmallDeviceMenuStatus: (
      state,
      action: PayloadAction<smallDeviceMenuStatus>
    ): MenusState => ({
      ...state,
      smallDeviceMenuStatus: action.payload,
    }),
  },
})

export default menusSlice
