import React from 'react'
import { TableOfContents } from 'apps/Site/components/TableOfContents'
import RelatedContent from 'apps/Site/components/RelatedContent/index'
import { ArticleListItemProps } from 'apps/Site/components/ArticleList/ArticleListItem'
import blockMapper from 'apps/Site/Epi/BlockMapper'
import HTMLMapper from 'apps/Site/Epi/HTMLMapper'
import { ScrollWrapper } from 'apps/Site/Epi/ScrollWrapper'
import { Introduction } from 'apps/Site/Epi/Blocks'
import { IContentBlock } from 'apps/Site/Epi/BlockMapper/BlockMapper'
import { IntroductionContentProps } from 'apps/Site/Epi/Blocks/Introduction/Introduction'
import { TableOfContentLink } from 'state/PublicWebApi/Content'
import { Grid } from '@mui/material'

interface IFaqProps {
  blocks?: IContentBlock[]
  editor?: string
  introduction?: IntroductionContentProps
  tableOfContents?: TableOfContentLink[]
  relatedContent?: ArticleListItemProps[]
}

const Faq = ({
  blocks,
  editor,
  introduction,
  tableOfContents,
  relatedContent,
}: IFaqProps) => {
  return (
    <ScrollWrapper>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Introduction content={introduction} />
          <TableOfContents items={tableOfContents} />
          <HTMLMapper body={editor} wrapper={'section'} />
          {relatedContent?.length > 0 && (
            <RelatedContent relatedContent={relatedContent} />
          )}
        </Grid>
        {blocks.length > 0 && (
          <Grid item xs={12}>
            {blockMapper({ blocks })}
          </Grid>
        )}
      </Grid>
    </ScrollWrapper>
  )
}

export default Faq
