import React, { createElement, PropsWithChildren } from 'react'

import { Link, RowBreak, Group } from '../MenuBlocks'
import { MenuBlock } from 'state/PublicWebApi/Settings'

interface MenuBlockMap {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

const blockTypeMap: MenuBlockMap = {
  MenuSingleLinkBlock: Link,
  RowBreakBlock: RowBreak,
  MenuGroupBlock: Group,
}

const MenuBlockMapper = ({
  blockType,
  ...props
}: PropsWithChildren<MenuBlock>) => {
  return (
    <>
      {createElement(blockTypeMap[blockType], {
        ...props,
      })}
    </>
  )
}

export default MenuBlockMapper
