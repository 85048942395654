/* eslint-disable @typescript-eslint/explicit-function-return-type */
import {
  HTMLReactParserOptions,
  domToReact,
  htmlToDOM,
} from 'html-react-parser'
import { ElementMap, IReplaceData } from '../types'
import { generateRenderChildren } from './generateRenderChildren'
import sanitizeBody from './sanitizeBody'

interface ParseBodyOptions {
  mediaUrl?: string
  replaceValues?: Record<string, unknown>
  allowedTags?: string[]
  isLoggedIn?: boolean
}

const parse = (
  html: string,
  options?: HTMLReactParserOptions
): ReturnType<typeof domToReact> => {
  if (typeof html !== 'string') {
    throw new TypeError('First argument must be a string')
  }

  if (!html) {
    return []
  }

  return domToReact(
    htmlToDOM(html, options?.htmlparser2 || { lowerCaseAttributeNames: false }),
    options
  )
}

export const parseBody = (
  body: string,
  elements: ElementMap,
  options: ParseBodyOptions
) => {
  const sanitizedBody = sanitizeBody(
    body,
    options.replaceValues ?? {},
    options.allowedTags
  )
  const parseOptions: HTMLReactParserOptions = {
    replace: (domNode) => {
      const { attribs, name, children } = domNode as unknown as IReplaceData
      const element = elements[name]
      const mediaUrl = options.mediaUrl
      const isLoggedIn = options.isLoggedIn
      if (element) {
        const renderChildren = generateRenderChildren(children, parseOptions)
        const parseHTML = (html: string) => parse(html, parseOptions)
        return element({
          children,
          attribs,
          mediaUrl,
          isLoggedIn,
          renderChildren,
          parseHTML,
        })
      }
    },
  }

  return parse(sanitizedBody, parseOptions)
}
