import { createElement } from 'react'
import configService from 'utils/configService'
import { useAuthentication } from 'features/Authentication/Hooks'

import BlockMap from './BlockMap'

export interface IBlockMapper {
  blocks: IContentBlock[]
}

export interface IContentBlock {
  id: number
  type: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties: any
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const BlockMapper = ({ blocks = [] }: IBlockMapper) => {
  const authState = useAuthentication()

  return (
    blocks.length > 0 &&
    blocks.map(
      ({ type, properties, id }, index) =>
        !!BlockMap[type] &&
        createElement(BlockMap[type], {
          id,
          content: {
            ...properties,
            mediaUrl: configService.config.MEDIA_URL,
            isLoggedIn: authState.isLoggedIn,
          },
          key: `${type}${index}`,
        })
    )
  )
}

export default BlockMapper
