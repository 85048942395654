import { styled } from '@mui/material/styles'
import { Box, Avatar, Typography } from '@mui/material'

interface ComponentProp {
  component?: string
}

export const Heading = styled(Typography)<ComponentProp>(({ theme }) => ({
  letterSpacing: '-0.3px',
  display: 'block',
  lineHeight: 1.4,
  margin: 0,
  fontWeight: theme.typography.fontWeightBold,
  fontSize: '1.125rem',
}))

export const BulletPointMargin = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(4.5),
}))

export const Body = styled(Typography)<ComponentProp>(({ theme }) => ({
  ...theme.typography.body2,
  fontSize: '1rem',
  display: 'block',
}))

export const LinkWrapper = styled(Box)(({ theme }) => ({
  display: 'block',
  marginTop: theme.spacing(1),
}))

export const TextWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}))

export const AvatarPoint = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(3),
}))

export const AvatarWrapper = styled(Avatar)(({ theme }) => ({
  flexShrink: 0,
  fontWeight: theme.typography.fontWeightMedium,
  backgroundColor: theme.palette.surface?.green,
  color: theme.palette.secondary.main,
  height: '48px',
  width: '48px',
}))
