import React from 'react'
import { styled } from '@mui/material'

export type OrderedListProps = JSX.IntrinsicElements['ol']

export const StyledOl = styled('ol')(({ theme }) => ({
  marginTop: theme.spacing(0),
  marginBottom: theme.spacing(5),
  counterReset: 'list',
  paddingInlineStart: theme.spacing(7),
  '> li': {
    counterIncrement: 'list',
    listStyle: 'none',
    marginBottom: theme.spacing(2),
    position: 'relative',
    '&::before': {
      content: 'counter(list)',
      background: theme.palette.primary.light,
      width: theme.spacing(3),
      height: theme.spacing(3),
      position: 'absolute',
      left: theme.spacing(-5),
      borderRadius: '50%',
      color: theme.palette.primary.main,
      fontSize: '14px',
      textAlign: 'center',
      lineHeight: '1.65',
      top: 2,
    },
    '> ul': {
      marginBottom: 0,
      marginTop: theme.spacing(2),
      paddingInlineStart: theme.spacing(3),
    },
    '> ol': {
      marginBottom: 0,
      marginTop: theme.spacing(2),
      paddingInlineStart: '36px',
    },
  },
}))

export const OrderedList = (attribs: OrderedListProps): JSX.Element => {
  return <StyledOl {...attribs} />
}
