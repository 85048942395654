import { convertLinksIfPr } from 'utils/urlTransition'
import { Settings } from './types'
import { publicWebApi } from 'state/PublicWebApi'

export const settingsApi = publicWebApi.injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.query<Settings, void>({
      query: () => `settings/content`,
      providesTags: ['Settings'],
      transformResponse: (settings: { epiSettings: Settings }) =>
        convertLinksIfPr(settings.epiSettings),
    }),
  }),
})

/**
 * A hook that returns the last cached settings state.
 * Needs to be ran in a component that is a child of ```<PrepareAppShell />```
 *
 * Will throw an error if ran outside of ```<PrepareAppShell />```
 *
 *
 * @returns
 *  ```
 * Settings
 * ```
 */
export const useCachedSettingsState = () => {
  const { data } = settingsApi.endpoints.getSettings.useQueryState()
  if (data) {
    return data
  }
  throw new Error(
    'This hook needs to be ran in a component that is a child of <PrepareAppShell />, or the endpoint has failed'
  )
}

export default settingsApi
