import { createElement } from 'react'
import CheckCircle from '@mui/icons-material/CheckCircle'
import Clear from '@mui/icons-material/Clear'
import Error from '@mui/icons-material/Error'
import Info from '@mui/icons-material/Info'
import Warning from '@mui/icons-material/Warning'
import { LinkProps } from '@mui/material/Link'
import { ThemeOptions } from '@mui/material'
import { svSE } from '@mui/material/locale'
import { generateStatusComponentStyling } from './utils/generateStatusComponent'
import { default as baseLineCss } from './baseLineCss'
import type {} from '@mui/x-date-pickers/themeAugmentation'
import type {} from '@mui/x-data-grid/themeAugmentation'
import { ReactRouterAdapterLink, ActionBar } from './components'

import {
  accentColors,
  errorColors,
  infoColors,
  neutralColors,
  primaryColors,
  secondaryColors,
  stateColors,
  successColors,
  surfaceColors,
  warningColors,
  AccentColors,
  NeutralColors,
  SurfaceColors,
  textColors,
} from './colors'
import {
  typographyOptions,
  typographyVariants,
} from './theme-sections/typograhy'
import { ResponsiveFontSizesOptions } from '@mui/material/styles/responsiveFontSizes'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    display: React.CSSProperties
    preamble: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    display?: React.CSSProperties
    preamble?: React.CSSProperties
  }

  interface Palette {
    accent?: AccentColors
    surface?: SurfaceColors
    neutral?: NeutralColors
  }

  interface PaletteOptions {
    accent?: AccentColors
    surface?: SurfaceColors
    neutral?: NeutralColors
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    display: true
    preamble: true
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    'status-success': true
    'status-info': true
    'status-error': true
    'status-warning': true
  }
}

declare module '@mui/material/Tabs' {
  interface TabsOwnProps {
    /**
     * The variant to use.
     * Provided by Cirkusen's adaptation of MUI Tabs.
     * _Not_ provided by MUI.
     *
     * @default undefined
     */
    size?: 'small'
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    neutral: true
  }
}

const defaultDateFormat = 'yyyy-MM-dd'

export const responsiveFontSizeOptions: ResponsiveFontSizesOptions = {
  variants: typographyVariants,
}

export const themeOptions: ThemeOptions = {
  palette: {
    primary: primaryColors,
    secondary: secondaryColors,
    accent: accentColors,
    neutral: neutralColors,
    error: errorColors,
    warning: warningColors,
    info: infoColors,
    success: successColors,
    surface: surfaceColors,
    text: textColors,
  },
  ...typographyOptions,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1920,
    },
  },
  components: {
    ...typographyOptions.components,
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: ReactRouterAdapterLink,
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        size: 'medium',
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: {
          display: 'inline-flex',
          width: 'max-content',
          borderRadius: 24,
          textTransform: 'none',
          lineHeight: '24px',
          padding: '12px 24px',
          boxShadow: 'none',
          '&:hover, &:focus': {
            boxShadow: 'none',
          },
          '&:focus': {
            backgroundColor: primaryColors.dark,
          },
        },
        outlined: {
          '&:focus': {
            backgroundColor: stateColors.outlinedButtonHover,
            border: `1px solid ${primaryColors.main}`,
          },
        },
        text: {
          '&:focus': {
            backgroundColor: stateColors.outlinedButtonHover,
          },
        },
        sizeSmall: {
          fontSize: '0.875rem',
          padding: '6px 12px',
        },
      },
    },
    MuiAlert: {
      variants: [
        {
          props: { severity: 'info' },
          style: { backgroundColor: surfaceColors.blue },
        },
        {
          props: { severity: 'error' },
          style: { backgroundColor: surfaceColors.pink },
        },
        {
          props: { severity: 'success' },
          style: { backgroundColor: surfaceColors.green },
        },
        {
          props: { severity: 'warning' },
          style: { backgroundColor: surfaceColors.orange },
        },
      ],
      defaultProps: {
        iconMapping: {
          error: createElement(Error),
          info: createElement(Info),
          success: createElement(CheckCircle),
          warning: createElement(Warning),
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        color: 'neutral',
      },
    },
    MuiIcon: {
      variants: [
        {
          props: { fontSize: 'small' },
          style: { fontSize: '20px' },
        },
        {
          props: { fontSize: 'medium' },
          style: { fontSize: '24px' },
        },
        {
          props: { fontSize: 'large' },
          style: { fontSize: '40px' },
        },
      ],
    },
    MuiSvgIcon: {
      variants: [
        {
          props: { fontSize: 'small' },
          style: { fontSize: '20px' },
        },
        {
          props: { fontSize: 'medium' },
          style: { fontSize: '24px' },
        },
        {
          props: { fontSize: 'large' },
          style: { fontSize: '40px' },
        },
      ],
    },
    MuiRadio: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiChip: {
      defaultProps: {
        variant: 'outlined',
        deleteIcon: createElement(Clear),
      },
      styleOverrides: {
        outlined: {
          color: neutralColors.main,
          '& .MuiChip-deleteIcon': {
            color: neutralColors.main,
          },
          '&.MuiButtonBase-root': {
            '&.MuiChip-clickable:focus': {
              backgroundColor: stateColors.outlinedChipHover,
            },
          },
        },
        filled: {
          backgroundColor: secondaryColors.light,
          color: secondaryColors.dark,
          '& .MuiSvgIcon-root': {
            color: secondaryColors.dark,
          },
          '& .MuiChip-deleteIcon': {
            '&:hover': {
              color: secondaryColors.main,
            },
            fontSize: '1.375rem',
          },
          '&.MuiButtonBase-root': {
            '&.MuiChip-clickable:hover, &.MuiChip-clickable:focus': {
              backgroundColor: stateColors.filledChipHover,
            },
          },
        },
        root: {
          fontSize: '0.875rem',
          lineHeight: '20px',
          fontWeight: 500,
          width: 'max-content',
        },
      },
      variants: [
        {
          props: { variant: 'status-success' },
          style: generateStatusComponentStyling(successColors.main),
        },
        {
          props: { variant: 'status-info' },
          style: generateStatusComponentStyling(infoColors.main),
        },
        {
          props: { variant: 'status-error' },
          style: generateStatusComponentStyling(errorColors.main),
        },
        {
          props: { variant: 'status-warning' },
          style: generateStatusComponentStyling(warningColors.main),
        },
      ],
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          height: '56px',
          borderRadius: '24px 24px 0 0',
          padding: '0px 56px',
          '&.Mui-selected': {
            backgroundColor: stateColors.tabSelected,
            '&:hover': {
              backgroundColor: stateColors.tabSelected,
            },
          },
          '&:hover': {
            backgroundColor: stateColors.tabHover,
          },
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        indicatorColor: null,
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            '& .MuiTab-root': {
              height: '48px',
              padding: '14px 32px',
            },
          },
        },
      ],
    },
    MuiAvatar: {
      defaultProps: {
        sx: {
          width: '40px',
          height: '40px',
        },
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        size: 32,
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MuiListItem: {
      defaultProps: {
        disablePadding: true,
      },
    },
    MuiListItemText: {
      styleOverrides: {
        secondary: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiCard: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: '24px 24px 0px 24px',
          // if header sub component is in use, then we want
          // the card content to have less top padding. 12px instead of 24px.
          '& + .MuiCardContent-root': {
            paddingTop: '12px',
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px',
          // For now will not work with Firefox. As of 2023-09-16, the :has selector is in development.
          // This is accepted as it's such a minor difference in style.
          '&:has(+ div.MuiCardActions-root)': {
            paddingBottom: '12px',
          },
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '0px 16px 16px 12px',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'none',
        component: ReactRouterAdapterLink,
        // Limitations in MUI typing requires casting to LinkProps to use 3rd party links
      } as LinkProps,
    },
    MuiPagination: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiMenuItem: {
      defaultProps: {
        sx: {
          paddingY: '16px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '0.875rem',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          svg: {
            color: textColors.secondary,
          },
          '& .MuiInputLabel-shrink': {
            transform: 'translate(14px, -9px) scale(0.875)',
          },
          '& fieldset > legend': {
            fontSize: '0.875rem',
          },
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionStart: {
          paddingRight: '16px',
          margin: 0,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: stateColors.tableRowSelected,
            '&:hover': {
              backgroundColor: stateColors.tableRowHover,
            },
          },
        },
      },
    },
    MuiDataGrid: {
      defaultProps: {
        slotProps: {
          baseButton: {
            variant: 'text',
          },
        },
      },
      styleOverrides: {
        root: {
          '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: stateColors.tableRowSelected,
            '&:hover': {
              backgroundColor: stateColors.tableRowHover,
            },
          },
        },
        // Wrapper for both 'Columns' and 'Filter' menus
        panelWrapper: {
          padding: '8px',
        },
        columnsManagementRow: {
          padding: '40px',
          margin: '50px',
          '&:not(:last-child)': {
            paddingBottom: '16px',
          },
        },

        // Footer for 'Columns' menu
        panelFooter: {
          justifyContent: 'end',
        },
        // Wrapper for 'Density' and 'Export' menus
        menu: {
          padding: '8px',
        },
      },
    },
    MuiDatePicker: {
      defaultProps: {
        disableHighlightToday: true,
        format: defaultDateFormat,
      },
    },
    MuiDesktopDatePicker: {
      defaultProps: {
        format: defaultDateFormat,
      },
    },
    MuiMobileDatePicker: {
      defaultProps: {
        disableHighlightToday: true,
        label: 'Välj datum',
        format: defaultDateFormat,
        slots: {
          actionBar: ActionBar,
        },
      },
    },
    MuiTimePicker: {
      defaultProps: {
        label: 'Välj tid',
      },
    },
    MuiCssBaseline: {
      styleOverrides: baseLineCss,
    },
  },
}

export const defaultLocale = svSE
