import React from 'react'
import { FunctionPageProperties } from 'state/PublicWebApi/Content'
import MicroFrontendLoader from 'features/SystemLoaders/MicroFrontendLoader'

export const FullFunction = ({
  urlKey,
  functionType,
}: FunctionPageProperties) => {
  return <MicroFrontendLoader currentKey={urlKey} functionType={functionType} />
}
