import React from 'react'

import { Box, styled } from '@mui/material'

import ContactLinksColumn from './ContactLinksColumn'
import Sections from './Sections'
import LegalLinks from './LegalLinks'
import MobileSections from './MobileSections'

import { useCachedSettingsState } from 'state/PublicWebApi'
import { useSliceStateSelector } from 'slices/hooks'
import { ScrollWrapper } from 'apps/Site/Epi/ScrollWrapper'

const FooterWrapper = styled('footer')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

const TopRowWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: theme.palette.surface?.purple,
  padding: `${theme.spacing(6)} 0 ${theme.spacing(5)} ${theme.spacing(1)}`,

  [theme.breakpoints.up('md')]: {
    padding: `${theme.spacing(6)} ${theme.spacing(3)}`,
  },
  [theme.breakpoints.up('lg')]: {
    flexDirection: 'row',
    justifyContent: 'center',
    padding: `${theme.spacing(6)} ${theme.spacing(9)}`,
  },
}))

const BottomRowWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: `0 ${theme.spacing(2)}`,
  backgroundColor: theme.palette.primary?.dark,

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: `0 ${theme.spacing(3)}`,
  },
  [theme.breakpoints.up('lg')]: {
    padding: `0 ${theme.spacing(9)}`,
  },
}))

const NewFooter = () => {
  const footerContent = useCachedSettingsState().footerNavigation
  const { isTablet, isMobile } = useSliceStateSelector(
    (state) => state.slices.device.deviceSize
  )

  return (
    <ScrollWrapper>
      <FooterWrapper data-testid="new-footer">
        {isTablet || isMobile ? (
          <>
            <TopRowWrapper data-testid="mobile-footer">
              <ContactLinksColumn links={footerContent?.contactLinksArea} />
              <MobileSections sections={footerContent?.footerNavigationItems} />
            </TopRowWrapper>
            <BottomRowWrapper>
              <LegalLinks links={footerContent?.legalLinksArea} />
            </BottomRowWrapper>
          </>
        ) : (
          <>
            <TopRowWrapper data-testid="desktop-footer">
              <ContactLinksColumn links={footerContent?.contactLinksArea} />
              <Sections contexts={footerContent?.footerNavigationItems} />
            </TopRowWrapper>
            <BottomRowWrapper>
              <LegalLinks links={footerContent?.legalLinksArea} />
            </BottomRowWrapper>
          </>
        )}
      </FooterWrapper>
    </ScrollWrapper>
  )
}

export default NewFooter
