import { Box, styled } from '@mui/material'
import React from 'react'

const RowBreakWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: `${theme.spacing(1)} 0`,
  width: '100%',
}))

const RowBreakLine = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette?.neutral?.divider,
  height: '1px',
  width: '100%',
}))

const RowBreak = () => {
  return (
    <RowBreakWrapper>
      <RowBreakLine data-testid="row-break-block" />
    </RowBreakWrapper>
  )
}

export default RowBreak
