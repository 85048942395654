import { publicWebApi } from 'state/PublicWebApi'
import { convertLinksIfPr } from 'utils/urlTransition'
import { ContentResponse, TransformedContentResponse } from './types'
import { useContentUrl } from 'utils/hooks'

const removeInitialSlash = (str: string) => str.replace(/^\//, '')
export const contentApi = publicWebApi.injectEndpoints({
  endpoints: (builder) => ({
    getContent: builder.query<TransformedContentResponse, string>({
      query: (contentUrl) => `/content/${removeInitialSlash(contentUrl)}`,
      providesTags: ['Content'],
      transformResponse: (res: ContentResponse): TransformedContentResponse => {
        const { properties, contentType, startPublish } = res
        let possibleUrlEndings: string[] = undefined
        if (properties?.hasTabNavigation) {
          possibleUrlEndings = properties?.blocks?.map(
            (block) => res.url + block.properties?.urlKey
          )
        }
        return {
          properties: convertLinksIfPr({
            ...properties,
            tabUrls: possibleUrlEndings,
            url: res.url,
          }),
          contentType,
          startPublish,
        }
      },
    }),
  }),
})

/**
 * A hook that returns the last cached content state for the current url.
 *
 * Normally should be ran in a component that is a child of ```<PrepareAppShell />``` and ```<FetchContent />```
 *
 * The hook can also be ran optimistically with null checks if used outside of ```<PrepareAppShell />``` and ```<FetchContent />```
 *
 * The data prop will return undefined if ran outside of ```<PrepareAppShell />``` and ```<FetchContent />``` until it is populated with data.
 *
 * Note that we are using the *as* keyword here instead of making unintuitive
 * checks wether the hook is called as a child of its specified elements or not.
 *
 * There wont be an error, just an untyped undefined data prop.
 *
 */
export const useCachedContentQuery = () => {
  const url: string = useContentUrl()
  const queryState = contentApi.endpoints.getContent.useQueryState(url)
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const data = queryState.data as TransformedContentResponse
  return { ...queryState, data }
}

export default contentApi
