import { Box } from '@mui/material'
import React, { Children, PropsWithChildren } from 'react'
import { AppshellButton, AppshellLink } from '@internal-helpers'

export interface IButtonAndLinkProps {
  link?: {
    text: string
    url: string
  }
  button?: {
    text: string
    url: string
  }
  isSecondaryButton?: boolean
}

const LinksWrapper = ({ children }: PropsWithChildren<unknown>) => {
  return Children.toArray(children).length > 1 ? (
    <Box
      alignItems="center"
      display="flex"
      flexWrap="wrap"
      gap="24px"
      data-testid="LinksWrapper"
    >
      {children}
    </Box>
  ) : (
    <>{children}</>
  )
}

const ButtonAndLink = ({
  link,
  button,
  isSecondaryButton,
}: IButtonAndLinkProps) => {
  if (button || link) {
    return (
      <LinksWrapper>
        {button?.url && button?.text && (
          <AppshellButton
            url={button.url}
            text={button.text}
            variant={isSecondaryButton ? 'outlined' : 'contained'}
          />
        )}
        {link?.url && link?.text && (
          <AppshellLink url={link.url} text={link.text} data-testid="link" />
        )}
      </LinksWrapper>
    )
  } else {
    return null
  }
}

export default ButtonAndLink
