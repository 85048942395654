import React from 'react'
import { ExpandableList } from '../../components'
import { ExpandableListItem } from 'packages/internaltypes'

export interface ExpandableListBlockContent {
  properties: {
    expandableItems: { properties: ExpandableListItem }[]
  }
}

export interface ExpandableListBlockProps {
  content: ExpandableListBlockContent
  mediaUrl: string
}
const ExpandableListBlock = ({
  content,
  mediaUrl,
}: ExpandableListBlockProps): JSX.Element => {
  const {
    properties: { expandableItems },
  } = content
  return (
    <ExpandableList
      mediaUrl={mediaUrl}
      listItems={expandableItems}
      variant="multiple"
    />
  )
}

export default ExpandableListBlock
