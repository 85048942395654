import React from 'react'

import {
  isLinkExternal,
  getFullImageUrl,
  getStandardSrcset,
  createImageAltTextFromFileName,
} from 'packages/internal-helpers'

import { LazyLoadWrapper } from '@internal-helpers'
import { Box, Link, Typography, styled } from '@mui/material'
import OpenInNew from '@mui/icons-material/OpenInNew'
import FormatQuote from '@mui/icons-material/FormatQuoteRounded'

export interface TestimonialBlockContent {
  quote: string
  body?: string
  image?: string
  link?: {
    text: string
    url: string
  }
  mediaUrl?: string
}

export interface TestimonialBlockProps {
  content: TestimonialBlockContent
}

const StyledBox = styled(Box)(({ theme }) => ({
  flex: '7 1 0',
  margin: 0,
  paddingTop: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(3),
  },
}))

const TestimonialBlock = ({
  content: { quote, body, link, image, mediaUrl },
}: TestimonialBlockProps): JSX.Element => {
  return (
    <Box display={'flex'} gap={7} component={'section'}>
      {image && (
        <Box display={{ xs: 'none', md: 'block' }} flex={'4 1 0'}>
          <LazyLoadWrapper component="span">
            <Box
              sx={{
                height: '100%',
                width: '100%',
                objectFit: 'cover',
                verticalAlign: 'middle',
              }}
              component={'img'}
              src={getFullImageUrl(mediaUrl, image, 576)}
              srcSet={getStandardSrcset(mediaUrl, image, [400])}
              sizes="(max-width: 400px) 100vw, 400px"
              alt={createImageAltTextFromFileName(image)}
              data-testid="testimonial-image"
            />
          </LazyLoadWrapper>
        </Box>
      )}
      <StyledBox component={'figure'}>
        <FormatQuote sx={{ fontSize: 56, mt: -2, mx: -1 }} color={'primary'} />
        <Box component={'blockquote'} m={0}>
          {quote && (
            <Typography mb={3} component="h2" variant="h5">
              {quote}
            </Typography>
          )}
          {body && <Typography variant="body1">{body}</Typography>}
          {link?.url && link?.text && (
            <Link
              href={link.url}
              target={isLinkExternal(link.url) ? '_bkank' : undefined}
              data-testid="testimonial-link"
              rel={isLinkExternal(link.url) ? 'noreferrer' : undefined}
              underline="hover"
              display={'inline-block'}
              mt={5}
              aria-label={
                isLinkExternal(link.url)
                  ? `${link.text} (öppnas i ny flik)`
                  : link.text
              }
            >
              {isLinkExternal(link.url) && (
                <OpenInNew
                  fontSize={'inherit'}
                  sx={{ mr: 1, position: 'relative', top: '0.15em' }}
                />
              )}
              {link.text}
            </Link>
          )}
        </Box>
      </StyledBox>
    </Box>
  )
}

export default TestimonialBlock
