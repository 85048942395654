import React from 'react'
import { Box, List, ListItem, Typography, styled } from '@mui/material'
import { FooterContextSection } from 'PublicWebApi/Settings/types'

import { SectionLink } from '../Section/Section.styles'

interface SubSectionProps {
  section: FooterContextSection
}

const SubSectionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 0,

  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(2),
  },
}))

const ListWrapper = styled(List)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  paddingTop: theme.spacing(2),

  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(1),
  },
}))

const SubSection = ({ section }: SubSectionProps) => {
  return (
    <SubSectionWrapper>
      <Typography variant="subtitle2" color="text.secondary">
        {section.title}
      </Typography>
      <ListWrapper>
        {section?.children?.map((link) => (
          <ListItem key={`sectionlink-${link.title}`}>
            <SectionLink
              data-testid={`link-with-subtitle-${link.title}`}
              url={link.url}
              text={link.title}
            />
          </ListItem>
        ))}
      </ListWrapper>
    </SubSectionWrapper>
  )
}

export default SubSection
