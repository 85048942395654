/* eslint-disable @typescript-eslint/no-duplicate-type-constituents */
import {
  errorColors,
  infoColors,
  successColors,
  warningColors,
} from '../colors'

type StatusComponentColor =
  | typeof successColors.main
  | typeof infoColors.main
  | typeof warningColors.main
  | typeof errorColors.main

export const generateStatusComponentStyling = (
  statusColor: StatusComponentColor
) => {
  const statusComponentStyle = {
    border: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    '&::before': {
      content: '""',
      backgroundColor: statusColor,
      height: '10px',
      width: '10px',
      borderRadius: '50%',
    },
  }

  return statusComponentStyle
}
