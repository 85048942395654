import React, { useContext, useEffect, useState } from 'react'
import { Box, IconButton, List, ListItem, styled } from '@mui/material'
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded'
import {
  FoldoutProps,
  MegaNavigationContext,
} from 'PublicWebApi/Settings/types'
import { useSliceStateSelector } from 'slices/hooks'
import ActionButtons from '../ActionButtons'
import ContextLinks from '../ContextLinks'
import Submenu from '../Submenu/Submenu'
import {
  DrawerWrapper,
  MenuWrapper,
  StyledMenuFoldoutItem,
  StyledMenuLink,
} from './Menu.styles'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { LogoLinkWrapper } from '../../NewHeader.styles'
import Logo from 'apps/Site/components/Logo'
import { useTrackCustomClick } from 'features/Telemetry/GoogleTagManager/Hooks/useTrackCustomClick'
import MedarbetareMenuList from 'apps/Site/Header/Medarbetare/MedarbetareMenuList'
import { SubMenuContext } from '../Navbar/Navbar'

interface MenuProps {
  menuContent: MegaNavigationContext[]
  openMenu: boolean
  onClickMenuButton: () => void
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '&.Mui-focusVisible': {
    color: theme.palette.primary?.main,
  },
}))

const Menu = ({ menuContent, openMenu, onClickMenuButton }: MenuProps) => {
  const { visitorContextStatus } = useSliceStateSelector(
    (state) => state.slices.visitorContexts
  )

  const trackCustomClick = useTrackCustomClick()
  const { setIsSubmenuOpen, isSubmenuOpen } = useContext(SubMenuContext)

  useEffect(() => {
    openMenu
      ? window?.zE?.('messenger', 'hide')
      : window?.zE?.('messenger', 'show')
  }, [openMenu])

  const [activeContext, setActiveContext] =
    useState<MegaNavigationContext | null>()

  const [submenuItems, setSubmenuItems] = useState<FoldoutProps[]>([])

  const openSubmenu = (label: string, items: FoldoutProps[]) => {
    trackCustomClick('Mobile menu', {
      label: `Öppna submeny: ${label}`,
    })
    setIsSubmenuOpen(true)
    setSubmenuItems(items)
  }

  const closeSubmenu = () => {
    trackCustomClick('Mobile menu', { label: 'Stäng submeny' })
    setIsSubmenuOpen(false)
  }

  const onClickMenuNavLink = (label: string, url: string) => {
    trackCustomClick('Mobile menu navlink', {
      label: `Klick på ${label}`,
      href: url,
    })
    onClickMenuButton()
  }

  useEffect(() => {
    menuContent?.map((items) => {
      if (visitorContextStatus === items.role) {
        setActiveContext(items)
      }
    })
  }, [visitorContextStatus, menuContent, setActiveContext])

  return (
    <DrawerWrapper
      hideBackdrop
      anchor="right"
      open={openMenu}
      onClose={onClickMenuButton}
    >
      <MenuWrapper data-testid="menu">
        <Box display="flex" justifyContent="space-between" pb={3.5}>
          {isSubmenuOpen ? (
            <Box display="flex" flexGrow={1}>
              <StyledIconButton
                data-testid="close-submenu-button"
                onClick={closeSubmenu}
                sx={{ padding: 0 }}
              >
                <ArrowBackRoundedIcon />
              </StyledIconButton>
            </Box>
          ) : (
            <LogoLinkWrapper
              href="/privatperson"
              aria-label="Startsida TRR.se"
              onClick={onClickMenuButton}
            >
              <Logo data-testid="mobile-trr-logo" />
            </LogoLinkWrapper>
          )}

          <StyledIconButton
            sx={{ alignSelf: 'flex-end' }}
            onClick={onClickMenuButton}
            data-gtm-label="close-menu-button"
          >
            <CloseRoundedIcon />
          </StyledIconButton>
        </Box>
        {isSubmenuOpen ? (
          <Submenu
            SubmenuItems={submenuItems}
            onClickLink={onClickMenuButton}
          />
        ) : (
          <>
            <List
              sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}
              data-testid="menuitems-list"
            >
              {visitorContextStatus === 'medarbetare' ? (
                <MedarbetareMenuList onClick={onClickMenuButton} />
              ) : (
                <>
                  {activeContext?.menuItems?.map((menuItem, index) =>
                    menuItem.children?.length === 0 && menuItem.url ? (
                      <ListItem
                        key={`menuitem-${index.toString()}`}
                        data-testid={`menuitem-${menuItem.title}`}
                        onClick={() =>
                          onClickMenuNavLink(menuItem.title, menuItem.url)
                        }
                      >
                        <StyledMenuLink href={menuItem.url}>
                          {menuItem.title}
                        </StyledMenuLink>
                      </ListItem>
                    ) : (
                      <ListItem key={`menuitem-${index.toString()}`}>
                        <StyledMenuFoldoutItem
                          data-testid={`menuitem-${menuItem.title}`}
                          onClick={() =>
                            openSubmenu(menuItem.title, menuItem.children)
                          }
                        >
                          {menuItem.title}
                          <NavigateNextRoundedIcon
                            sx={{ color: 'text.secondary' }}
                          />
                        </StyledMenuFoldoutItem>
                      </ListItem>
                    )
                  )}
                </>
              )}
            </List>

            <ActionButtons
              ctaButton={activeContext?.ctaButton}
              visitorContext={visitorContextStatus}
              onClick={onClickMenuButton}
            />

            <ContextLinks contexts={menuContent} onClick={onClickMenuButton} />
          </>
        )}
      </MenuWrapper>
    </DrawerWrapper>
  )
}

export default Menu
