import React, { createContext, useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { AppBar, Box, IconButton, Toolbar, styled } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import SearchIcon from '@mui/icons-material/Search'

import Logo from 'apps/Site/components/Logo'
import RightMenu from 'apps/Site/Header/RightMenu'
import { useTrackCustomClick } from 'features/Telemetry/GoogleTagManager/Hooks/useTrackCustomClick'
import { useAuthentication } from 'features/Authentication/Hooks'
import { smallDeviceMenuStatus } from 'slices/Menus/types'
import { useSliceDispatch, useSliceStateSelector } from 'slices/hooks'
import { useCachedSettingsState } from 'PublicWebApi'

import Menu from '../Menu'
import { LogoLinkWrapper } from '../../NewHeader.styles'
import { getLoggedInContext, getLoginUrl } from '../../NewHeader.hooks'
import AvatarButton from '../../AvatarButton'

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: 'sticky',
  backgroundColor: theme.palette.background.default,
  width: '100vw',
  boxShadow: 'none',
}))

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  width: 'inherit',
  justifyContent: 'space-between',
  [theme.breakpoints.up('sm')]: {
    padding: `${theme.spacing(2)}`,
  },
}))

const renderAvatarLoggedInButton = (
  visitorContext: string,
  onClick?: () => void
) => {
  return (
    <AvatarButton
      isMobile
      visitorContext={visitorContext}
      onClickMobileAction={onClick}
    />
  )
}

interface SubMenuContextProps {
  setIsSubmenuOpen: (state: boolean) => void
  isSubmenuOpen: boolean
}

export const SubMenuContext = createContext<SubMenuContextProps>({
  setIsSubmenuOpen: null,
  isSubmenuOpen: false,
})

const Navbar = () => {
  const { visitorContextStatus } = useSliceStateSelector(
    (state) => state.slices.visitorContexts
  )
  const trigger = useSliceDispatch()
  const currentLocation = useLocation()
  const { isLoggedIn, profile } = useAuthentication()

  const userRole = profile.idp

  const [openMenu, setOpenMenu] = useState(false)
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false)
  const submenuContext = useMemo(
    () => ({ isSubmenuOpen, setIsSubmenuOpen }),
    [isSubmenuOpen, setIsSubmenuOpen]
  )
  const menuContent = useCachedSettingsState().megaNavigation?.contexts

  const { smallDeviceMenuStatus } = useSliceStateSelector(
    (state) => state.slices.menus
  )

  const trackCustomClick = useTrackCustomClick()

  const showTRRMenu = useCallback(
    (type: smallDeviceMenuStatus) => {
      type === smallDeviceMenuStatus || type === 'none'
        ? trigger('setSmallDeviceMenuStatus', 'none')
        : trigger('setSmallDeviceMenuStatus', type)
    },
    [smallDeviceMenuStatus, trigger]
  )

  const onClickMenuButton = () => {
    if (
      profile.idp === 'local' &&
      currentLocation.pathname.includes('/mitt-trr')
    ) {
      return showTRRMenu('right')
    }
    setOpenMenu(!openMenu)
    setIsSubmenuOpen(false)
    showTRRMenu('none')
  }

  const closeAllMenus = () => {
    setOpenMenu(false)
    setIsSubmenuOpen(false)
    showTRRMenu('none')
  }

  const onSearchButtonClick = () => {
    trackCustomClick('Mobile header', {
      label: `Klick på sökknapp`,
      href: '/sok',
    })
    closeAllMenus()
  }

  return (
    <>
      <StyledAppBar>
        <StyledToolbar>
          <LogoLinkWrapper
            href="/privatperson"
            aria-label="Startsida TRR.se"
            onClick={closeAllMenus}
          >
            <Logo data-testid="mobile-trr-logo" />
          </LogoLinkWrapper>
          <Box display="flex" gap={1}>
            {!openMenu && (
              <>
                <IconButton href="/sok/" onClick={onSearchButtonClick}>
                  <SearchIcon />
                </IconButton>
                {isLoggedIn ? (
                  renderAvatarLoggedInButton(
                    getLoggedInContext(userRole, visitorContextStatus),
                    () => showTRRMenu('right')
                  )
                ) : (
                  <IconButton
                    href={getLoginUrl('/login', visitorContextStatus as string)}
                    data-testid="header-avatar-button"
                    onClick={() => {
                      trackCustomClick('Mobile header', {
                        label: 'Klick på Logga in-knapp',
                        href: '/login',
                      })
                    }}
                  >
                    <AccountCircleIcon data-testid="AccountCircleIcon-navbar" />
                  </IconButton>
                )}
                <IconButton
                  onClick={onClickMenuButton}
                  data-gtm-label="open-menu-button"
                >
                  <MenuRoundedIcon />
                </IconButton>
              </>
            )}
          </Box>
        </StyledToolbar>
      </StyledAppBar>
      <SubMenuContext.Provider value={submenuContext}>
        <Menu
          menuContent={menuContent}
          openMenu={openMenu}
          onClickMenuButton={onClickMenuButton}
        />
        <RightMenu isNewHeader testSelector="new-header-mobile-right-menu" />
      </SubMenuContext.Provider>
    </>
  )
}

export default Navbar
