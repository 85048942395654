import React from 'react'
import { AppshellLink } from 'packages/internal-helpers'

import { BulletListItemProperties } from '../BulletList'

import {
  AvatarPoint,
  AvatarWrapper,
  Body,
  Heading,
  LinkWrapper,
  TextWrapper,
} from './BulletListPoints.styles'

interface NumberedPointProps extends BulletListItemProperties {
  index: number
}

export const NumberedPoint = ({
  index,
  heading,
  body,
  link,
}: NumberedPointProps): JSX.Element => {
  const number = index + 1

  return (
    <AvatarPoint>
      <AvatarWrapper>{number.toString()}</AvatarWrapper>
      <TextWrapper>
        <Heading component="span">{heading}</Heading>
        {body && <Body component="span">{body}</Body>}
        {link?.text && link?.url && (
          <LinkWrapper>
            <AppshellLink url={link.url} text={link.text} />
          </LinkWrapper>
        )}
      </TextWrapper>
    </AvatarPoint>
  )
}
