import { ListItem } from '@mui/material'
import React from 'react'
import { StyledMenuLink } from '../../NewHeader/Mobile/Menu/Menu.styles'

const medarbetarData = [
  {
    title: 'Medarbetare',
    url: '/medarbetare',
  },
  {
    title: 'Mitt TRR',
    url: '/mitt-trr',
  },
  {
    title: 'TRR.se',
    url: '/',
  },
]

interface MedarbetareMenuListProps {
  onClick: () => void
}

const MedarbetareMenuList = ({ onClick }: MedarbetareMenuListProps) => {
  return (
    <>
      {medarbetarData.map((menuItem, index) => (
        <ListItem
          key={`menuitem-${index.toString()}`}
          data-testid={`medarbetare-menuitem-${menuItem.title}`}
          onClick={onClick}
        >
          <StyledMenuLink href={menuItem.url}>{menuItem.title}</StyledMenuLink>
        </ListItem>
      ))}
    </>
  )
}

export default MedarbetareMenuList
