import DOMPurify from 'dompurify'

const defaultAllowedTags = [
  'factbox',
  'iframe',
  'testimonialblock',
  'loginblock',
  'bulletlistblock',
  'expandablelistblock',
  'calltoaction',
]

const isEmpty = (obj: Record<string, unknown>): boolean =>
  Object.keys(obj).length === 0

export const replaceSelfClosingTags = (html: string): string => {
  // Needed so that the sanitizer doesn't remove data-content attributes
  // when self closing tags exist

  const tags = [
    { original: '&lt;br /&gt;', replacement: '&lt;br&gt;' },
    { original: '&lt;hr /&gt;', replacement: '&lt;hr&gt;' },
    { original: '<br />', replacement: '<br>' },
    { original: '<hr />', replacement: '<hr>' },
  ]

  tags.forEach((tag) => {
    html = html.replaceAll(tag.original, tag.replacement)
  })

  return html
}

const sanitizeBody = (
  body: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  replaceValues: Record<string, any>,
  allowedTags: string[] = []
): string => {
  const domPurifyConfig = {
    ADD_TAGS: [...defaultAllowedTags, ...allowedTags],
    ADD_ATTR: ['target'],
  }

  const sanitized = DOMPurify.sanitize(
    replaceSelfClosingTags(body),
    domPurifyConfig
  )

  const output = isEmpty(replaceValues)
    ? sanitized
    : sanitized.replace(
        new RegExp(Object.keys(replaceValues).join('|'), 'gi'),
        (matched: string) => replaceValues[matched] as string
      )

  return output
}

export default sanitizeBody
