import { Theme } from '@mui/material'

export const getBackgroundColor = (
  theme: Theme,
  color: string,
  useColor: boolean
): string => {
  switch (useColor && color) {
    case 'red':
      return theme.palette.surface?.orange
    case 'darkred':
      return theme.palette.error?.light
    case 'green':
      return theme.palette.surface?.green
    case 'yellow':
      return theme.palette.surface?.yellow
    case 'darkyellow':
      return theme.palette.accent?.main
    case 'blue':
      return theme.palette.surface?.purple
    case 'darkblue':
      return theme.palette.primary?.light
    default:
      return undefined
  }
}
