import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { visitorContext, VisitorContextState } from './types'

const initialState: VisitorContextState = {
  visitorContextStatus: 'privatperson',
}

const contextSlice = createSlice({
  name: 'visitorContexts',
  initialState,
  reducers: {
    setContextStatus: (
      state,
      action: PayloadAction<visitorContext>
    ): VisitorContextState => ({
      ...state,
      visitorContextStatus: action.payload,
    }),
  },
})

export default contextSlice
