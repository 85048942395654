import { MegaNavigationMenuItem } from 'PublicWebApi/Settings/types'
import React, { useCallback } from 'react'
import { NavItemWrapper } from '../HeaderMenuItems.styles'

export interface NavItemProps extends MegaNavigationMenuItem {
  onClick?: () => void
  toggleActiveNavElement: () => void
}

const NavLink = ({
  title,
  url,
  onClick,
  toggleActiveNavElement,
}: NavItemProps) => {
  const onClickAction = useCallback(() => {
    toggleActiveNavElement?.()
    onClick?.()
  }, [onClick, toggleActiveNavElement])

  return (
    <NavItemWrapper
      onClick={onClickAction}
      key={'item' + `${title}`}
      href={url}
      data-testid={'navitem-' + `${title}`}
    >
      {title}
    </NavItemWrapper>
  )
}

export default NavLink
