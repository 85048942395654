import { store } from 'store'
import config from '../Hooks/useTrackElement/config'
import { tryToGetElementMfName } from '../Hooks/useTrackElement/helpers/tryToGetElementMfName'
import { gtmLogsSlice } from 'slices/gtmLogsSlice'
import { gtmCustomEvent } from 'packages/app-shell-communication'

export const gtmLoggerErrorClick = (
  conditionFn: (element: HTMLElement) => boolean,
  element: HTMLElement,
  reason: string
): boolean => {
  if (conditionFn(element) === false) {
    const origin = tryToGetElementMfName(element) ?? 'App-Shell'
    const item = config[element.tagName].item
    const distinguisher = JSON.stringify(
      Object.entries(config[element.tagName].assembleDataEntries(element)).map(
        ([key, value]) => ({ key: key, value: value })
      )
    )

    store.dispatch({
      type: gtmLogsSlice.actions.setGtmErrorLogs.type,
      payload: {
        origin,
        event: `click_${item}`,
        action: 'click',
        item,
        distinguisher,
        reason,
      },
    })
  }
  return conditionFn(element)
}

export const gtmLoggerErrorCustom = (
  condition: boolean,
  conditionDescriptor: string,
  conditionDetails: gtmCustomEvent['detail']
): boolean => {
  if (condition === false) {
    store.dispatch({
      type: gtmLogsSlice.actions.setGtmErrorLogs.type,
      payload: {
        origin: conditionDetails.mfName,
        event: 'custom_event',
        action: conditionDetails.event_action,
        item: conditionDetails.event_item,
        distinguisher: JSON.stringify(conditionDetails.event_data),
        reason: conditionDescriptor,
      },
    })
    return condition
  }
  return condition
}
