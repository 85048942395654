import { pullRequestShareUrlMatcher } from 'utils/urlTransition'
import configService from 'utils/configService'
import { getCookieValue } from 'utils/cookies'

// Get path callback depending on environment (local, PR, dev, test, prod)
export const getRedirectUrl = (callbackPRPrefix = ''): string => {
  let result = ''
  const path = window.location.pathname

  if (pullRequestShareUrlMatcher.test(path)) {
    // If in PR
    result = `${pullRequestShareUrlMatcher.exec(path)[1]}${callbackPRPrefix}`
  } else if (callbackPRPrefix) {
    // If coming back to PR from login
    result = callbackPRPrefix
  }

  return result
}

const port = window.location.port ? `:${window.location.port}` : ''
const currentWindow = `${window.location.protocol}//${window.location.hostname}${port}`

export const getUserManagerSilentRedirectUri = (): string => {
  if (getCookieValue('app-shell-version')) {
    // @ts-expect-error Patch window for test purposes
    return `${currentWindow}${window.SIGN_IN_SILENT_URL}`
  }
  if (location.host.includes('pull-request-validation')) {
    return `${currentWindow}${getRedirectUrl()}/silent_renew.html`
  }
  return `${currentWindow}${configService.config.PUBLIC_URL}silent_renew.html`
}

export const getUserManagerRedirectUri = (callbackPRPrefix = ''): string => {
  return `${currentWindow}${getRedirectUrl(callbackPRPrefix)}/callback`
}
