import { Box, alpha, styled } from '@mui/material'
import { getBackgroundColor } from 'apps/Site/hooks/getBackgroundColor'

interface ColorProps {
  useColor?: boolean
  color?: string
  useColorStyling?: boolean
}

export const HeroBlockWrapper = styled('section', {
  shouldForwardProp: (props: string) => !['useColor', 'color'].includes(props),
})<ColorProps>(({ theme, useColor, color }) => ({
  display: 'flex',
  flexDirection: 'column',
  left: '50%',
  marginBottom: theme.spacing(5),
  marginTop: theme.spacing(5),
  position: 'relative',
  width: 'min(1536px, 100vw)',
  marginLeft: `max(calc(-1536px / 2), -50vw)`,

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(10),
    padding: `0 ${theme.spacing(6)}`,
  },

  ...(useColor &&
    color && {
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        marginTop: theme.spacing(-10),
        padding: `0 0 0 ${theme.spacing(4)}`,
      },
      backgroundColor: getBackgroundColor(theme, color, useColor),
      width: '100vw',
      marginLeft: `-50vw`,
    }),
}))

export const TextAreaWrapper = styled(Box, {
  shouldForwardProp: (props: string) => !['useColorStyling'].includes(props),
})<ColorProps>(({ theme, useColorStyling }) => ({
  display: 'flex',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    padding: `0 ${theme.spacing(10)} 0  0`,
    width: '50%',
    ...(useColorStyling && {
      justifyContent: 'flex-end',
      padding: 0,
    }),
  },

  ...(useColorStyling && {
    [theme.breakpoints.up('md')]: {
      width: '50%',
      justifyContent: 'flex-end',
    },
  }),
}))

export const HeadingAndIntroWrapper = styled(Box, {
  shouldForwardProp: (props: string) => !['useColorStyling'].includes(props),
})<ColorProps>(({ theme, useColorStyling }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: `0 ${theme.spacing(4)}`,
  marginBottom: theme.spacing(5),
  width: '100%',

  [theme.breakpoints.up('md')]: {
    padding: 0,
    marginBottom: '0',
    marginTop: theme.spacing(5),
  },

  ...(useColorStyling && {
    padding: `${theme.spacing(4)} ${theme.spacing(4)} 0 ${theme.spacing(4)}`,
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(10),
      maxWidth: '686px',
    },
  }),
}))

export const HeadingWrapper = styled('div')(({ theme }) => ({
  margin: `0 0 ${theme.spacing(4)} 0`,
  maxWidth: '752px',
}))

export const IntroWrapper = styled(Box)(({ theme }) => ({
  '& a[class*=_Button]': {
    padding: `0 ${theme.spacing(4)}`,
    height: '54px',
  },
}))

export const PreambleWrapper = styled('p')(({ theme }) => ({
  ...theme.typography.body1,
  marginBottom: theme.spacing(4),
  maxWidth: '752px',

  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(5),
  },
}))
interface HasBigLayout {
  hasBigLayout: boolean
}

export const VideoWrapper = styled(Box, {
  shouldForwardProp: (props: string) => !['hasBigLayout'].includes(props),
})<HasBigLayout>(({ theme, hasBigLayout }) => ({
  display: 'flex',
  flexGrow: 1,
  flexShrink: 1,
  overflow: 'hidden',
  [theme.breakpoints.up('md')]: {
    margin: 0,
  },

  '.LazyLoadWrapper': {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  ...(hasBigLayout
    ? {
        height: '100vw',
        [theme.breakpoints.up('md')]: {
          height: '600px',
        },
        '&:focus-visible': {
          outline: 'none',
          color: theme.palette.text?.primary,
          backgroundColor: alpha(theme.palette.primary?.main, 0.3),
        },
        '&:hover': {
          backgroundColor: theme.palette.surface?.purple,
          color: theme.palette.primary?.main,
          svg: {
            color: theme.palette.primary?.main,
          },
        },
      }
    : {
        [theme.breakpoints.up('md')]: {
          height: '440px',
          paddingLeft: theme.spacing(15),
        },
      }),
}))

export const ImageWrapper = styled(Box, {
  shouldForwardProp: (props: string) => !['hasBigLayout'].includes(props),
})<HasBigLayout>(({ theme, hasBigLayout }) => ({
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    width: '50%',
  },

  '.LazyLoadWrapper': {
    width: '100%',
    height: '100%',
  },
  img: {
    objectFit: 'cover',
    verticalAlign: 'middle',
    width: '100%',
    height: '100%',
    ...(hasBigLayout
      ? {
          height: '100vw',
          [theme.breakpoints.up('md')]: {
            height: '100%',
            minHeight: '600px',
          },
        }
      : {
          [theme.breakpoints.up('md')]: {
            minHeight: '440px',
            paddingLeft: theme.spacing(15),
          },
        }),
  },
}))
