import React, { useState, useEffect, useCallback } from 'react'
import { Typography, Link, Button, Stack, Drawer, Box } from '@mui/material'
import Cookies from 'js-cookie'
import { useApplicationInsights } from 'features/Telemetry/ApplicationInsights'
import { useCachedSettingsState } from 'state/PublicWebApi'
import { useSliceDispatch, useSliceStateSelector } from 'slices/hooks'

const name = 'TrrCookiesApproved'
const approvedValue = '1'
const declinedValue = '0'

const getCookieVisibleState = () => Cookies.get(name) === undefined

const CookieBanner = () => {
  const trigger = useSliceDispatch()
  const { enable: enableApplicationInsights, isEnabled: isAppInsightsEnabled } =
    useApplicationInsights()

  const { text, approveButton, declineButton, linkUrl, linkText } =
    useCachedSettingsState().cookieInformation

  const [cookiebarVisibility, setCookiebarVisibility] = useState<boolean>(
    getCookieVisibleState()
  )

  const { isTrrCookiesApproved } = useSliceStateSelector(
    (state) => state.slices.cookies
  )

  useEffect(() => {
    if (isTrrCookiesApproved) {
      if (!isAppInsightsEnabled) {
        enableApplicationInsights()
      }
    }
  }, [enableApplicationInsights, isAppInsightsEnabled, isTrrCookiesApproved])

  const decline = useCallback(() => {
    setCookiebarVisibility(false)
    Cookies.set(name, declinedValue)
  }, [])

  const approve = useCallback(() => {
    setCookiebarVisibility(false)
    Cookies.set(name, approvedValue, { expires: 365, secure: true })
    trigger('setCookieApproval', true)
  }, [trigger])

  if (!cookiebarVisibility) return <></>

  return (
    <Drawer
      open
      anchor="bottom"
      hideBackdrop
      variant="persistent"
      PaperProps={{
        elevation: 16,
      }}
    >
      <Box padding={4}>
        <Typography
          mb={3}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          {text}
          <Link href={linkUrl} display="block">
            {linkText}
          </Link>
        </Typography>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Button onClick={approve}>{approveButton}</Button>
          <Button onClick={decline} variant="outlined">
            {declineButton}
          </Button>
        </Stack>
      </Box>
    </Drawer>
  )
}

export default CookieBanner
