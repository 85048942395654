import { resetAuthOnExpiry } from 'features/Authentication/helpers'
import userManager, { IdServerUser } from 'features/Authentication/userManager'
import { useCallback, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useSliceDispatch } from 'slices/hooks'
import { applicationInsights } from 'packages/app-shell-communication'
import createUserRules from 'utils/userRules'

const isSameProfile = (currentUser: IdServerUser, prevUser: IdServerUser) => {
  const currUserProfile = currentUser?.profile
  const prevUserProfile = prevUser?.profile

  const allRolesMatch = currUserProfile?.roles?.every((role) =>
    prevUserProfile?.roles?.includes(role)
  )

  return (
    currUserProfile?.sub === prevUserProfile?.sub &&
    currUserProfile?.roles?.length === prevUserProfile?.roles?.length &&
    allRolesMatch
  )
}

export const shouldRedirect = (
  currentUser: IdServerUser,
  prevUser: IdServerUser,
  userRules: ReturnType<typeof createUserRules>
) => {
  if (currentUser.state?.refreshUserProfile) return false
  if (currentUser.state?.expiringTokenWasRefreshed) return false
  if (isSameProfile(currentUser, prevUser)) return false
  if (userRules.shouldUserStayInArbetsgivarinfoLoginFlow()) return false
  if (currentUser.profile.roles?.length) return true // roles exists

  return false
}

const getArbetsgivarinfoLinkId = (user: IdServerUser) => {
  const userState = user?.state?.arbetsgivarinfoLinkId
  const arbetsgivarinfoUrl = new URLSearchParams(window.location.search).get(
    'linkId'
  )
  return userState ?? arbetsgivarinfoUrl ?? undefined
}

const trackUserLoadedRedirect = (
  idp: string,
  roles: string[],
  lastRoles: string[],
  stateRedirectUrl: string,
  redirectUrl: string
) => {
  applicationInsights.trackEvent({
    name: 'userLoadedRedirect',
    properties: {
      repo: 'public-web-frontend',
      idp,
      roles,
      lastRoles,
      stateRedirectUrl,
      redirectUrl,
    },
  })
}

const useUserManagerEventListeners = () => {
  const currentUserRef = useRef<IdServerUser>()
  const trigger = useSliceDispatch()
  const history = useHistory()

  const userLoadedCallback = useCallback(
    (user: IdServerUser) => {
      trigger('setAuthState', user)

      const prevUser = currentUserRef.current
      currentUserRef.current = user

      const userRules = createUserRules(
        user?.profile?.roles,
        user?.profile?.idp,
        getArbetsgivarinfoLinkId(user)
      )

      if (shouldRedirect(user, prevUser, userRules)) {
        const redirectUrl = userRules.getLoggedInRedirectUrl(
          user.state?.redirectUrl
        )

        trackUserLoadedRedirect(
          user?.profile?.idp,
          user?.profile?.roles,
          prevUser?.profile?.roles,
          user.state?.redirectUrl,
          redirectUrl
        )
        history.replace(redirectUrl)
      }
    },
    [history, trigger]
  )

  const accessTokenExpiredCallback = useCallback(() => {
    void resetAuthOnExpiry()
  }, [])

  useEffect(() => {
    userManager.events.addUserLoaded(userLoadedCallback)
    userManager.events.addAccessTokenExpired(accessTokenExpiredCallback)
    return () => {
      userManager.events.removeUserLoaded(userLoadedCallback)
      userManager.events.removeAccessTokenExpired(accessTokenExpiredCallback)
    }
  }, [userLoadedCallback, accessTokenExpiredCallback])
}

export default useUserManagerEventListeners
