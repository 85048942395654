import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { handledHttpErrors } from 'state/PublicWebApi/Settings'

const narrowHandledErrorCode = (
  error?: FetchBaseQueryError | SerializedError
): handledHttpErrors | undefined => {
  if (error && 'status' in error && typeof error.status === 'number') {
    if (error.status === 401 || error.status === 403 || error.status === 404)
      return error.status.toString() as handledHttpErrors
  }
}

export default narrowHandledErrorCode
