/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import React, { PropsWithChildren } from 'react'

import {
  BulletPoint,
  IconPoint,
  NumberedPoint,
  Simple,
} from './BulletListPoints'

import { styled, List } from '@mui/material'

export enum EListType {
  bulletpoint = 'bulletpoint',
  simple = 'simple',
  numbered = 'numbered',
  icon = 'icon',
}

export interface BulletListContent {
  listType: EListType
  listItems: BulletListItem[]
}

export interface BulletList {
  content: BulletListContent
}

interface BulletListItem {
  properties: BulletListItemProperties
}

export interface BulletListItemProperties {
  heading: string
  body?: string
  iconName?: string
  link?: { text: string; url: string }
}

interface BulletListContentProps extends BulletListItemProperties {
  listType: EListType
  index: number
}

const BulletListContent = (props: BulletListContentProps): JSX.Element => {
  switch (props.listType) {
    case 'numbered':
      return <NumberedPoint {...props} />
    case 'icon':
      return <IconPoint {...props} />
    case 'simple':
      return <Simple {...props} />
    case 'bulletpoint':
    default:
      return <BulletPoint {...props} />
  }
}

interface BulletListWrapperProps {
  listType: EListType
}

interface ListWrapperProps {
  listType: EListType
  component: string
}

export const ListWrapper = styled(List, {
  shouldForwardProp: (prop) => prop !== 'listType',
})<ListWrapperProps>(({ theme, listType }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
  listStyle: 'none',
  margin: 0,
  padding: 0,
  ...(listType === 'bulletpoint' && {
    paddingLeft: theme.spacing(4.5),
    listStyle: 'disc',
  }),
}))

const BulletListWrapper = ({
  children,
  listType,
}: PropsWithChildren<BulletListWrapperProps>): JSX.Element => {
  return (
    <>
      {listType === 'numbered' ? (
        <ListWrapper component="ol" listType={listType}>
          {children}
        </ListWrapper>
      ) : (
        <ListWrapper component="ul" listType={listType}>
          {children}
        </ListWrapper>
      )}
    </>
  )
}

const BulletList = ({
  content: { listType, listItems },
}: BulletList): JSX.Element | null => {
  if (!listItems || listItems?.length === 0) {
    return null
  }

  return (
    <BulletListWrapper listType={listType}>
      {listItems.map(
        ({ properties: { heading, body, iconName, link } }, index) => (
          <li key={heading}>
            <BulletListContent
              index={index}
              listType={listType}
              heading={heading}
              body={body}
              iconName={iconName}
              link={link}
            />
          </li>
        )
      )}
    </BulletListWrapper>
  )
}

export default BulletList
