import classNames from 'classnames'
import React, { FunctionComponent, useCallback, useEffect, useRef } from 'react'

import s from './DropdownMenu.module.scss'

interface NamedSlots {
  action: JSX.Element
  dropdownList: JSX.Element
}

interface DropdownMenuProps {
  testSelector?: string
  dropdownListCustomClass?: string
  showDropdown: boolean
  children?: NamedSlots
  toggleDropdown: (value: boolean) => void
}

interface IDropdownMenuContext {
  toggleDropdownMenu: () => void
  isDropdownVisible: boolean
}

export const DropdownMenuContext =
  React.createContext<IDropdownMenuContext>(null)

export const DropdownMenu: FunctionComponent<DropdownMenuProps> = ({
  children,
  testSelector,
  dropdownListCustomClass,
  showDropdown,
  toggleDropdown,
}) => {
  if (!children) {
    throw new Error('You need to specify children')
  }
  const { action, dropdownList } = children

  const toggleDropdownMenu = useCallback(() => {
    toggleDropdown(!showDropdown)
  }, [toggleDropdown, showDropdown])

  const menuRef = useRef(null)

  const closeMenuOnClickOutside = (event: Event) => {
    if (
      showDropdown &&
      menuRef.current &&
      !menuRef.current.contains(event.target as Node)
    ) {
      toggleDropdown(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', closeMenuOnClickOutside, true)
    return () => {
      document.removeEventListener('click', closeMenuOnClickOutside, true)
    }
  })

  return (
    <div ref={menuRef}>
      <DropdownMenuContext.Provider
        value={{ toggleDropdownMenu, isDropdownVisible: showDropdown }}
      >
        <nav data-testid={testSelector} className={s.DropdownMenu}>
          {action}
          {dropdownList && showDropdown && (
            <ul
              className={classNames(s.DropdownList, dropdownListCustomClass)}
              data-testid="dropdown-menu-list"
              role="menu"
            >
              {dropdownList}
            </ul>
          )}
        </nav>
      </DropdownMenuContext.Provider>
    </div>
  )
}
