import { HTMLMapper } from 'packages/html-mapper'
import React, { useState } from 'react'
import { ExpandableListItem } from 'packages/internaltypes'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  styled,
} from '@mui/material'
import ExpandMore from '@mui/icons-material/ExpandMore'

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderLeft: 0,
  borderRight: 0,
  boxShadow: 'none',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}))

export interface ExpandableListProps {
  heading?: string
  listItems: { properties: ExpandableListItem }[]
  variant: 'single' | 'multiple'
  mediaUrl: string
}

const ExpandableList = ({
  heading,
  listItems,
  variant,
  mediaUrl,
}: ExpandableListProps): JSX.Element => {
  const [expanded, setExpanded] = useState<unknown[]>([])

  const checkIfExpanded = (index: number): boolean => {
    return expanded.includes(index)
  }

  const toggleExpanded = (index: number): void => {
    if (checkIfExpanded(index)) {
      if (variant === 'single') {
        setExpanded([])
      } else {
        setExpanded(expanded.filter((item) => item !== index))
      }
    } else {
      if (variant === 'single') {
        setExpanded([index])
      } else {
        setExpanded([...expanded, index])
      }
    }
  }

  return (
    <>
      {heading && (
        <Typography component="h3" variant="h6" mb={1.5}>
          {heading}
        </Typography>
      )}
      {listItems?.length > 0 && (
        <List>
          {listItems.map(({ properties: { heading, body } }, index) => (
            <StyledAccordion
              key={`expandable-list-key-${heading}`}
              expanded={checkIfExpanded(index)}
              onChange={() => toggleExpanded(index)}
              disableGutters
              data-testid={`list-item-${index}`}
            >
              <AccordionSummary
                id={`expandable-list-item-${heading}`}
                expandIcon={<ExpandMore />}
              >
                <Typography variant="subtitle1" fontSize={18}>
                  {heading}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <HTMLMapper mediaUrl={mediaUrl} body={body} />
              </AccordionDetails>
            </StyledAccordion>
          ))}
        </List>
      )}
    </>
  )
}

export default ExpandableList
