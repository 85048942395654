import ArticleList from 'apps/Site/components/ArticleList'
import { ArticleListItem } from 'apps/Site/components/ArticleList/ArticleListItem'
import React from 'react'

export interface IEditorialArticleListItem {
  properties: {
    articleCategory: string
    image: string
    heading: string
    preamble: string
    startPublish: string
    url: string
  }
}

export interface IEditorialArticleListBlockProps {
  content: {
    heading: string
    articleListItems: IEditorialArticleListItem[]
  }
}

const EditorialArticleListBlock = ({
  content: { heading, articleListItems },
}: IEditorialArticleListBlockProps) => {
  return (
    <>
      {articleListItems?.length > 0 && (
        <section
          className="EditorialArticleListBlock"
          data-testid="editorial-article-list-block"
        >
          <ArticleList heading={heading}>
            {articleListItems.map((item, index) => (
              <ArticleListItem
                heading={item.properties.heading}
                body={item.properties.preamble}
                url={item.properties.url}
                date={item.properties.startPublish}
                category={item.properties.articleCategory}
                image={item.properties.image}
                key={index}
              />
            ))}
          </ArticleList>
        </section>
      )}
    </>
  )
}

export default EditorialArticleListBlock
