import React, { forwardRef } from 'react'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom'

const ReactRouterAdapterLink = forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props
  if (href?.startsWith('/')) {
    // Map MUI's 'href' prop to react-router's 'to' if link is internal
    return <RouterLink ref={ref} to={href} {...other} />
  }
  // else use a href with passed props
  return <a ref={ref} href={href} {...other} />
})

export default ReactRouterAdapterLink
