import React from 'react'
import { styled, Box, Link, Typography } from '@mui/material'
import { date as dateFormatter } from '@trr/frontend-datetime'
import { LazyLoadWrapper } from '@internal-helpers'
import configService from 'utils/configService'
import { getImageQuery } from 'packages/internal-helpers'
import { useSliceStateSelector } from 'slices/hooks'

const ArticleListItemLink = styled(Link)(({ theme }) => ({
  display: 'block',
  textDecoration: 'none',
  cursor: 'pointer',
  color: theme.palette.neutral?.main,
  '&:hover': {
    '& .Heading': {
      color: theme.palette.primary?.main,
    },
  },
}))

const ItemBox = styled(Box)(() => ({
  display: 'flex',
  flexFlow: 'row',
  justifyContent: 'space-between',
}))

const Image = styled('img')(() => ({
  width: '120px',
  height: '120px',
  objectFit: 'cover',
  objectPosition: 'center',
}))

export interface ArticleListItemProps {
  heading: string
  body: string
  url: string
  date?: string
  category?: string
  image?: string
}

export const ArticleListItem = ({
  heading,
  body,
  url,
  date,
  category,
  image,
}: ArticleListItemProps) => {
  const { isMobile } = useSliceStateSelector(
    (state) => state.slices.device.deviceSize
  )
  const { MEDIA_URL } = configService.config

  const truncateText = (text: string) => {
    if (text?.length > 200) {
      return `${text.substring(0, 200)}...`
    }
    return text
  }

  return (
    <li>
      <ArticleListItemLink href={url}>
        {date && (
          <Typography variant="subtitle2" component="p" sx={{ mb: 1 }}>
            {dateFormatter.format(date, { style: 'numeric' })}
          </Typography>
        )}
        <ItemBox>
          <Box maxWidth={'752px'}>
            <Typography
              className="Heading"
              variant="h5"
              component="h3"
              sx={{ mb: 1.5 }}
            >
              {heading}
            </Typography>
            <Typography
              variant="body1"
              component="p"
              data-testid="item-body"
              sx={{ mb: 3 }}
            >
              {truncateText(body)}
            </Typography>
            {category && (
              <Typography
                variant="subtitle2"
                component="p"
                sx={{ color: 'neutral.light' }}
              >
                {category}
              </Typography>
            )}
          </Box>
          {image && !isMobile && (
            <Box aria-hidden="true" marginLeft={4}>
              <LazyLoadWrapper>
                <Image
                  src={getImageQuery(MEDIA_URL + image, 120)}
                  alt={image}
                  data-testid="article-list-item-image"
                />
              </LazyLoadWrapper>
            </Box>
          )}
        </ItemBox>
      </ArticleListItemLink>
    </li>
  )
}
