import { __api_internals__ } from '../Event.names'

const eventNames = __api_internals__.eventNames

export interface IApplicationInsightsEvent<T> {
  aiVersion: number
  azureReleaseId?: string
  action: string
  payload: T
}

export type ApplicationInsightsCustomEvent = ReturnType<
  typeof createCustomEvent
>

const createCustomEvent = <T>(
  e: IApplicationInsightsEvent<T>
): CustomEvent<IApplicationInsightsEvent<T>> =>
  new CustomEvent(eventNames.applicationInsightsEventName, {
    bubbles: true,
    detail: e,
  })

// Fire a custom event with details of a application insights logging action
export const __internal__dispatchApplicationInsightsEvent = <T>(
  event: IApplicationInsightsEvent<T>
): boolean => dispatchEvent(createCustomEvent(event))
