import React, { Suspense, useEffect, useMemo } from 'react'
import * as muitheme from 'packages/mui-theme'
import { ExternalAssetsBoundary } from 'features/ErrorHandling/ExternalAssetsBoundary'
import { useApplicationInsights } from 'features/Telemetry/ApplicationInsights'
import LazyLoading from 'features/SystemLoaders/LazyLoading'
import reloadConfig from '../../../../microFrontends.reload.config'

import { useAuthentication } from '../../Authentication/Hooks'

import { IMicroFrontendLoader } from './MicrofrontendLoader.types'
import { useLegacySharedState } from './hooks/useLegacySharedState'
import { useSharedState } from './hooks/useSharedState'
import { useCachedFeatureFlagsState } from 'state/PublicWebApi'
import { CircularProgress } from '@mui/material'

/*
  This cannot be loaded into SystemJS the same way we do
  with other shared dependencies, given that it, too, stems from
  Public-Web-Frontend.

  Therefore, we set it in SystemJS once it is instantiated here instead.
*/
System.set('app:@trr/mui-theme', muitheme)

const MicroFrontendLoader = ({
  functionType,
  currentKey,
  editorBlockContent,
}: IMicroFrontendLoader) => {
  const { appInsights } = useApplicationInsights()
  const { isLoggedIn } = useAuthentication()
  const sharedState = useLegacySharedState(currentKey, editorBlockContent)
  const appShellData = useSharedState(currentKey, editorBlockContent)
  const MicroFrontend = useMemo(
    () => React.lazy(() => System.import(functionType)),
    [functionType]
  )

  useEffect(() => {
    return () => {
      if (reloadConfig.includes(functionType)) {
        const moduleUrl = System.resolve(functionType)
        System.delete(moduleUrl)
      }
    }
  }, [functionType])

  const flags = useCachedFeatureFlagsState()
  const hasMfTrackableFlag =
    flags.includes(`gtm.enabled.${functionType}`) ?? false
  return (
    <ExternalAssetsBoundary
      assetType="micro-frontend"
      appInsights={appInsights}
    >
      <Suspense fallback={<LazyLoading component={<CircularProgress />} />}>
        <div
          data-mf-loaded={functionType}
          data-mftrackable={hasMfTrackableFlag}
        >
          <MicroFrontend
            application={{ isLoggedIn }}
            mfName={functionType}
            {...sharedState}
            appShellData={appShellData}
          />
        </div>
      </Suspense>
    </ExternalAssetsBoundary>
  )
}

export default MicroFrontendLoader
