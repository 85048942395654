import React from 'react'
import { Typography, Box, styled } from '@mui/material'
import ShareButtons from 'apps/Site/components/ShareButtons'

interface ShareBarProps {
  isPublicPage: boolean
}

const ShareBarWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(7),
  width: '100%',
}))

const Bar = styled('hr')(({ theme }) => ({
  backgroundColor: theme.palette.neutral?.divider,
  borderWidth: 0,
  height: '1px',
  width: '100%',
}))

const BottomContent = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  gap: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    paddingLeft: 0,
  },
}))

const ShareBar = ({ isPublicPage }: ShareBarProps) => {
  return (
    <ShareBarWrapper>
      <Bar />
      <BottomContent>
        <Typography
          variant="subtitle2"
          component="h2"
          sx={{ color: 'text.secondary' }}
        >
          Dela
        </Typography>
        <ShareButtons isPublicPage={isPublicPage} />
      </BottomContent>
    </ShareBarWrapper>
  )
}

export default ShareBar
