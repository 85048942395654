import React, { PropsWithChildren } from 'react'
import { styled, Typography } from '@mui/material'

export interface ArticleListProps {
  heading?: string
}

const List = styled('ul')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  listStyle: 'none',
  padding: '0',
  margin: '0',
  maxWidth: '752px',

  li: {
    borderBottom: `1px solid ${theme.palette.neutral?.divider}`,

    '&:first-of-type': {
      borderTop: `1px solid ${theme.palette.neutral?.divider}`,
    },

    a: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(2),
    },
  },
}))

const ScreenReaderOnlyHeading = styled('h2')(() => ({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: '1px',
}))

const ArticleList = ({
  heading,
  children,
}: PropsWithChildren<ArticleListProps>) => {
  return (
    <>
      {heading ? (
        <Typography variant="h4" component="h2" sx={{ mb: 2 }}>
          {heading}
        </Typography>
      ) : (
        <ScreenReaderOnlyHeading>Länkar</ScreenReaderOnlyHeading>
      )}
      <List>{children}</List>
    </>
  )
}

export default ArticleList
