import userManager from 'features/Authentication/userManager'
import { applicationInsights } from 'packages/app-shell-communication'
import { useCallback, useEffect, useRef } from 'react'
import { toRoundedISOString } from '../helpers'

const MAX_RENEW_INTERVAL = 3000 // 3s

const trackEvent = (name: string, operationId: number) => {
  applicationInsights.trackEvent({
    name,
    properties: {
      repo: 'public-web-frontend',
      clientTime: toRoundedISOString(new Date()),
      operationId,
    },
  })
}

const trackError = (name: string, error: unknown, operationId: number) => {
  if (error instanceof Error) {
    applicationInsights.trackException({
      exception: new Error(`${name}: ${error.message}`),
      properties: {
        repo: 'public-web-frontend',
        clientTime: toRoundedISOString(new Date()),
        operationId,
      },
    })
  }
}

const getRandomId = () => Math.round(Math.random() * 100000000000000)

const useAccessTokenExpiringListener = () => {
  const lastSignInSilentRef = useRef<number>(0)

  const accessTokenExpiringCallback = useCallback(async () => {
    const operationId = getRandomId()
    try {
      /*
        Prevents multiple consecutive renewals. This is necessary because calling 'getUser'
        in 'userManager.signinSilent' triggers an 'expired' event when the user is about to expire.

        Usually, this isn't a problem, but we need to determine the user manager to use by checking
        if the currently logged-in user is a 'klient', 'kund', or 'medarbetare' by calling getUser
        on every user manager.

        To prevent this, we only allow one event every 3 seconds to trigger a 'signinSilent'.
      */
      const timeSinceLastSignInSilent = Date.now() - lastSignInSilentRef.current
      if (timeSinceLastSignInSilent > MAX_RENEW_INTERVAL) {
        lastSignInSilentRef.current = Date.now()
        trackEvent('silentRenewStarted', operationId)
        await userManager.signinSilent({
          state: { expiringTokenWasRefreshed: true },
        })
        trackEvent('trackSilentRenewCompleted', operationId)
      }
    } catch (error) {
      console.error(error)
      trackError('silentRenewFailed', error, operationId)
    }
  }, [])

  useEffect(() => {
    userManager.events.addAccessTokenExpiring(accessTokenExpiringCallback)

    // Cleanup events
    return () => {
      userManager.events.removeAccessTokenExpiring(accessTokenExpiringCallback)
    }
  }, [accessTokenExpiringCallback])
}

export default useAccessTokenExpiringListener
