import React from 'react'
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material'
import { useSliceStateSelector } from 'slices/hooks'

const ErrorTable = () => {
  const { errorLogs } = useSliceStateSelector((state) => state.slices.gtmLogs)
  return (
    <Table data-testid="gtm-log-error" stickyHeader size="small">
      <TableHead>
        <TableRow>
          <TableCell>Index</TableCell>
          <TableCell>Origin</TableCell>
          <TableCell>Event</TableCell>
          <TableCell>Action</TableCell>
          <TableCell>Item</TableCell>
          <TableCell>Distinguisher</TableCell>
          <TableCell>Failed condition</TableCell>
          <TableCell>Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {errorLogs.map((log, index) => (
          <TableRow
            key={`gtm-log-${index}`}
            sx={{
              '&:last-child td, &:last-child th': { border: 0 },
              '&:nth-of-type(odd)': {
                backgroundColor: 'surface.grey',
              },
            }}
          >
            <TableCell>{errorLogs.length - index}</TableCell>
            <TableCell>{log.origin}</TableCell>
            <TableCell>{log.event}</TableCell>
            <TableCell>{log.action}</TableCell>
            <TableCell>{log.item}</TableCell>
            <TableCell>{log.distinguisher}</TableCell>
            <TableCell>{log.reason}</TableCell>
            <TableCell>💩</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default ErrorTable
