import { ThemeOptions } from '@mui/material/styles/createTheme'
import calculateRemFromPx from '../utils/calculateRemFromPx'
import { TypographyVariant } from '@mui/material'

const headingOptions = {
  fontFamily: 'Raleway',
  fontFeatureSettings: '"lnum", "pnum"',
  fontVariantLigatures: 'no-common-ligatures',
  fontWeight: 700,
  lineHeight: 1.4,
}

export const typographyOptions: Partial<ThemeOptions> = {
  typography: {
    fontSize: 16,
    fontFamily: 'Open Sans',
    display: {
      ...headingOptions,
      fontWeight: 800,
      fontSize: calculateRemFromPx(64),
      lineHeight: 1.2,
    },
    h1: {
      ...headingOptions,
      fontSize: calculateRemFromPx(52),
      lineHeight: 1.3,
    },
    h2: {
      ...headingOptions,
      fontSize: calculateRemFromPx(40),
    },
    h3: {
      ...headingOptions,
      fontSize: calculateRemFromPx(32),
    },
    h4: {
      ...headingOptions,
      fontSize: calculateRemFromPx(28),
    },
    h5: {
      ...headingOptions,
      fontSize: calculateRemFromPx(24),
    },
    h6: {
      ...headingOptions,
      fontSize: calculateRemFromPx(20),
    },
    body1: {
      fontWeight: 400,
      fontSize: calculateRemFromPx(16),
      lineHeight: 1.5,
    },
    body2: {
      fontWeight: 400,
      fontSize: calculateRemFromPx(14),
      lineHeight: 1.43,
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: calculateRemFromPx(16),
      lineHeight: 1.5,
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: calculateRemFromPx(14),
      lineHeight: 1.43,
    },
    preamble: {
      //  Specify font family due to MUI not styling custom variants
      fontFamily: 'Open Sans',
      fontWeight: 400,
      fontSize: calculateRemFromPx(22),
      lineHeight: 1.5,
    },
    caption: {
      fontWeight: 400,
      fontSize: calculateRemFromPx(12),
      lineHeight: 1.4,
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          display: 'display',
          preamble: 'p',
        },
      },
      styleOverrides: {
        gutterBottom: {
          marginBottom: '24px',
        },
      },
    },
  },
}

type CustomTypographyVariants =
  | Exclude<TypographyVariant, 'button' | 'overline'>
  | 'display'
  | 'preamble'
  | 'caption'

export const typographyVariants: CustomTypographyVariants[] = [
  'body1',
  'body2',
  'display',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'caption',
  'subtitle1',
  'subtitle2',
  'preamble',
]
