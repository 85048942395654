import React from 'react'
import { Box, styled } from '@mui/material'
import Header from './Header'
import Main from './Main'
import Footer from './Footer'
import {
  useDeviceSize,
  useOnlineListener,
  useRouteFullScreenMode,
  useSetupSignalRConnection,
  useVisitorContext,
} from './hooks'
import {
  useApplicationInsightsEventListener,
  useSetAuthenticatedUser,
} from 'features/Telemetry/ApplicationInsights'
import CookieBanner from 'features/CookieBanner'
import VersionBanner from 'features/VersionBanner'
import SystemInformation from './SystemInformation'
import FullScreenMain from './FullScreenMain'
import NewHeader from './Header/NewHeader/NewHeader'
import NewFooter from './Footer/NewFooter/NewFooter'
import { FetchContent } from 'apps/Site/Epi/FetchContent'
import { ContentContainer } from './ContentContainer'
import { cookieName, getCookieValue } from 'utils/cookies'
import { Breadcrumbs } from 'apps/Site/components/Breadcrumbs'
import { FeatureFlagWrapper } from 'features/FeatureFlagWrapper'
import { useSliceStateSelector } from 'slices/hooks'
import Preview from './Epi/Preview'
import Content from './Epi/Content'
import { ZendeskChat } from './Epi/ZendeskChat'
import { useCachedFeatureFlagsState } from 'PublicWebApi'

const StyledBox = styled(Box)(() => ({
  minHeight: '100vh',
  display: 'flex',
  flex: '1 1 auto',
  flexFlow: 'column nowrap',
  width: '100%',
  backgroundColor: 'white',
}))

const Site = () => {
  const isFullScreen = useRouteFullScreenMode()
  const isTrrCookiesApproved = useSliceStateSelector(
    (state) => state.slices.cookies.isTrrCookiesApproved
  )
  useSetupSignalRConnection()
  useDeviceSize()
  useOnlineListener()
  useApplicationInsightsEventListener()
  useSetAuthenticatedUser()
  useVisitorContext()

  const newNavigationFeatureFlag = useCachedFeatureFlagsState().includes(
    'Public-Web-Frontend_newNavigation_temp_240611'
  )

  const useNewNavigationSystem =
    newNavigationFeatureFlag ||
    Boolean(getCookieValue(cookieName.newNavigationSystemCookie))

  if (isFullScreen) {
    return (
      <StyledBox>
        <Preview />
        <VersionBanner />
        <CookieBanner />
        <SystemInformation />
        <FetchContent>
          <FullScreenMain>
            <Content isFullScreen />
          </FullScreenMain>
        </FetchContent>
      </StyledBox>
    )
  }

  return (
    <StyledBox>
      <Preview />
      <VersionBanner />
      <CookieBanner />
      <SystemInformation />
      {useNewNavigationSystem ? <NewHeader /> : <Header />}
      <ContentContainer>
        <FetchContent>
          <Main>
            <>
              <Breadcrumbs />
              <Content isFullScreen={false} />
              {isTrrCookiesApproved && (
                <FeatureFlagWrapper featureFlag="zendeskChat">
                  <ZendeskChat />
                </FeatureFlagWrapper>
              )}
            </>
          </Main>
        </FetchContent>
      </ContentContainer>
      {useNewNavigationSystem ? <NewFooter /> : <Footer />}
    </StyledBox>
  )
}

export default Site
