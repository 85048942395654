import React, { PropsWithChildren } from 'react'
import { getBackgroundColor } from 'apps/Site/hooks/getBackgroundColor'
import { styled, Box, Typography } from '@mui/material'
import { AppshellLink } from '@internal-helpers'

export interface IInformationBoxProps {
  heading: string
  body: string
  link: {
    text: string
    url: string
  }
  color: string
  useColor: boolean
}

interface InformationBoxWrapperProps {
  backgroundColor: string
  useColor: boolean
}

const InformationBoxWrapper = styled('section')<InformationBoxWrapperProps>(
  ({ theme, backgroundColor, useColor }) => ({
    backgroundColor: getBackgroundColor(theme, backgroundColor, useColor),
    padding: useColor && theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),

    [theme.breakpoints.up('lg')]: {
      padding: useColor && theme.spacing(7),
      flexDirection: 'row',
      gap: theme.spacing(8),
    },
  })
)

const InformationBox = ({
  heading,
  body,
  link,
  color,
  useColor,
  children,
}: PropsWithChildren<IInformationBoxProps>) => {
  return (
    <InformationBoxWrapper backgroundColor={color} useColor={useColor}>
      <Box flex={1}>
        {heading && (
          <Typography variant="h4" component="h2" sx={{ mb: 3 }}>
            {heading}
          </Typography>
        )}
        {body && (
          <Typography variant="body1" component="p" sx={{ mb: 3 }}>
            {body}
          </Typography>
        )}
        {link?.text && link?.url && (
          <AppshellLink url={link.url} text={link.text} />
        )}
      </Box>
      <Box flex={2}>{children}</Box>
    </InformationBoxWrapper>
  )
}

export default InformationBox
