import React, { useCallback } from 'react'
import { styled, Box, alpha, ButtonBase } from '@mui/material'
import { useSliceDispatch, useSliceStateSelector } from 'slices/hooks'
import { visitorContext } from 'slices/VisitorContext/types'
import { ContextButtonProps } from 'PublicWebApi/Settings/types'
import { useTrackCustomClick } from 'features/Telemetry/GoogleTagManager/Hooks/useTrackCustomClick'

interface ContextButtonsProps {
  buttons: ContextButtonProps[]
}

interface ContextButtonLinkProps {
  href?: string
  disableRipple?: boolean
  isContextActive?: boolean
}

const ContextButton = styled(ButtonBase, {
  shouldForwardProp: (prop: string) => !['isContextActive'].includes(prop),
})<ContextButtonLinkProps>(({ theme, isContextActive }) => ({
  ...theme.typography.subtitle2,
  display: 'flex',
  padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
  borderBottomLeftRadius: '8px',
  borderBottomRightRadius: '8px',
  color: theme.palette.text.primary,
  textDecoration: 'none',
  height: 'fit-content',

  ...(isContextActive && {
    color: theme.palette.primary?.dark,
    backgroundColor: theme.palette.surface?.purple,
  }),

  '&:hover': {
    backgroundColor: alpha(theme.palette.primary?.main, 0.04),
    color: theme.palette.primary?.main,
  },

  '&:focus-visible': {
    backgroundColor: alpha(theme.palette.primary?.main, 0.3),
    outline: 'none',
    color: theme.palette.text?.primary,
  },
}))

const ContextButtons = ({ buttons }: ContextButtonsProps) => {
  const { visitorContextStatus } = useSliceStateSelector(
    (state) => state.slices.visitorContexts
  )

  const trigger = useSliceDispatch()
  const trackCustomClick = useTrackCustomClick()

  const getActiveContext = useCallback(
    (contextTitle: visitorContext) => {
      return visitorContextStatus === contextTitle
    },
    [visitorContextStatus]
  )

  const onContextClick = (
    context: visitorContext,
    title: string,
    href: string
  ) => {
    trackCustomClick('button', {
      label: `Klick på kontext-knapp i headern: ${title}`,
      href: href,
    })
    if (context !== visitorContextStatus) {
      trigger('setContextStatus', context)
    }
  }

  return (
    <Box display="flex">
      {buttons?.map(({ title, url, context }) => (
        <ContextButton
          href={url}
          key={`contextbutton-${title}`}
          data-testid={`contextbutton-${title}`}
          isContextActive={getActiveContext(context)}
          onClick={() => onContextClick(context, title, url)}
        >
          {title}
        </ContextButton>
      ))}
    </Box>
  )
}

export default ContextButtons
