import {
  UserManager,
  UserManagerSettings,
  WebStorageStateStore,
  Log,
} from 'oidc-client-ts'
import configService from 'utils/configService'
import Cookies from 'js-cookie'

import {
  getUserManagerRedirectUri,
  getUserManagerSilentRedirectUri,
} from './helpers/getRedirectUrl'
import { medarbetarePathname } from 'features/Authentication/helpers'

// Only log on localhost or at dev env, ignore everything else including jest env
if (Cookies.get('appshell-log-auth') === 'true') {
  Log.setLogger(console)
}

const { AUTHORITY_URL } = configService.config

const baseSettings: UserManagerSettings = {
  authority: AUTHORITY_URL,
  automaticSilentRenew: false,
  client_id: 'Trr.Oauth.PublicWebKlient',
  filterProtocolClaims: true,
  accessTokenExpiringNotificationTimeInSeconds: 60, // 1 min,
  includeIdTokenInSilentRenew: false,
  loadUserInfo: false,
  redirect_uri: getUserManagerRedirectUri(),
  response_type: 'code',
  validateSubOnSilentRenew: false,
  scope: 'trr_klient_profile trr_klient openid',
  post_logout_redirect_uri: window.location.origin,
  silent_redirect_uri: getUserManagerSilentRedirectUri(),
  userStore: new WebStorageStateStore({ store: window.localStorage }),
}

const kundLoginSettings = {
  client_id: 'Trr.Oauth.PublicWebKund',
  scope: 'trr_kund_profile openid',
}

const medarbetarSettings = {
  client_id: 'Trr.Oauth.Medarbetare',
  response_type: 'code',
  scope: 'trr_medarbetare openid',
  accessTokenExpiringNotificationTime: 60, // 1 min
  redirect_uri: getUserManagerRedirectUri(medarbetarePathname),
}

const settings = {
  ...baseSettings,
}

const medarbetareSettings = {
  ...baseSettings,
  ...medarbetarSettings,
}

const kundSettings = {
  ...baseSettings,
  ...kundLoginSettings,
}

export const klientUserManager = new UserManager(settings)
export const medarbetareUserManager = new UserManager(medarbetareSettings)
export const kundUserManager = new UserManager(kundSettings)
