import {
  AI_ACTIONS,
  AI_VERSION,
  trackDependencyData,
  trackEvent,
  trackException,
  __internal__dispatchApplicationInsightsEvent,
  __internalEvents,
} from './ApplicationInsights'

export {
  goToLogin,
  goToLoginEmployer,
  goToLogout,
  redirectToAuthServer,
  redirectToAuthServerEmployer,
  userCreationFinished,
  abortLogin,
} from './Authentication'
export { refreshUserProfile } from './User'
export { getOIDCUser } from './getOIDCUser'
export type { OidcUser, Profile } from './getOIDCUser'
export { __api_internals__ } from './Event.names'
export { internal_trackGtmCustomEvent } from './GoogleTagManager'
export type { gtmCustomEvent, customEventData } from './GoogleTagManager'
export type {
  IApplicationInsightsExceptionPayload,
  IApplicationInsightsEventPayload,
  IApplicationInsightsDependencyDataPayload,
  ApplicationInsightsCustomEvent,
  IApplicationInsightsEvent,
} from './ApplicationInsights'

export const applicationInsights = {
  AI_ACTIONS,
  AI_VERSION,
  trackDependencyData,
  trackEvent,
  trackException,
  __internalEvents,
  __internal__dispatchApplicationInsightsEvent,
}
