import {
  Card,
  styled,
  Typography,
  CardActionArea,
  CardContent,
  Box,
} from '@mui/material'
import { getBackgroundColor } from 'apps/Site/hooks/getBackgroundColor'

interface BackgroundColorProps {
  backgroundColor?: string
  useColor?: boolean
}

interface LinkProp {
  href: string
  target: string
  rel: string
}

export const CardWrapper = styled(Card, {
  shouldForwardProp: (prop: string) =>
    !['backgroundColor', 'useColor'].includes(prop),
})<BackgroundColorProps>(({ theme, backgroundColor, useColor }) => ({
  backgroundColor:
    backgroundColor &&
    useColor &&
    getBackgroundColor(theme, backgroundColor, useColor),
  border: backgroundColor && useColor && 'none',
  display: 'flex',
  flexGrow: 1,
  flexShrink: 1,
  flexBasis: '100%',
  [theme.breakpoints.up('md')]: {
    flexBasis: 0,
  },
}))

export const CardActionAreaWrapper = styled(CardActionArea)<LinkProp>(() => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}))

export const CardContentWrapper = styled(CardContent)(({ theme }) => ({
  flex: 1,
  width: '100%',
  marginBottom: theme.spacing(3),
}))

export const LinkText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary?.main,
  fontSize: '0.875rem',
  '&:hover': {
    textDecoration: 'underline',
  },
}))

export const ExternalLinkIconWrapper = styled('span')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  marginRight: theme.spacing(1),
}))

interface Image {
  cardsInRow: number
}

export const ImageWrapper = styled(Box, {
  shouldForwardProp: (prop: string) => !['cardsInRow'].includes(prop),
})<Image>(({ theme, cardsInRow }) => ({
  [theme.breakpoints.up('md')]: {
    minHeight: cardsInRow === 2 && '314px',
  },
  minHeight: '204px',
  aspectRatio: '16/9',
  display: 'flex',
  width: '100%',
  img: {
    height: '100%',
    objectFit: 'cover',
    verticalAlign: 'middle',
    width: '100%',
  },
  '.LazyLoadWrapper': {
    height: '100%',
    width: '100%',
  },
}))
