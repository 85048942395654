import { Box, Typography, styled } from '@mui/material'

export const Heading = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    marginBottom: theme.spacing(3),
  },
}))

export const CardsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(3),
  },
}))

export const Row = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: theme.spacing(2),

  [theme.breakpoints.up('md')]: {
    gap: theme.spacing(3),
    '& > div:only-child': {
      maxWidth: `calc((100% - ${theme.spacing(3)} * 2) / 3)`,
    },
  },
}))
