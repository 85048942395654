import React from 'react'
import {
  Avatar,
  ButtonBase,
  MenuItem,
  MenuList,
  Popover,
  styled,
  Typography,
} from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import { StyledSecondaryActionButton as StyledMedarbetareAvatarButton } from 'apps/Site/Header/NewHeader/NewHeader.styles'
import { useAuthentication } from 'features/Authentication/Hooks'
import { useSliceDispatch } from 'slices/hooks'

interface MedarbetareAvatarButtonProps {
  firstName: string
  image: string
  imageFileName: string
  isMobile: boolean
}

interface PopoverButtonProps {
  href?: string
}

const StyledPopoverButton = styled(ButtonBase)<PopoverButtonProps>(
  ({ theme }) => ({
    ...theme.typography.subtitle1,
    display: 'flex',
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    width: '100%',
    justifyContent: 'flex-start',
  })
)

const MedarbetareAvatarButton = ({
  firstName,
  image,
  imageFileName,
  isMobile,
}: MedarbetareAvatarButtonProps) => {
  const trigger = useSliceDispatch()
  const { logout } = useAuthentication()
  const firstLetter = firstName?.slice(0, 1)

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const closePopover = () => {
    setAnchorEl(null)
    trigger('setSmallDeviceMenuStatus', 'none')
  }
  const open = Boolean(anchorEl)
  const id = open ? 'logout-popover' : undefined

  return (
    <>
      <StyledMedarbetareAvatarButton
        onClick={openPopover}
        disableRipple
        key={'avatarbutton'}
        data-testid={'header-avatar-button-medarbetare'}
      >
        <Avatar
          sx={{ height: '24px', width: '24px', bgcolor: 'primary.main' }}
          {...(imageFileName && {
            src: image,
            alt: 'Profilbild',
          })}
          data-testid="loggedin-medarbetare-avatar"
        >
          <Typography variant="subtitle2">{firstLetter}</Typography>
        </Avatar>
        {!isMobile && <>{firstName}</>}
      </StyledMedarbetareAvatarButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClick={closePopover}
        onClose={closePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ marginTop: 1 }}
      >
        <MenuList disablePadding>
          <MenuItem sx={{ padding: 0 }}>
            <StyledPopoverButton href={'/medarbetare'}>
              Medarbetare
            </StyledPopoverButton>
          </MenuItem>
          <MenuItem sx={{ padding: 0 }}>
            <StyledPopoverButton href={'/mitt-trr'}>
              Mitt TRR
            </StyledPopoverButton>
          </MenuItem>
          <MenuItem sx={{ padding: 0 }}>
            <StyledPopoverButton href={'/'}>TRR.se</StyledPopoverButton>
          </MenuItem>
          <MenuItem sx={{ padding: 0 }}>
            <StyledPopoverButton onClick={logout}>
              <LogoutIcon /> Logga ut
            </StyledPopoverButton>
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  )
}

export default MedarbetareAvatarButton
