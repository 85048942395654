import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithHeaders } from 'state/helpers'
import configService from 'utils/configService'

export const publicWebApi = createApi({
  reducerPath: 'publicWebApi',
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  baseQuery: baseQueryWithHeaders(configService.config.API_URL as string),
  tagTypes: ['Settings', 'Content', 'UserProfile'],
  endpoints: () => ({}),
})

export default publicWebApi
