import React from 'react'
import { AppshellLink, FontIcon } from 'packages/internal-helpers'

import { BulletListItemProperties } from '../BulletList'

import {
  AvatarPoint,
  AvatarWrapper,
  Body,
  Heading,
  LinkWrapper,
  TextWrapper,
} from './BulletListPoints.styles'

export const IconPoint = ({
  iconName,
  heading,
  body,
  link,
}: BulletListItemProperties): JSX.Element => {
  return (
    <AvatarPoint>
      <span aria-hidden="true">
        <AvatarWrapper data-testid={'avatar-wrapper'}>
          <FontIcon iconName={iconName} />
        </AvatarWrapper>
      </span>
      <TextWrapper>
        <Heading component="span">{heading}</Heading>
        {body && <Body component="span">{body}</Body>}
        {link?.url && link?.text && (
          <LinkWrapper>
            <AppshellLink url={link.url} text={link.text} />
          </LinkWrapper>
        )}
      </TextWrapper>
    </AvatarPoint>
  )
}
