import ButtonAndLink from 'apps/Site/components/ButtonAndLink/ButtonAndLink'
import { Box, Typography } from '@mui/material'
import React from 'react'

export interface CTABlockProps {
  content: {
    heading: string
    preamble: string
    link?: {
      text: string
      url: string
    }
    button?: {
      text: string
      url: string
    }
  }
}

const CTABlock = ({
  content: { heading, preamble, link = undefined, button = undefined },
}: CTABlockProps) => {
  return (
    <section>
      <Box maxWidth="752px">
        {heading && (
          <Typography variant="h4" component="h2" mb={3}>
            {heading}
          </Typography>
        )}
        {preamble && (
          <Typography variant="body1" component="p" mb={3}>
            {preamble}
          </Typography>
        )}
        {(button || link) && <ButtonAndLink button={button} link={link} />}
      </Box>
    </section>
  )
}

export default CTABlock
