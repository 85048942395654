import React from 'react'
import { FontIcon } from '@internal-helpers'
import { Box } from '@mui/material'
import { useAuthentication } from 'features/Authentication/Hooks'
import { useSliceStateSelector } from 'slices/hooks'
import AvatarButton from '../../AvatarButton'
import { StyledSecondaryActionButton } from '../../NewHeader.styles'
import { getLoggedInContext, getLoginUrl } from '../../NewHeader.hooks'
import { useTrackCustomClick } from 'features/Telemetry/GoogleTagManager/Hooks/useTrackCustomClick'

interface SecondaryActionButtonProps {
  icon: string
  title: string
  url: string
  isLoginButton?: boolean
}

const secondaryActionButtonsData: SecondaryActionButtonProps[] = [
  {
    icon: 'Search',
    title: 'Sök',
    url: '/sok',
  },
  {
    icon: 'AccountCircle',
    title: 'Logga in',
    url: '/login',
    isLoginButton: true,
  },
  {
    icon: 'Language',
    title: 'In English',
    url: '/trr-in-english/',
  },
]

const SecondaryActionButtons = () => {
  const { visitorContextStatus } = useSliceStateSelector(
    (state) => state.slices.visitorContexts
  )

  const { isLoggedIn, profile } = useAuthentication()
  const userRole = profile.idp

  const trackCustomClick = useTrackCustomClick()

  return (
    <Box display="flex">
      {secondaryActionButtonsData?.map(({ icon, title, url, isLoginButton }) =>
        isLoginButton && isLoggedIn ? (
          <AvatarButton
            key={'avatarbutton-component'}
            visitorContext={getLoggedInContext(userRole, visitorContextStatus)}
          />
        ) : (
          <StyledSecondaryActionButton
            href={
              isLoginButton
                ? getLoginUrl(url, visitorContextStatus as string)
                : url
            }
            onClick={() => {
              trackCustomClick('Header top button', {
                label: `Klick på ${title}-knapp`,
                href: isLoginButton
                  ? getLoginUrl(url, visitorContextStatus as string)
                  : url,
              })
            }}
            disableRipple
            key={`secondaryactionbutton-${title}`}
            data-testid={
              isLoginButton
                ? 'header-avatar-button'
                : `secondaryactionbutton-${title}`
            }
          >
            <FontIcon iconName={icon} />
            {title}
          </StyledSecondaryActionButton>
        )
      )}
    </Box>
  )
}

export default SecondaryActionButtons
