import React from 'react'
import { styled } from '@mui/material'
import ContextButtons from '../ContextButtons/ContextButtons'
import SecondaryActionButtons from '../SecondaryActionButtons/SecondaryActionButtons'
import { ContextButtonProps } from 'PublicWebApi/Settings/types'

const NavbarTopWrapper = styled('nav')(({ theme }) => ({
  height: 'fit-content',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '100%',
  maxWidth: '1248px',
  display: 'flex',
  justifyContent: 'space-between',
  padding: `0 ${theme.spacing(6)} 0 ${theme.spacing(6)}`,
  [theme.breakpoints.up('xl')]: {
    padding: 0,
  },
}))

interface NavbarTopProps {
  contextButtons: ContextButtonProps[]
}

const NavbarTop = ({ contextButtons }: NavbarTopProps) => {
  return (
    <NavbarTopWrapper data-testid="navbar-top">
      <ContextButtons buttons={contextButtons} />
      <SecondaryActionButtons />
    </NavbarTopWrapper>
  )
}

export default NavbarTop
