import { Box, Typography, styled } from '@mui/material'
import VideoPlayer from 'apps/Site/components/VideoPlayer'
import React from 'react'
import { useSliceStateSelector } from 'slices/hooks'
import { getBackgroundColor } from 'apps/Site/hooks/getBackgroundColor'

import { AppshellLink, LazyLoadWrapper } from '@internal-helpers'

export interface VideoTextBlockProps {
  content: VideoTextBlockContent
}

interface VideoTextBlockContent extends VideoSectionProps, TextSectionProps {
  videoPosition?: 'left' | 'right'
  useColor?: boolean
  color?: string
}
interface VideoSectionProps {
  videoUrl: string
  fallbackUrl: string
}

interface TextSectionProps {
  heading: string
  preamble: string
  link?: {
    text: string
    url: string
  }
}

const VideoSectionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 0',
  [theme.breakpoints.up('md')]: {
    flexBasis: '720px',
  },

  '.LazyLoadWrapper': {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
}))

const VideoSection = ({ videoUrl, fallbackUrl }: VideoSectionProps) => {
  return (
    <VideoSectionWrapper data-testid="video-section">
      <LazyLoadWrapper className="LazyLoadWrapper">
        <VideoPlayer
          url={videoUrl}
          fallbackUrl={fallbackUrl}
          data-testid="video-text-videoplayer"
        />
      </LazyLoadWrapper>
    </VideoSectionWrapper>
  )
}

const TextSectionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 0',
  gap: theme.spacing(1),
  justifyContent: 'center',
  padding: theme.spacing(3),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(7),
    flexBasis: '420px',
    gap: theme.spacing(3),
  },
}))

const TextSection = ({ heading, preamble, link }: TextSectionProps) => {
  return (
    <TextSectionWrapper data-testid="text-section">
      <Typography component="h2" variant="h3">
        {heading}
      </Typography>
      <Typography variant="body1">{preamble}</Typography>
      {link && <AppshellLink text={link.text} url={link.url} mt={2} />}
    </TextSectionWrapper>
  )
}

interface VideoTextBlockColors {
  bgColor: string
  useColor: boolean
}

const VideoTextBlockWrapper = styled(Box, {
  shouldForwardProp: (props: string) =>
    !['bgColor', 'useColor'].includes(props),
})<VideoTextBlockColors>(({ theme, bgColor, useColor }) => ({
  backgroundColor: getBackgroundColor(theme, bgColor, useColor),
  display: 'flex',
  flexDirection: 'column',
  minHeight: '200px',

  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}))

const VideoTextBlock = ({
  content: {
    videoUrl,
    heading,
    preamble,
    link,
    videoPosition,
    useColor,
    color,
    fallbackUrl,
  },
}: VideoTextBlockProps) => {
  const { isMobile } = useSliceStateSelector(
    (state) => state.slices.device.deviceSize
  )

  const components = {
    video: <VideoSection videoUrl={videoUrl} fallbackUrl={fallbackUrl} />,
    text: <TextSection heading={heading} preamble={preamble} link={link} />,
  }

  const videoTextComponentsInOrder =
    videoPosition === 'left' || isMobile ? (
      <>
        {components.video}
        {components.text}
      </>
    ) : (
      <>
        {components.text}
        {components.video}
      </>
    )

  return (
    <VideoTextBlockWrapper bgColor={color} useColor={useColor}>
      {videoTextComponentsInOrder}
    </VideoTextBlockWrapper>
  )
}

export default VideoTextBlock
