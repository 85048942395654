import React from 'react'
import MicroFrontendLoader from 'features/SystemLoaders/MicroFrontendLoader'
import FunctionPageWrapper from './FunctionPageWrapper'
import { FunctionPageProperties } from 'state/PublicWebApi/Content'

const FunctionWithoutTabs = (props: FunctionPageProperties) => (
  <FunctionPageWrapper {...props}>
    <MicroFrontendLoader
      currentKey={props.urlKey}
      functionType={props.functionType}
    />
  </FunctionPageWrapper>
)

export default FunctionWithoutTabs
