import {
  internal_trackGtmCustomEvent,
  customEventData,
} from 'packages/app-shell-communication'
import { useCallback, useContext } from 'react'

import { GTMTrackingContext } from '../Context/GTMTrackingContext'

type trackCustomClickTrigger = (
  identifier: string,
  options?: {
    label?: string
    href?: string
  }
) => void

export const internal_buildCustomClickEvent = (
  identifier: string,
  options: { label?: string; href?: string },
  mfName: string = undefined,
  overrideGtmEnabledBlock: boolean = false
) => {
  const event: customEventData = {
    event_action: 'click',
    event_item: identifier,
    event_data: [],
    mfName: mfName,
    overrideGtmEnabledBlock: overrideGtmEnabledBlock,
  }
  if (options?.label) {
    event.event_data.push({ key: 'label', value: options.label })
  }
  if (options?.href) {
    event.event_data.push({ key: 'href', value: options.href })
  }
  return event
}

/**
 * A hook that allows the user to track custom clicks. This can be usedfull when base tracking does not
 * cover the needs.
 *
 * @param  {string}  identifier - The identifier of of the item clicked
 * @param  {string}  options.label - The potential inner text of the item clicked
 * @param  {string}  options.href - The potential href of the item clicked.
 * @returns {function(): void} The trigger function used to send an event to the datalayer in GTM.
 */
export const useTrackCustomClick = (): trackCustomClickTrigger => {
  const context = useContext(GTMTrackingContext)
  const trackerFunction = useCallback<trackCustomClickTrigger>(
    (identifier, options?) => {
      if (!context) {
        process?.env?.NODE_ENV === 'development' &&
          console.warn(
            'In order to use useTrackCustomClick you must wrap your component in `GTMTracker`'
          )
        return
      }

      const customClickEvent = internal_buildCustomClickEvent(
        identifier,
        options,
        context.mfName
      )

      internal_trackGtmCustomEvent(customClickEvent)
    },
    []
  )
  return trackerFunction
}
