import { publicWebApi } from 'state/PublicWebApi'

const featureFlagsApi = publicWebApi.injectEndpoints({
  endpoints: (builder) => ({
    getFeatureFlags: builder.query<string[], void>({
      query: () => `settings/app-config`,
      transformResponse: (res: { enabledFeatures: string[] }) =>
        res.enabledFeatures,
    }),
  }),
})

/**
 * A hook that returns the last cached featureflag state.
 *
 * Needs to be ran in a component that is a child of <PrepareAppShell />
 *
 * Will throw an error if ran outside of ```<PrepareAppShell />```
 *
 *
 * @returns
 *  ```
 * string[]
 * ```
 */
export const useCachedFeatureFlagsState = () => {
  const { data } = featureFlagsApi.endpoints.getFeatureFlags.useQueryState()
  if (data) {
    return data
  }
  throw new Error(
    'This hook needs to be ran in a component that is a child of <PrepareAppShell />, or the endpoint has failed'
  )
}

export default featureFlagsApi
