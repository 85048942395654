import React from 'react'
import { TableOfContents } from 'apps/Site/components/TableOfContents'
import RelatedContent from 'apps/Site/components/RelatedContent'
import { ArticleListItemProps } from 'apps/Site/components/ArticleList/ArticleListItem'
import HTMLMapper from 'apps/Site/Epi/HTMLMapper'
import { Introduction } from 'apps/Site/Epi/Blocks'
import { IntroductionContentProps } from 'apps/Site/Epi/Blocks/Introduction/Introduction'
import DetailsBar from 'apps/Site/components/DetailsBar'
import ShareBar from 'apps/Site/components/ShareBar'
import ArticleTopMedia from 'apps/Site/Epi/Blocks/ArticleTopMedia'
import { ArticleVideoBlockProps } from 'apps/Site/Epi/Blocks/ArticleVideoBlock/ArticleVideoBlock'
import { TableOfContentLink } from 'state/PublicWebApi/Content'
import { Grid } from '@mui/material'

export interface ArticleContentProps {
  editor?: string
  introduction?: IntroductionContentProps
  tableOfContents?: TableOfContentLink[]
  customCategory?: string
  relatedContent?: ArticleListItemProps[]
  image?: string
  startPublish?: string
  videoArea?: ArticleVideoBlockProps[]
  isPublic?: boolean
}

const ArticleContent = ({
  introduction,
  tableOfContents,
  customCategory,
  image,
  editor,
  relatedContent,
  startPublish,
  videoArea,
  isPublic,
}: ArticleContentProps) => {
  return (
    <article>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Introduction content={introduction} />
          <TableOfContents items={tableOfContents} />
          <DetailsBar
            category={customCategory}
            isPublicPage={isPublic}
            {...(startPublish && { date: startPublish })}
          />
        </Grid>
        {(videoArea?.length > 0 || image) && (
          <Grid item xs={12}>
            <ArticleTopMedia
              image={image}
              videoProperties={{
                properties: { ...videoArea?.[0]?.properties },
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} md={8}>
          <HTMLMapper body={editor} wrapper={'section'} />
          <ShareBar isPublicPage={isPublic} />
          {relatedContent?.length > 0 && (
            <RelatedContent relatedContent={relatedContent} />
          )}
        </Grid>
      </Grid>
    </article>
  )
}

export default ArticleContent
