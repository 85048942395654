import React from 'react'
import { HeroAreaPanelProps } from 'apps/Site/components/HeroAreaPanel/HeroAreaPanel'

import { IContentBlock } from '../../BlockMapper/BlockMapper'
import Block from '../Block'

export interface PublicLandingProps {
  heroAreaPanel: HeroAreaPanelProps[]
  blockArea: IContentBlock[]
}

const PublicLanding = ({ heroAreaPanel, blockArea }: PublicLandingProps) => {
  return <Block heroAreaPanel={heroAreaPanel} blockArea={blockArea} />
}

export default PublicLanding
