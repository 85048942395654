const linkFileFormats = [
  'doc',
  'docx',
  'odt',
  'xls',
  'xlsx',
  'xlt',
  'xltx',
  'ods',
  'pdf',
]

export const isLinkFile = (href: string): boolean => {
  const hrefParts = href.split('.')
  return linkFileFormats.indexOf(hrefParts[hrefParts.length - 1]) > -1
}
