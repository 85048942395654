import configService from 'utils/configService'
import { getCookieValue } from 'utils/cookies'
import { DataLayer } from './Types'
import { gtmLogger } from './gtmLogger'

const scriptId = 'gtm-script'

const TagManager = {
  initialize: () => {
    const gtmScript = document.getElementById(scriptId)

    if (!window.dataLayer) {
      window.dataLayer = []
    }

    if (!gtmScript) {
      const script = document.createElement('script')
      script.id = scriptId
      script.innerHTML = `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://analytics.trr.se/gtm.js?id='+i+dl+'&gtm_auth=${configService.config.GOOGLE_TAG_MANAGER_AUTH}&gtm_preview=${configService.config.GOOGLE_TAG_MANAGER_PREVIEW}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${configService.config.GOOGLE_TAG_MANAGER_ID}');
      `
      document.head.insertBefore(script, document.head.childNodes[0])
    }
  },

  dataLayer: (item: DataLayer) => {
    window.dataLayer.push(item)
    if (getCookieValue('appshell-gtm-log')) {
      gtmLogger.success()
    }
  },
}

export default TagManager
