import React from 'react'
import { Box, Link, Typography } from '@mui/material'
import { ScrollWrapper } from 'apps/Site/Epi/ScrollWrapper'
import Surfly from 'apps/Site/Surfly/Surfly'
import { AppshellLink } from '@internal-helpers'

import {
  FooterWrapper,
  TopRowWrapper,
  TopRowContentWrapper,
  ColumnHeader,
  List,
  BottomRowWrapper,
  BottomRowContentWrapper,
  ScreenReaderOnly,
  InformationLinks,
  BottomRowLeft,
  FooterLogo,
  ContactLinksWrapper,
} from './Footer.styles'
import ContactLink from './ContactLink'
import Logo from 'apps/Site/components/Logo'
import {
  useCachedFeatureFlagsState,
  useCachedSettingsState,
} from 'state/PublicWebApi'

const Footer = () => {
  const surflyFeature = useCachedFeatureFlagsState().includes('surfly')
  const footerContent = useCachedSettingsState().footer
  return (
    <ScrollWrapper>
      <FooterWrapper data-testid="footer-component">
        <TopRowWrapper>
          <TopRowContentWrapper>
            {footerContent.columns?.map(
              ({ heading, headingUrl, links = [] }, index) => (
                <Box
                  component="nav"
                  aria-labelledby={`footer-heading-${index}`}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    flexShrink: 1,
                    flexBasis: '0%',
                  }}
                  key={heading}
                >
                  <ColumnHeader id={`footer-heading-${index}`}>
                    <Link href={headingUrl}>{heading}</Link>
                  </ColumnHeader>
                  <List>
                    {links.map(({ name, url, showIcon }) => (
                      <li key={url}>
                        <Typography variant="subtitle2" component="p">
                          <AppshellLink
                            url={url}
                            text={name}
                            fontSize={14}
                            fontWeight={500}
                            showIcon={showIcon}
                          />
                        </Typography>
                      </li>
                    ))}
                  </List>
                </Box>
              )
            )}
          </TopRowContentWrapper>
        </TopRowWrapper>
        <BottomRowWrapper>
          <BottomRowContentWrapper>
            <BottomRowLeft>
              <FooterLogo>
                <Link href="/" aria-label="Startsida TRR.se">
                  <Logo color={'white'} testSelector="footer-logo" />
                </Link>
              </FooterLogo>
              <ContactLinksWrapper aria-labelledby="footer-contact-links">
                <ScreenReaderOnly id="footer-contact-links">
                  Kontaktlänkar
                </ScreenReaderOnly>
                <ul>
                  {footerContent.contactLinks?.map((props, index) => (
                    <li key={index}>
                      <ContactLink {...props} />
                    </li>
                  ))}
                </ul>
              </ContactLinksWrapper>
            </BottomRowLeft>
            <Box
              component="nav"
              sx={{ display: 'flex' }}
              aria-labelledby="footer-information-links"
            >
              <ScreenReaderOnly id="footer-information-links">
                Informationslänkar
              </ScreenReaderOnly>
              <InformationLinks>
                {footerContent.informationLinks?.map(({ name, url }) => (
                  <li key={name}>
                    <Link href={url}>{name}</Link>
                  </li>
                ))}
                {surflyFeature && (
                  <li>
                    <Surfly />
                  </li>
                )}
              </InformationLinks>
            </Box>
          </BottomRowContentWrapper>
        </BottomRowWrapper>
      </FooterWrapper>
    </ScrollWrapper>
  )
}

export default Footer
