import { useCachedFeatureFlagsState } from 'state/PublicWebApi'

export interface FeatureFlagProps {
  featureFlag: string
  children: JSX.Element
}

export const FeatureFlagWrapper = ({
  featureFlag,
  children,
}: FeatureFlagProps): JSX.Element =>
  useCachedFeatureFlagsState().includes(featureFlag) && children
