export const pullRequestShareUrlMatcher = new RegExp(
  '(\\/share\\/([a-z\\d-]+)\\/(\\d{4,}))(\\/|$)',
  'i'
)

const matchSiteUrlsWithinAnyObject = /"\/(?!(\/|(global|site|content)assets))/g

export const removeAppShellPrPrefix = (part: string) =>
  part.replace(pullRequestShareUrlMatcher, '/')

export const unconvertLinksIfPr = (url: string) => {
  if (process.env.URL_REWRITE === 'true') {
    return removeAppShellPrPrefix(url)
  }
  return url
}

export const convertLinksIfPr = <T extends object>(objectToRewrite: T): T => {
  if (
    !process.env?.JEST_WORKER_ID &&
    process.env.URL_REWRITE === 'true' &&
    pullRequestShareUrlMatcher.test(window.location.pathname)
  ) {
    const stringifiedObject = JSON.stringify(objectToRewrite)
    const getAppShellPrPrefix =
      pullRequestShareUrlMatcher.exec(window.location.pathname)?.[1] ?? ''
    const stringifiedObjectWithPrUrls = stringifiedObject.replaceAll(
      matchSiteUrlsWithinAnyObject,
      `"${getAppShellPrPrefix}/`
    )
    return JSON.parse(stringifiedObjectWithPrUrls) as T
  }
  return objectToRewrite
}

export const addPRPrefix = (url: string) => {
  if (pullRequestShareUrlMatcher.test(window.location.pathname)) {
    const getAppShellPrPrefix =
      pullRequestShareUrlMatcher.exec(window.location.pathname)?.[1] ?? ''
    return `${getAppShellPrPrefix}${url}`
  }
  return url
}

export type isEnforcedToSetContactInformation = {
  isEnforcedToSetContactInformation: boolean
} & Record<string, unknown>

export const goToEnforcedMissingContactInformation = (
  url: string,
  push: (path: string, state?: isEnforcedToSetContactInformation) => void,
  state: isEnforcedToSetContactInformation
): void => {
  push(addPRPrefix(url), {
    ...state,
    isEnforcedToSetContactInformation: true,
  })
}
