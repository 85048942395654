import React from 'react'
import { Button, DialogActions } from '@mui/material'
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar'

const ActionBar = (props: PickersActionBarProps): JSX.Element => {
  const { onAccept, onCancel } = props
  return (
    <DialogActions>
      <Button variant="text" onClick={() => onCancel()}>
        Avbryt
      </Button>
      <Button variant="text" onClick={() => onAccept()}>
        Ok
      </Button>
    </DialogActions>
  )
}

export default ActionBar
