import React, { useState } from 'react'
import {
  Badge,
  Box,
  IconButton,
  Paper,
  Tab,
  TableContainer,
  Tabs,
  styled,
} from '@mui/material'
import MaximizeIcon from '@mui/icons-material/ExpandLess'
import MinimizeIcon from '@mui/icons-material/ExpandMore'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew'
import { ErrorTable, SuccessTable } from './components'
import { useSliceStateSelector } from 'slices/hooks'
import { deleteCookie } from 'utils/cookies'

const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop: string) =>
    !['textColor', 'selectionFollowsFocus', 'indicator', 'fullWidth'].includes(
      prop
    ),
})(() => ({}))

const StyledBox = styled(Box, {
  shouldForwardProp: (prop: string) =>
    !['textColor', 'selectionFollowsFocus', 'indicator', 'fullWidth'].includes(
      prop
    ),
})(() => ({}))

const GtmLogTable = () => {
  const error = 'Error' as const
  const success = 'Success' as const
  const [tab, setTab] = useState<typeof error | typeof success>(success)
  const [minified, setMinified] = useState(false)
  const { errorLogs } = useSliceStateSelector((state) => state.slices.gtmLogs)

  const turnOffGtmLogger = () => {
    deleteCookie('appshell-gtm-log')
    location.reload()
  }

  return (
    <Box
      data-gtm-logger-pane={'true'}
      data-testid="gtm-logger"
      sx={{
        background: '#fff',
        borderTop: '1px solid #ccc',
        position: 'fixed',
        bottom: 0,
        zIndex: 9999,
        width: '100%',
        maxHeight: '300px',
        transition: 'height 0.3s',
        height: minified ? '48px' : '300px',
      }}
    >
      <Tabs value={tab} sx={{ px: 1 }}>
        {minified ? (
          <StyledIconButton
            data-testid="maximize"
            onClick={() => setMinified(false)}
            aria-label="maximize"
            size="small"
          >
            <MaximizeIcon />
          </StyledIconButton>
        ) : (
          <StyledIconButton
            data-testid="minimize"
            onClick={() => setMinified(true)}
            aria-label="minimize"
            size="small"
          >
            <MinimizeIcon />
          </StyledIconButton>
        )}
        <Tab onClick={() => setTab(success)} value={success} label={success} />
        <Tab
          onClick={() => setTab(error)}
          value={error}
          label={
            errorLogs.length > 0 ? (
              <Badge
                sx={{
                  '> .MuiBadge-badge': { right: '-12px' },
                  opacity: tab !== error ? 0.5 : 'unset',
                }}
                badgeContent={errorLogs.length}
                color="error"
              >
                {error}
              </Badge>
            ) : (
              <>{error}</>
            )
          }
        />
        <StyledBox flexGrow={1} />
        <StyledIconButton
          data-testid="remove-gtm-log-cookie"
          onClick={turnOffGtmLogger}
          size="small"
        >
          <PowerSettingsNewIcon />
        </StyledIconButton>
      </Tabs>

      <TableContainer
        sx={{
          display: 'block',
          overflowY: 'auto',
          height: '250px',
          borderTop: '1px solid #cccccc50',
        }}
        component={Paper}
      >
        {tab === success && <SuccessTable />}
        {tab === error && <ErrorTable />}
      </TableContainer>
    </Box>
  )
}

export default GtmLogTable
