import { addPRPrefix } from 'utils/urlTransition'
import userManager, { IdServerUser } from './../userManager'
import { applicationInsights } from 'packages/app-shell-communication'
import { medarbetarePathname } from './helpers'

const goToLogoutPath = () => {
  const isMedarbetarePathname = window.location.pathname.startsWith(
    addPRPrefix(medarbetarePathname)
  )
  isMedarbetarePathname ? location.assign('/') : location.reload()
}

/**
 * Outputs an iso string without milliseconds for readability
 *
 * @returns The iso string without milliseconds
 */
export const toRoundedISOString = (isoString: Date) => {
  try {
    return isoString.toISOString().slice(0, -5) + 'Z'
  } catch {
    return undefined
  }
}

/**
 * Converts the expiration timestamp to a rounded ISO string.
 * @param expireAt The expiration timestamp in seconds
 * @returns The rounded ISO string representation of the expiration timestamp, or undefined if the conversion fails.
 */
export const expiresAtToRoundedISOString = (expireAt: number) => {
  try {
    return toRoundedISOString(new Date(Number(expireAt + '000')))
  } catch {
    return undefined
  }
}

const trackResetEvent = (user: IdServerUser) => {
  applicationInsights.trackEvent({
    name: 'resetAuthOnExpiry',
    properties: {
      repo: 'public-web-frontend',
      userExpiresAt: expiresAtToRoundedISOString(user?.expires_at),
      clientTime: toRoundedISOString(new Date()),
      userProfileIssuedAt: expiresAtToRoundedISOString(user?.profile?.iat),
    },
  })
}

const trackResetError = (error: unknown) => {
  if (error instanceof Error) {
    applicationInsights.trackException({
      exception: new Error(`resetAuthOnExpiry: ${error.message}`),
      properties: {
        repo: 'public-web-frontend',
      },
    })
  }
}

const resetAuthOnExpiry = async () => {
  try {
    const user = await userManager.getUser()
    trackResetEvent(user)

    applicationInsights.__internalEvents.clearAuthenticatedUserContext()
    await userManager.clearStaleState()
    await userManager.removeUser()
  } catch (error: unknown) {
    console.log(error)
    trackResetError(error)
  } finally {
    goToLogoutPath()
  }
}

export default resetAuthOnExpiry
