import React, { useEffect } from 'react'
import Button from '@mui/material/Button'
import { clearAllCookies, cookieName, getCookieValue } from 'utils/cookies'

export interface CookieWithdrawBlockProps {
  content: {
    buttonText: string
  }
}

const clearLocalStorage = () => {
  for (const key in localStorage) {
    if (!key.startsWith('oidc')) {
      localStorage.removeItem(key)
    }
  }
}

const clearSessionStorage = () => {
  for (const key in sessionStorage) {
    if (!key.startsWith('oidc')) {
      sessionStorage.removeItem(key)
    }
  }
}

const clearEverything = () => {
  clearAllCookies()
  clearLocalStorage()
  clearSessionStorage()
}

const CookieWithdrawBlock = ({ content }: CookieWithdrawBlockProps) => {
  useEffect(() => {
    // If cookies aint enabled when component loads, clear all cookies to make sure none are still there
    if (getCookieValue(cookieName.clearCookies) === 'clear') {
      clearEverything()
    }
  }, [])

  const onClick = () => {
    clearEverything()
    document.cookie = `${cookieName.clearCookies}=clear; path=/`
    // Trigger reload so everything is erased
    window.location.reload()
  }

  return <Button onClick={onClick}>{content.buttonText}</Button>
}

export default CookieWithdrawBlock
