import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { GtmLogs, GtmErrorLog, GtmSuccessLog } from './types'

const initialState: GtmLogs = {
  errorLogs: [],
  successLogs: [],
}

const gtmLogsSlice = createSlice({
  name: 'gtmLogs',
  initialState,
  reducers: {
    setGtmErrorLogs: (state, action: PayloadAction<GtmErrorLog>): GtmLogs => ({
      ...state,
      errorLogs: [action.payload, ...state.errorLogs],
    }),
    setGtmSuccessLogs: (
      state,
      action: PayloadAction<GtmSuccessLog>
    ): GtmLogs => ({
      ...state,
      successLogs: [action.payload, ...state.successLogs],
    }),
  },
})

export default gtmLogsSlice
