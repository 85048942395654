import React from 'react'
import FunctionWithTabs from './FunctionWithTabs'
import FunctionWithoutTabs from './FunctionWithoutTabs'
import { FunctionPageProperties } from 'state/PublicWebApi/Content'

const Function = (props: FunctionPageProperties) =>
  props.hasTabNavigation ? (
    <FunctionWithTabs {...props} />
  ) : (
    <FunctionWithoutTabs {...props} />
  )

export default Function
