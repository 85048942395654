import { pullRequestShareUrlMatcher } from 'utils/urlTransition'
import userManager from './../userManager'
import { applicationInsights } from 'packages/app-shell-communication'

export enum UserType {
  'client',
  'customer',
}

export const medarbetarePathname = '/medarbetare'
export const tjanstepersonPathname = '/mitt-trr'
export const foretagPathname = '/trr-foretag'

const callbackUrl = '/callback'
export const medarbetareCallbackUrl = `${medarbetarePathname}${callbackUrl}`
const publicCallbackUrl = `${process.env.PUBLIC_URL}callback`

const callbackUrls = [callbackUrl, medarbetareCallbackUrl, publicCallbackUrl]

export const getCallbackUrls = (pathname: string): string[] => {
  return pullRequestShareUrlMatcher.test(pathname)
    ? callbackUrls.concat([
        `${pullRequestShareUrlMatcher.exec(pathname)[1]}/callback`,
        `${pullRequestShareUrlMatcher.exec(pathname)[1]}/medarbetare/callback`,
      ])
    : callbackUrls
}

export const getLoginUrl = (pathname: string, userType: UserType): string => {
  const loginPath = pathname.endsWith('/') ? pathname : `${pathname}/`

  const url = pullRequestShareUrlMatcher.test(loginPath)
    ? `${pullRequestShareUrlMatcher.exec(loginPath)[1]}/login`
    : '/login'
  // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
  return userType === UserType.customer ? `${url}/arbetsgivare` : url
}

export const removeRepeatedSlashesFromUrl = (url = '') => {
  try {
    const alldoublesRemoved = url.replace(/\/\/+/g, '/')
    return alldoublesRemoved.replace(':/', '://')
  } catch (error) {
    console.error(error)
    return url
  }
}

export const forceLogout = (): void => {
  const logoutFn = async () => {
    applicationInsights.__internalEvents.clearAuthenticatedUserContext()
    try {
      await userManager.clearStaleState()
      await userManager.signoutRedirect()
    } catch {
      location.assign('/')
    }
  }
  void logoutFn()
}
