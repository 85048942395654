import React, { useEffect } from 'react'
import { useTrackPage } from './Hooks/useTrackPage'
import { useTrackElement } from './Hooks/useTrackElement'
import { useTrackCustomEventListener } from './Hooks/useTrackCustomEventListener'
import TagManager from './TagManager'
import { useSliceStateSelector } from 'slices/hooks'
import { GtmLogTable } from './GtmLogTable'
import { getCookieValue } from 'utils/cookies'
import { useOverrideDocumentReferrer } from './Hooks/useOverrideDocumentReferrer'

export const GoogleTagManager = (): JSX.Element => {
  const isTrrCookiesApproved = useSliceStateSelector(
    (state) => state.slices.cookies.isTrrCookiesApproved
  )
  const hasLogCookie = Boolean(getCookieValue('appshell-gtm-log'))

  useOverrideDocumentReferrer()

  useEffect(() => {
    if (isTrrCookiesApproved) {
      TagManager.initialize()
    }
  }, [isTrrCookiesApproved])

  useTrackCustomEventListener()

  useTrackPage()

  useTrackElement()

  return hasLogCookie ? <GtmLogTable /> : <></>
}
