import { __api_internals__ } from './Event.names'

type customData = { key: string; value: string }[]

const eventNames = __api_internals__.eventNames

export interface customEventData {
  event_action: string
  event_item: string
  event_data: customData
  mfName?: string
  overrideGtmEnabledBlock?: boolean
}

export type gtmCustomEvent = ReturnType<typeof gtmCustomEvent>

const gtmCustomEvent = (e: customEventData): CustomEvent<customEventData> =>
  new CustomEvent(eventNames.gtmCustomEventName, {
    bubbles: true,
    detail: e,
  })

/**
 * Internal function - not to be used by any consumer other than appshell
 * Push a custom event to the GTM datalayer
 *
 * @param  {string}  e.event_action - The action of the event, e.g. ```click```.
 * @param  {string}   e.event_item - The item of the event, e.g. ```button```.
 * @param  {customData}   e.event_data - An array of key/value pairs, e.g ```
 * [{key: age, value: 30}, {key: height, value: 189}].```
 * @param  {string=}   mfName - The name of the triggering mf, e.g ```meetings```
 * @param  {boolean}   overrideGtmEnabledBlock - Override the block for sending events
 * to GTM if you do not have a gtm.enabled.MfName feature flag. Only to be used in app-shell.
 *
 */
export const internal_trackGtmCustomEvent = (e: customEventData): boolean =>
  dispatchEvent(gtmCustomEvent(e))
