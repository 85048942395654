import VideoPlayButton from 'apps/Site/components/VideoPlayer/VideoPlayButton'
import React, { useCallback, useState } from 'react'
import YouTube, { YouTubeProps, YouTubeEvent } from 'react-youtube'
import { Box, styled } from '@mui/material'

import FallbackImage from '../FallbackImage'

interface YouTubeEmbedProps {
  videoUrl: string
  setBlockDuration: (duration: number) => void
  fallbackUrl: string
  autoPlay?: boolean
}

export const extractVideoId = (videoUrl: string) => {
  if (!videoUrl?.includes('/')) {
    return null
  }
  const url = new URL(videoUrl)
  if (videoUrl.includes('embed')) {
    return url.pathname.split('/')[2]
  }
  if (videoUrl.includes('watch')) {
    return url.searchParams.get('v')
  }
  if (videoUrl.includes('youtu.be')) {
    return url.pathname.split('/')[1]
  }
}

const YoutubeContainer = styled(Box)(() => ({
  position: 'relative',
  width: '100%',
  height: '100%',
  paddingBottom: 'calc(100%/calc(16/9))',

  iframe: {
    border: 'none',
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
  },
}))

const YouTubeEmbed = ({
  videoUrl,
  setBlockDuration,
  fallbackUrl,
  autoPlay = false,
}: YouTubeEmbedProps) => {
  const videoId = extractVideoId(videoUrl)

  const [youTubePlayer, setYoutubePlayer] = useState(null)
  const [showFallBack, setShowFallBack] = useState(false)
  const [showPlayButton, setShowPlayButton] = useState(false)

  const onPlayerReady: YouTubeProps['onReady'] = useCallback(
    (event: YouTubeEvent) => {
      const newPlayer = event.target
      const videoData = newPlayer.getVideoData()
      if (videoData.video_id === videoId) {
        setYoutubePlayer(newPlayer)
        setShowPlayButton(true)
        setBlockDuration(newPlayer.getDuration() as number)
        document
          .getElementById('youtube-embed-iframe')
          ?.setAttribute('title', `Video: ${videoData.title as string}`)
      } else {
        setShowFallBack(true)
      }
    },
    [setBlockDuration, videoId]
  )

  const onPlay: YouTubeProps['onPlay'] = useCallback(() => {
    setShowPlayButton(false)
  }, [])

  const handlePlay = useCallback(() => {
    youTubePlayer.playVideo()
  }, [youTubePlayer])

  const youtubePlayerOptions = {
    host: 'https://www.youtube-nocookie.com',
    playerVars: {
      showInfo: 0,
      modestbranding: 1,
    },
  }

  const shouldShowFallback = showFallBack || !videoId || autoPlay

  return (
    <YoutubeContainer>
      {showPlayButton && <VideoPlayButton onClickAction={handlePlay} />}
      {shouldShowFallback ? (
        <FallbackImage imageUrl={fallbackUrl} />
      ) : (
        <YouTube
          id="youtube-embed-iframe"
          videoId={videoId}
          onReady={onPlayerReady}
          onPlay={onPlay}
          opts={youtubePlayerOptions}
        />
      )}
    </YoutubeContainer>
  )
}

export default YouTubeEmbed
