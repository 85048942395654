/* eslint-disable react/display-name */
import React, { ReactElement } from 'react'
import { domToReact, DOMNode } from 'html-react-parser'
import DOMPurify from 'dompurify'
import ReactDOMServer from 'react-dom/server'
import configService from 'utils/configService'
import { JmtLink } from 'apps/Site/components/JmtLink'
import FeaturesBlock from 'apps/Site/Epi/Blocks/FeaturesBlock'
import ArticleVideoBlock from 'apps/Site/Epi/Blocks/ArticleVideoBlock'
import {
  HTMLMapper as CommonHTMLMapper,
  ElementMap,
  ReplaceAttributes,
} from 'packages/html-mapper'

import { mergeBlockContent } from 'features/SystemLoaders/MicroFrontendLoader/utils'
import MicroFrontendLoader from 'features/SystemLoaders/MicroFrontendLoader/MicroFrontendLoader'

import { EpiBlock } from 'packages/internaltypes'
import { useAuthentication } from 'features/Authentication/Hooks'
import { EpiEditorialBlock } from 'state/PublicWebApi/Settings'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
} from '@mui/material'
import ExpandMore from '@mui/icons-material/ExpandMore'

interface DataAttributes {
  [key: string]: unknown
}

const allowedTags = [
  'expanding-content',
  'iframe',
  'accordion',
  'jmt',
  'jmtreport',
  'functionblock',
  'featuresblock',
  'articlevideoblock',
]

const getJmtComponent = (children: DOMNode[], download = false) => {
  const htmlChildren = getHtmlString(children)
  const linkText = DOMPurify.sanitize(htmlChildren, {
    FORBID_TAGS: ['p'],
    KEEP_CONTENT: true,
  })

  if (htmlChildren.length === 0) {
    return
  }

  return <JmtLink content={{ linkText }} download={download} />
}

const getHtmlString = (children: DOMNode[]) =>
  ReactDOMServer.renderToStaticMarkup(domToReact(children) as ReactElement)

const expandingContentMapping = ({
  children,
  parseHTML,
}: ReplaceAttributes) => {
  const headingMatch = /(<h[1-6]>(.+)<\/h[1-6]>)/gim
  const htmlChildren = getHtmlString(children)

  if (htmlChildren.length === 0) {
    return <></>
  }

  const title = headingMatch.exec(htmlChildren)

  if (!title) {
    return <></>
  }

  const content = htmlChildren.replace(headingMatch, '')
  const titleBasedId = `${title[2].replaceAll(' ', '')}`
  return (
    <Box>
      <Accordion sx={{ boxShadow: 'none' }}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls={titleBasedId + 'content'}
          id={titleBasedId + 'header'}
        >
          {title[2]}
        </AccordionSummary>
        <AccordionDetails>{parseHTML(content)}</AccordionDetails>
      </Accordion>
      <Divider />
    </Box>
  )
}

const elements: ElementMap = {
  /**
   * @deprecated Old template name in EPI, should be changed to use 'expanding-content'
   */
  accordion: expandingContentMapping,
  'expanding-content': expandingContentMapping,
  functionblock: ({ attribs }: ReplaceAttributes<DataAttributes>) => {
    const blockKey: React.Key = attribs['data-blockkey'] as React.Key
    const urlKey: string = attribs['data-urlkey'] as string
    const functionType: string = attribs['data-functiontype'] as string
    const block: EpiBlock = JSON.parse(attribs['data-content'] as string)
    const blockContent: EpiEditorialBlock = mergeBlockContent([block])

    return (
      <MicroFrontendLoader
        currentKey={urlKey}
        key={blockKey}
        functionType={functionType}
        editorBlockContent={blockContent}
      />
    )
  },
  jmt: ({ children }: ReplaceAttributes) => getJmtComponent(children),
  jmtreport: ({ children }: ReplaceAttributes) =>
    getJmtComponent(children, true),
  featuresblock: ({ attribs }: ReplaceAttributes<DataAttributes>) => {
    const { properties } = JSON.parse(attribs['data-content'] as string)
    return <FeaturesBlock content={{ ...properties }} />
  },

  articlevideoblock: ({ attribs }: ReplaceAttributes<DataAttributes>) => {
    const properties = JSON.parse(attribs?.['data-content'] as string)
    return <ArticleVideoBlock {...properties} />
  },
}

type wrapperTypes = 'span' | 'section'

interface IHTMLMapperProps {
  body: string
  replaceValues?: { [key: string]: string }
  wrapper?: wrapperTypes
  className?: string
}

const HTMLMapper = ({
  body,
  replaceValues = {},
  wrapper,
  className,
}: IHTMLMapperProps) => {
  const { isLoggedIn } = useAuthentication()
  return (
    <CommonHTMLMapper
      body={body}
      replaceValues={replaceValues}
      wrapper={wrapper}
      className={className}
      mediaUrl={configService.config.MEDIA_URL}
      isLoggedIn={isLoggedIn}
      allowedTags={allowedTags}
      elements={elements}
    />
  )
}

export default HTMLMapper
