import React from 'react'
import { ScrollWrapper } from 'apps/Site/Epi/ScrollWrapper'
import { IContentBlock } from 'apps/Site/Epi/BlockMapper/BlockMapper'
import { ArticleListItem } from 'apps/Site/components/ArticleList/ArticleListItem'
import ArticleList from 'apps/Site/components/ArticleList'
import { Box, styled, Typography } from '@mui/material'

interface NewsListItem extends IContentBlock {
  startPublish: string
  url: string
  introduction: { text: string }
}

export interface NewsListProps {
  heading: string
  text?: string
  news?: NewsListItem[]
}

const Introduction = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  marginBottom: theme.spacing(7),
  maxWidth: '752px',
}))

const NewsList = ({ heading, text, news }: NewsListProps) => {
  return (
    <ScrollWrapper>
      <Introduction>
        <Typography variant="h1">{heading}</Typography>
        {text && <Typography variant="preamble">{text}</Typography>}
      </Introduction>
      {news?.length > 0 && (
        <ArticleList>
          {news.map(
            ({
              startPublish,
              url,
              properties: { customCategory, puffView, introduction },
            }) => (
              <ArticleListItem
                key={`arctile-item-${url}`}
                date={startPublish}
                url={url}
                heading={puffView?.heading || introduction?.heading}
                body={
                  puffView?.longIntro ||
                  puffView?.mainIntro ||
                  introduction?.text
                }
                image={puffView?.image}
                category={customCategory}
              />
            )
          )}
        </ArticleList>
      )}
    </ScrollWrapper>
  )
}

export default NewsList
