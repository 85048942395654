const eventNames = {
  internals: {
    login: 'app-shell:gotoLoginClicked',
    logout: 'app-shell:gotoLogoutClicked',
    abortLogin: 'app-shell:abortLoginClicked',
    refreshUserProfile: 'app-shell:refreshUserProfile',
    toggleArbetsgivareCookie: 'app-shell:toggleArbetsgivareCookie',
    redirectToAuthServer: 'app-shell:redirectToAuthServer',
    userCreationFinished: 'app-shell:userCreationFinished',
    gtmCustomEventName: 'app-shell:gtmCustomEvent',
    applicationInsightsEventName: 'app-shell:applicationInsightsEvent',
  },
}

export const __api_internals__ = {
  eventNames: eventNames.internals,
}
