import { isNil } from 'ramda'
import React, { useEffect, useState } from 'react'

import fetchPossibleJmtResources from './fetchPossibleJmtLink'
import { JmtStatus } from './jmtStatus'
import getJmtStatus from './getJmtStatus'
import { Box, Link, Typography } from '@mui/material'
import OpenInNew from '@mui/icons-material/OpenInNew'
import ComplementingReportSection from './ComplementingReportSection'

interface IJmtLink {
  linkText: string
}

interface IJmtLinkProps {
  content: IJmtLink
  download?: boolean
}

export const JmtLink = ({
  content: { linkText },
  download = false,
}: IJmtLinkProps) => {
  const [jmtStatus, setJmtStatus] = useState<JmtStatus | undefined>(undefined)
  const [jmtLink, setJmtLink] = useState<string | undefined>(undefined)

  useEffect(() => {
    void getJmtStatus(setJmtStatus)
  }, [])

  useEffect(() => {
    if (!jmtLink) {
      void fetchPossibleJmtResources(setJmtLink)
    }
  }, [download, jmtLink, jmtStatus])

  if (isNil(linkText)) {
    return null
  }
  if (!jmtLink) return null
  if (jmtStatus === JmtStatus.Completed)
    return (
      <Box>
        <>
          <Typography gutterBottom variant="h6">
            Mitt resultat
          </Typography>

          <Link
            component={'a'}
            target="_blank"
            href={jmtLink}
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 2,
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            <OpenInNew sx={{ mr: 1 }} /> Se ditt resultat
          </Link>
        </>
        <ComplementingReportSection />
      </Box>
    )

  return (
    <Box>
      <Link
        component={'a'}
        target="_blank"
        href={jmtLink}
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 2,
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
      >
        <OpenInNew sx={{ mr: 1 }} /> {linkText}
      </Link>
    </Box>
  )
}
