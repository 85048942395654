import { IConfiguration, IPersistedConfigration } from './types'

declare global {
  interface Window {
    APP_SHELL_CONFIGURATION?: IConfiguration
  }
}

class ConfigService {
  private configuration: IConfiguration
  private persistedConfiguration: IPersistedConfigration = {
    ADVISOR_SESSION_KEY: 'advisorSession',
  }

  public constructor() {
    const runtimeConfiguration = window.APP_SHELL_CONFIGURATION

    const compiletimeConfiguration: IConfiguration = {
      API_URL: process.env.API_URL,
      AUTHORITY_URL: process.env.AUTHORITY_URL,
      AI_CONNECTION_STRING: process.env.AI_CONNECTION_STRING,
      PUBLIC_URL: process.env.PUBLIC_URL,
      URL_REWRITE: process.env.URL_REWRITE,
      MEDIA_URL: process.env.MEDIA_URL,
      API_GATEWAY: process.env.API_GATEWAY,
      GOOGLE_TAG_MANAGER_ID: process.env.GOOGLE_TAG_MANAGER_ID,
      GOOGLE_TAG_MANAGER_AUTH: process.env.GOOGLE_TAG_MANAGER_AUTH,
      GOOGLE_TAG_MANAGER_PREVIEW: process.env.GOOGLE_TAG_MANAGER_PREVIEW,
    }

    this.configuration = runtimeConfiguration?.API_URL
      ? runtimeConfiguration
      : compiletimeConfiguration
  }

  public get config(): IConfiguration & IPersistedConfigration {
    return {
      ...this.configuration,
      ...this.persistedConfiguration,
    }
  }

  public set setCompiletimeConfiguration(
    configuration: Partial<IConfiguration>
  ) {
    this.configuration = {
      ...this.configuration,
      ...configuration,
    }
  }
}

export default new ConfigService()
