import React from 'react'
import FeaturePuff from './FeaturePuff'
import { Typography, Box, styled } from '@mui/material'
import { AppshellLink } from '@internal-helpers'

export interface FeaturesBlockProps {
  content: {
    heading?: string
    isNumberedList?: boolean
    link?: {
      text: string
      url: string
    }
    featurePuffs: {
      id: number
      properties: {
        iconName?: string
        heading: string
        body: string
      }
    }[]
  }
}

interface RowItemProp {
  variant: 'double' | 'triple'
}

const StyledRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(4),
}))

const StyledRowItem = styled(Box)<RowItemProp>(({ theme, variant }) => ({
  width: '100%',
  wordBreak: 'break-word',
  ...(variant === 'triple' && {
    [theme.breakpoints.up('sm')]: {
      width: `calc(50% - ${theme.spacing(2)})`,
    },
    [theme.breakpoints.up('md')]: {
      width: `calc(33% - ${theme.spacing(2.4)})`,
    },
  }),
  ...(variant === 'double' && {
    [theme.breakpoints.up('sm')]: {
      flexBasis: `calc(50% - ${theme.spacing(2)})`,
    },
  }),
}))

const FeaturesBlock = ({
  content: { heading = undefined, isNumberedList = false, featurePuffs, link },
}: FeaturesBlockProps) => {
  const numberInRow = featurePuffs?.length % 3 === 0 ? 'triple' : 'double'
  return (
    <section>
      {heading && (
        <Typography variant="h3" component="h2" mb={4}>
          {heading}
        </Typography>
      )}
      {featurePuffs?.length > 0 && (
        <StyledRow data-testid="feature-block">
          {featurePuffs.map(({ id, properties }, index) => (
            <StyledRowItem key={`feature-puff-${id}`} variant={numberInRow}>
              <FeaturePuff
                index={index + 1}
                heading={properties.heading}
                body={properties.body}
                iconName={properties.iconName}
                variant={isNumberedList ? 'enumerated' : 'icon'}
              />
            </StyledRowItem>
          ))}
        </StyledRow>
      )}
      <AppshellLink url={link?.url} text={link?.text} mt={5} />
    </section>
  )
}

export default FeaturesBlock
