import React from 'react'
import { AppshellLink } from 'packages/internal-helpers'

import { BulletListItemProperties } from '../BulletList'

import {
  Heading,
  Body,
  LinkWrapper,
  TextWrapper,
} from './BulletListPoints.styles'

export const Simple = ({
  heading,
  body,
  link,
}: BulletListItemProperties): JSX.Element => (
  <TextWrapper>
    <Heading component="span">{heading}</Heading>
    <Body component="span">{body}</Body>
    {link?.text && link?.url && (
      <LinkWrapper>
        <AppshellLink url={link.url} text={link.text} />
      </LinkWrapper>
    )}
  </TextWrapper>
)
