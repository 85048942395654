import { Box, IconButton, Popover, Typography } from '@mui/material'
import React, { useEffect, useState, useRef } from 'react'
import History from '@mui/icons-material/History'

interface VersionStrings {
  appshellVersion: string | undefined
  mfName: string | undefined
  mfVersion: string | undefined
}

const createVersionInformationString = (versionStrings: VersionStrings) => {
  const appshellVersionString = versionStrings.appshellVersion
    ? `public-web/${versionStrings.appshellVersion}`
    : undefined

  const mfVersionString =
    versionStrings.mfName && versionStrings.mfVersion
      ? `${versionStrings.mfName}/${versionStrings.mfVersion}`
      : undefined

  return [appshellVersionString, mfVersionString].filter(Boolean).join(' och ')
}

const BannerHeading = ({
  appshellVersion,
  mfName,
  mfVersion,
}: VersionStrings) => {
  const buttonRef = useRef<HTMLButtonElement>(null)

  const versionInformationString = createVersionInformationString({
    appshellVersion,
    mfName,
    mfVersion,
  })

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(
    buttonRef.current
  )

  const handlePopoverOpen = () => {
    setAnchorEl(buttonRef.current)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const togglePopover = () => {
    if (anchorEl) {
      handlePopoverClose()
    } else {
      handlePopoverOpen()
    }
  }

  useEffect(() => {
    handlePopoverOpen()
    setTimeout(() => {
      handlePopoverClose()
    }, 3000)
  }, [])

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        color="white"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <IconButton
          sx={{ color: 'white', marginRight: 1 }}
          onClick={togglePopover}
          data-testid="close-button"
          ref={buttonRef}
        >
          <History
            sx={{
              animation: 'spin 5s linear infinite',
              '@keyframes spin': {
                '0%': {
                  transform: 'rotate(360deg)',
                },
                '100%': {
                  transform: 'rotate(0deg)',
                },
              },
            }}
          />
        </IconButton>
        <Typography>TRRs Tidsmaskin</Typography>
      </Box>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableAutoFocus
        disableEnforceFocus
      >
        <Typography sx={{ p: 1 }}>
          Du använder följande:&nbsp;{versionInformationString}
        </Typography>
      </Popover>
    </>
  )
}

export default BannerHeading
