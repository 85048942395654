import React from 'react'

import { AppshellLink } from 'packages/internal-helpers'

import { BulletListItemProperties } from '../BulletList'

import {
  Heading,
  BulletPointMargin,
  Body,
  LinkWrapper,
  TextWrapper,
} from './BulletListPoints.styles'

export const BulletPoint = ({
  heading,
  body,
  link,
}: BulletListItemProperties): JSX.Element => {
  return (
    <TextWrapper>
      <Heading component="span">
        <BulletPointMargin>{heading}</BulletPointMargin>
      </Heading>

      <Body component="span">
        <BulletPointMargin>{body}</BulletPointMargin>
      </Body>
      {link?.text && link?.url && (
        <LinkWrapper>
          <BulletPointMargin>
            <AppshellLink url={link.url} text={link.text} />
          </BulletPointMargin>
        </LinkWrapper>
      )}
    </TextWrapper>
  )
}
