import InformationBox from 'apps/Site/components/InformationBox'
import { ExpandableList } from '@internal-helpers'
import React from 'react'
import { ExpandableListItem } from 'packages/internaltypes'
import configService from 'utils/configService'

export interface IFAQBlock {
  content: {
    heading: string
    preamble?: string
    link?: {
      text: string
      url: string
    }
    listHeading: string
    listItems: { properties: ExpandableListItem }[]
    color?: string
    useColor?: boolean
  }
}

const FAQBlock = ({
  content: { heading, preamble, link, listHeading, listItems, color, useColor },
}: IFAQBlock) => (
  <InformationBox
    heading={heading}
    body={preamble}
    link={link}
    color={color}
    useColor={useColor}
  >
    <ExpandableList
      mediaUrl={configService.config.MEDIA_URL}
      heading={listHeading}
      listItems={listItems}
      variant="single"
    />
  </InformationBox>
)

export default FAQBlock
