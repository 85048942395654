import React, { useEffect, useCallback, useState } from 'react'
import { Link, NavLink, useHistory } from 'react-router-dom'
import { useAuthentication } from 'features/Authentication/Hooks'
import { goToLogin } from 'packages/app-shell-communication'
import FocusTrap from 'focus-trap-react'
import { useLockScroll } from 'apps/Site/hooks'
import {
  DropdownMenu,
  DropdownMenuToggle,
  DropdownListItem,
  DropdownMenuListSection,
} from 'apps/Site/components/DropdownMenu'
import classNames from 'classnames'
import MenuButton from 'apps/Site/components/MenuButton'
import { SecondaryNavigation } from 'apps/Site/SecondaryNavigation'
import { useMediaQuery, useTheme, Typography } from '@mui/material'
import Menu from '@mui/icons-material/Menu'
import Close from '@mui/icons-material/Close'

import LeftMenu from './LeftMenu'
import HeaderButton from './HeaderButton'
import s from './Header.module.scss'
import RightMenu from './RightMenu'
import Logo from 'apps/Site/components/Logo'
import { useCachedSettingsState } from 'state/PublicWebApi'
import {
  HeaderContainer,
  HeaderContent,
  HeaderSegmentLeft,
  HeaderSegmentRight,
  MenuButton as LeftMenuButton,
} from './Header.styles'
import { useSliceDispatch, useSliceStateSelector } from 'slices/hooks'
import { smallDeviceMenuStatus } from 'slices/Menus/types'
import { addPRPrefix } from 'utils/urlTransition'

const Header = () => {
  const { logout, isLoggedIn } = useAuthentication()
  const { enableScroll, disableScroll } = useLockScroll()
  const [isTabletMainNavigationVisible, setIsTabletMainNavigationVisible] =
    useState(false)
  const history = useHistory()

  const trigger = useSliceDispatch()

  const { smallDeviceMenuStatus } = useSliceStateSelector(
    (state) => state.slices.menus
  )
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'))
  const settings = useCachedSettingsState()
  const trrBusinessButton = settings.trrBusinessNavigation?.button
  const myTrrButton = settings.myTrrNavigation?.button
  const searchUrl = settings.userInterfaceLinks?.['searchPagePathname']?.url

  const avatarButton = myTrrButton || trrBusinessButton

  const { primaryNavigation, secondaryNavigation, useOldExperience } = settings

  const setMenuHelper = useCallback(
    (type: smallDeviceMenuStatus) => {
      type === smallDeviceMenuStatus || type === 'none'
        ? trigger('setSmallDeviceMenuStatus', 'none')
        : trigger('setSmallDeviceMenuStatus', type)
    },
    [smallDeviceMenuStatus, trigger]
  )

  const loginAction = useCallback(() => {
    goToLogin()
  }, [])

  const loggedInAction = useCallback(() => {
    if (isMobile || isTablet) {
      setMenuHelper('right')
    } else {
      history.push(avatarButton?.url)
    }
  }, [isMobile, isTablet, setMenuHelper, history, avatarButton?.url])

  const navigateToSearch = useCallback(() => {
    setMenuHelper('none')
    searchUrl && history.push(searchUrl)
  }, [history, searchUrl, setMenuHelper])

  useEffect(() => {
    setIsTabletMainNavigationVisible(false)
    trigger('setSmallDeviceMenuStatus', 'none')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTablet, isMobile])

  useEffect(() => {
    smallDeviceMenuStatus === 'none' ? enableScroll() : disableScroll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smallDeviceMenuStatus])

  return (
    <header>
      <HeaderContainer>
        <HeaderContent>
          <HeaderSegmentLeft>
            {isMobile && (
              <FocusTrap
                active={smallDeviceMenuStatus === 'left'}
                focusTrapOptions={{
                  initialFocus: false,
                  allowOutsideClick: true,
                }}
              >
                <div>
                  <LeftMenuButton
                    onClick={() => setMenuHelper('left')}
                    disableRipple
                    aria-haspopup
                    aria-expanded={smallDeviceMenuStatus === 'left'}
                    data-testid="header-menu-button"
                  >
                    {smallDeviceMenuStatus === 'left' ? (
                      <Close titleAccess="Stäng meny" fontSize="medium" />
                    ) : (
                      <Menu titleAccess="Öppna meny" fontSize="medium" />
                    )}
                  </LeftMenuButton>
                  <LeftMenu
                    testSelector="header-left-menu"
                    navigationLinks={primaryNavigation}
                  />
                </div>
              </FocusTrap>
            )}
            {isTablet && (
              <DropdownMenu
                dropdownListCustomClass={s.DropdownList}
                testSelector="header-dropdown-list"
                showDropdown={isTabletMainNavigationVisible}
                toggleDropdown={(status) =>
                  setIsTabletMainNavigationVisible(status)
                }
              >
                {{
                  action: (
                    <DropdownMenuToggle
                      testSelector="header-dropdown-toggle"
                      className={classNames(s.MenuButton, s.TabletMenuButton)}
                      secondaryAction={() => setMenuHelper('none')}
                    >
                      {isTabletMainNavigationVisible ? (
                        <Close titleAccess="Stäng meny" fontSize="medium" />
                      ) : (
                        <Menu titleAccess="Öppna meny" fontSize="medium" />
                      )}
                    </DropdownMenuToggle>
                  ),
                  dropdownList: (
                    <DropdownMenuListSection>
                      {primaryNavigation?.map((link, index) => (
                        <DropdownListItem
                          key={`dropdown-navigation-link-${index.toString()}`}
                        >
                          <MenuButton
                            url={link.url}
                            onClickAction={() =>
                              setIsTabletMainNavigationVisible(false)
                            }
                            size="large"
                          >
                            <Typography variant="subtitle1" component="p">
                              {link.text}
                            </Typography>
                          </MenuButton>
                        </DropdownListItem>
                      ))}
                    </DropdownMenuListSection>
                  ),
                }}
              </DropdownMenu>
            )}
            <Link
              className={s.Logo}
              to={addPRPrefix('/')}
              onClick={() => setMenuHelper('none')}
              aria-label="Startsida TRR.se"
            >
              <Logo />
            </Link>
            {!isMobile && !isTablet && (
              <ul className={s.LinkList} data-testid="desktop-navigation-links">
                {primaryNavigation?.map((link, index) => (
                  <li key={`primary-navigation-link-${index}`}>
                    <NavLink
                      to={link.url}
                      className={s.Link}
                      activeClassName={s.ActiveLink}
                    >
                      {link.text}
                    </NavLink>
                  </li>
                ))}
              </ul>
            )}
          </HeaderSegmentLeft>
          <HeaderSegmentRight>
            <HeaderButton
              text="Sök"
              type="search"
              action={navigateToSearch}
              isMobile={isMobile}
            />
            <FocusTrap
              active={smallDeviceMenuStatus === 'right'}
              focusTrapOptions={{
                initialFocus: false,
                allowOutsideClick: true,
              }}
            >
              <div>
                {isLoggedIn && useOldExperience && (
                  <SecondaryNavigation
                    routes={secondaryNavigation}
                    logout={logout}
                    secondaryToggleAction={() => setMenuHelper('none')}
                  />
                )}
                {isLoggedIn && !useOldExperience && (
                  <>
                    <HeaderButton
                      text={avatarButton?.text}
                      type="avatar"
                      action={loggedInAction}
                      isLoggedIn={isLoggedIn}
                      isMobile={isMobile}
                      isTablet={isTablet}
                      isSideMenuOpen={smallDeviceMenuStatus === 'right'}
                    />
                    {(isMobile || isTablet) && (
                      <RightMenu testSelector="header-right-menu" />
                    )}
                  </>
                )}
                {!isLoggedIn && (
                  <HeaderButton
                    text={'Logga in'}
                    type="avatar"
                    action={loginAction}
                    isLoggedIn={false}
                    isMobile={isMobile}
                    isTablet={isTablet}
                    isSideMenuOpen={smallDeviceMenuStatus === 'right'}
                  />
                )}
              </div>
            </FocusTrap>
          </HeaderSegmentRight>
        </HeaderContent>
      </HeaderContainer>
    </header>
  )
}

export default Header
