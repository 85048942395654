import { palette } from './tokens.json'

const {
  primary,
  secondary,
  accent,
  neutralColors: neutral,
  stateColors: state,
  errorColors: error,
  warningColors: warning,
  infoColors: info,
  successColors: success,
  surfaceColors: surface,
  textColors: text,
} = palette

export interface AccentColors {
  dark?: typeof accentColors.dark
  main?: typeof accentColors.main
  light?: typeof accentColors.light
}

export interface NeutralColors {
  main?: typeof neutralColors.main
  light?: typeof neutralColors.light
  disabled?: typeof neutralColors.disabled
  divider?: typeof neutralColors.divider
}

export interface SurfaceColors {
  purple?: typeof surfaceColors.purple
  green?: typeof surfaceColors.green
  yellow?: typeof surfaceColors.yellow
  pink?: typeof surfaceColors.pink
  orange?: typeof surfaceColors.orange
  blue?: typeof surfaceColors.blue
  grey?: typeof surfaceColors.grey
}

export const primaryColors = {
  dark: primary.dark.value,
  main: primary.main.value,
  light: primary.light.value,
} as const

export const secondaryColors = {
  dark: secondary.dark.value,
  main: secondary.main.value,
  light: secondary.light.value,
} as const

export const accentColors = {
  dark: accent.dark.value,
  main: accent.main.value,
  light: accent.light.value,
} as const

export const neutralColors = {
  main: neutral.main.value,
  light: neutral.light.value,
  disabled: neutral.disabled.value,
  divider: neutral.divider.value,
} as const

export const stateColors = {
  outlinedChipHover: state.outlinedChipHover.value,
  filledChipHover: state.filledChipHover.value,
  outlinedButtonHover: state.outlinedButtonHover.value,
  tableRowSelected: state.tableRowSelected.value,
  tableRowHover: state.tableRowHover.value,
  tabSelected: state.tabSelected.value,
  tabHover: state.outlinedButtonHover.value,
} as const

export const errorColors = {
  dark: error.dark.value,
  main: error.main.value,
  light: error.light.value,
} as const

export const warningColors = {
  dark: warning.dark.value,
  main: warning.main.value,
  light: warning.light.value,
} as const

export const infoColors = {
  dark: info.dark.value,
  main: info.main.value,
  light: info.light.value,
} as const

export const successColors = {
  dark: success.dark.value,
  main: success.main.value,
  light: success.light.value,
} as const

export const surfaceColors = {
  purple: surface.purple.value,
  green: surface.green.value,
  yellow: surface.yellow.value,
  pink: surface.pink.value,
  orange: surface.orange.value,
  blue: surface.blue.value,
  grey: surface.grey.value,
} as const

export const textColors = {
  primary: text.primary.value,
  secondary: text.secondary.value,
  disabled: text.disabled.value,
} as const
