import VimeoEmbed from 'apps/Site/components/VideoPlayer/VimeoEmbed'
import YouTubeEmbed from 'apps/Site/components/VideoPlayer/YoutubeEmbed'
import React from 'react'
import { Box } from '@mui/material'

import FallbackImage from './FallbackImage'

const getUrlType = (url: string) => {
  let videoUrl
  try {
    videoUrl = new URL(url)
  } catch (e) {
    return 'unknown'
  }

  if (videoUrl?.hostname.includes('vimeo')) {
    return 'vimeo'
  } else if (
    videoUrl?.hostname.includes('youtube') ||
    videoUrl?.hostname.includes('youtu.be')
  ) {
    return 'youtube'
  }
  return 'unknown'
}

interface VideoPlayerProps {
  url: string
  fallbackUrl: string
  setBlockDuration?: (duration: number) => void
  autoPlay?: boolean
}

const getEmbededPlayer = ({
  url,
  fallbackUrl,
  setBlockDuration,
  autoPlay,
}: VideoPlayerProps) => {
  switch (getUrlType(url)) {
    case 'vimeo':
      return (
        <VimeoEmbed
          videoUrl={url}
          setBlockDuration={setBlockDuration}
          fallbackUrl={fallbackUrl}
          autoPlay={autoPlay}
        />
      )
    case 'youtube':
      return (
        <YouTubeEmbed
          videoUrl={url}
          setBlockDuration={setBlockDuration}
          fallbackUrl={fallbackUrl}
          autoPlay={autoPlay}
        />
      )
    case 'unknown':
    default:
      return (
        <Box
          position="relative"
          width="100%"
          height="100%"
          paddingBottom="calc(100%/calc(16/9))"
        >
          <FallbackImage imageUrl={fallbackUrl} />
        </Box>
      )
  }
}

const VideoPlayer = ({
  url,
  fallbackUrl,
  setBlockDuration,
  autoPlay,
}: VideoPlayerProps) => {
  return getEmbededPlayer({ url, fallbackUrl, setBlockDuration, autoPlay })
}

export default VideoPlayer
