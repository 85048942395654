/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { ReactElement } from 'react'
import { domToReact, DOMNode } from 'html-react-parser'
import DOMPurify from 'dompurify'
import ReactDOMServer from 'react-dom/server'
import {
  isLinkExternal,
  getImageQuery,
  AppshellButton,
} from 'packages/internal-helpers'

import {
  ExpandableListBlock,
  ExpandableListBlockContent,
  LazyLoadWrapper,
  TestimonialBlock,
  TestimonialBlockContent,
  LoginBlock,
} from '@internal-helpers'

import {
  BulletList,
  BulletListContent,
  OrderedList,
  UnorderedList,
  FileDownload,
} from '../components'
import { isLinkFile } from '../helpers'
import { ElementMap, ReplaceAttributes } from '../types'
import { noCookie } from '../helpers/linkHelpers'
import { EpiBlock } from 'packages/internaltypes'
import { Box, Divider, Link, Typography } from '@mui/material'
import ChevronRight from '@mui/icons-material/ChevronRight'
import OpenInNew from '@mui/icons-material/OpenInNew'

interface DataAttributes {
  [key: string]: unknown
}

interface LinkAttributes {
  href?: string
  title?: string
  target?: string
}

interface ImageAttributes {
  alt: string
  src: string
  height: string
  width: string
  class: string
}

interface VideoAttributes {
  src: string
}

const getHtmlString = (children: DOMNode[]) =>
  ReactDOMServer.renderToStaticMarkup(domToReact(children) as ReactElement)

const baseElementMap: ElementMap = {
  p: ({ renderChildren }) => (
    <Typography variant="body1" gutterBottom>
      {renderChildren()}
    </Typography>
  ),
  a: ({
    children,
    renderChildren,
    attribs: { href, title, target = undefined },
    mediaUrl,
  }: ReplaceAttributes<LinkAttributes>) => {
    const link = href ?? ''
    const ariaLabel = isLinkExternal(link)
      ? `${title} (öppnas i ny flik)`
      : title

    return isLinkFile(link) ? (
      <FileDownload
        relLink={link}
        label={children[0].data}
        title={title}
        mediaUrl={mediaUrl}
      />
    ) : (
      <Link
        title={title}
        href={link}
        target={target}
        rel={target === '_blank' ? 'noreferrer' : undefined}
        underline="hover"
        {...(title && { 'aria-label': ariaLabel })}
      >
        {(isLinkExternal(link) || target === '_blank') && (
          <OpenInNew
            fontSize={'inherit'}
            sx={{ mr: 1, position: 'relative', top: '0.15em' }}
          />
        )}
        {renderChildren()}
        {!isLinkExternal(link) && (
          <ChevronRight
            fontSize={'inherit'}
            sx={{ position: 'relative', top: '0.2em' }}
          />
        )}
      </Link>
    )
  },
  h1: ({ attribs, renderChildren }) => (
    <Typography variant="h1" gutterBottom {...attribs}>
      {renderChildren()}
    </Typography>
  ),
  h2: ({ attribs, renderChildren }) => (
    <Typography variant="h2" gutterBottom {...attribs}>
      {renderChildren()}
    </Typography>
  ),
  h3: ({ attribs, renderChildren }) => (
    <Typography variant="h3" gutterBottom {...attribs}>
      {renderChildren()}
    </Typography>
  ),
  h4: ({ attribs, renderChildren }: ReplaceAttributes) => (
    <Typography variant="h4" gutterBottom {...attribs}>
      {renderChildren()}
    </Typography>
  ),
  h5: ({ attribs, renderChildren }) => (
    <Typography variant="h5" gutterBottom {...attribs}>
      {renderChildren()}
    </Typography>
  ),
  ul: ({ renderChildren }) => <UnorderedList>{renderChildren()}</UnorderedList>,
  ol: ({ renderChildren }) => <OrderedList>{renderChildren()}</OrderedList>,
  li: ({ renderChildren }) => (
    <Typography gutterBottom={false} component="li" variant="body1">
      {renderChildren()}
    </Typography>
  ),
  hr: ({ attribs }) => <Divider sx={{ my: 5 }} {...attribs} />,
  img: ({
    attribs: {
      src,
      alt,
      width = '100%',
      height = '100%',
      class: customClass = '',
    },
    mediaUrl,
  }: ReplaceAttributes<ImageAttributes>) => (
    <LazyLoadWrapper component={'span'}>
      <span
        style={{
          height: 'auto',
          maxWidth: '100%',
          marginBottom: '24px',
          display: 'block',
          ...(!width.includes('%')
            ? { maxWidth: Math.min(parseInt(width, 10), 752) }
            : null),
        }}
        className={customClass}
      >
        <img
          style={{
            objectFit: 'cover',
            borderRadius: '4px',
            height: height,
            width: width,
            maxWidth: '100%',
          }}
          src={`${mediaUrl}${getImageQuery(
            src,
            !width.includes('%') ? Math.min(parseInt(width, 10), 752) : 752
          )}`}
          alt={alt}
        />
      </span>
    </LazyLoadWrapper>
  ),
  iframe: ({ attribs: { src } }: ReplaceAttributes<VideoAttributes>) => (
    <LazyLoadWrapper component="span">
      <Box
        sx={{
          position: 'relative',
          display: 'block',
          paddingBottom: '56.25%',
          /* 16:9 */
          height: 0,
          marginBottom: 1,
          overflow: 'hidden',
          iframe: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
          },
        }}
      >
        <iframe
          src={noCookie(src)}
          frameBorder="0"
          allow="encrypted-media"
          allowFullScreen
          title="video"
        />
      </Box>
    </LazyLoadWrapper>
  ),
  bulletlistblock: ({ attribs }: ReplaceAttributes<DataAttributes>) => {
    const content = JSON.parse(attribs['data-content'] as string) as {
      properties: BulletListContent
    }

    return <BulletList content={{ ...content.properties }} />
  },
  testimonialblock: ({
    attribs,
    mediaUrl,
  }: ReplaceAttributes<DataAttributes>) => {
    const content = JSON.parse(attribs['data-content'] as string) as {
      properties: TestimonialBlockContent
    }
    return <TestimonialBlock content={{ ...content.properties, mediaUrl }} />
  },
  expandablelistblock: ({
    attribs,
    mediaUrl,
  }: ReplaceAttributes<DataAttributes>) => {
    const properties = JSON.parse(
      attribs['data-content'] as string
    ) as ExpandableListBlockContent

    return (
      <ExpandableListBlock mediaUrl={mediaUrl} content={{ ...properties }} />
    )
  },
  loginblock: ({ attribs, isLoggedIn }: ReplaceAttributes<DataAttributes>) => {
    const loginContent: EpiBlock = JSON.parse(
      attribs['data-content'] as string
    ) as EpiBlock
    const loginDescription: string = loginContent.properties
      .description as string

    return (
      <LoginBlock content={{ description: loginDescription, isLoggedIn }} />
    )
  },

  factbox: ({
    children,
    attribs,
    renderChildren,
  }: ReplaceAttributes<DataAttributes>) => {
    const niceToKnow: React.Key = attribs['class'] as React.Key
    return (
      <Box
        component={'article'}
        sx={{
          padding: { xs: 3, lg: 5 },
          mb: 5,
          backgroundColor: niceToKnow ? 'surface.purple' : 'surface.orange',
          borderRadius: 1,
          '> *:last-child': {
            mb: '0 !important',
          },
        }}
      >
        {renderChildren(children)}
      </Box>
    )
  },

  calltoaction: ({ children, attribs }: ReplaceAttributes<DataAttributes>) => {
    const secondary: React.Key = attribs['class'] as React.Key

    const childrenAsHtmlString = getHtmlString(children)
    const linkElement = DOMPurify.sanitize(childrenAsHtmlString, {
      FORBID_TAGS: ['p'],
      KEEP_CONTENT: true,
      RETURN_DOM: true,
    }).querySelector('a')

    if (!linkElement) return <></>

    return (
      <AppshellButton
        variant={secondary ? 'outlined' : 'contained'}
        url={linkElement.getAttribute('href')}
        text={linkElement.textContent}
        dataTestId="ctaButton"
      />
    )
  },
}

export default baseElementMap
