import {
  ICustomProperties,
  IDependencyTelemetry,
  IEventTelemetry,
  IExceptionTelemetry,
} from '@microsoft/applicationinsights-web'
import { __internal__dispatchApplicationInsightsEvent } from './Events'

export const AI_VERSION = 3

export interface IApplicationInsightsEventPayload {
  eventTelemetry: IEventTelemetry
  customProperties?: ICustomProperties
}

export interface IApplicationInsightsExceptionPayload {
  exceptionTelemetry: IExceptionTelemetry
}

export interface IApplicationInsightsDependencyDataPayload {
  dependencyTelemetry: IDependencyTelemetry
}

export const AI_ACTIONS = {
  trackEvent: 'trackEvent',
  trackException: 'trackException',
  trackDependencyData: 'trackDependencyData',
  __internal: {
    clearAuthenticatedUserContext: '__internal__clearAuthenticatedUserContext',
  },
}

export const trackEvent = (
  eventTelemetry: IEventTelemetry,
  customProperties?: ICustomProperties
): void => {
  __internal__dispatchApplicationInsightsEvent<IApplicationInsightsEventPayload>(
    {
      aiVersion: AI_VERSION,
      azureReleaseId: process.env.BUILD_BUILDID,
      action: AI_ACTIONS.trackEvent,
      payload: {
        eventTelemetry,
        customProperties,
      },
    }
  )
}

export const trackException = (
  exceptionTelemetry: IExceptionTelemetry
): void => {
  __internal__dispatchApplicationInsightsEvent<IApplicationInsightsExceptionPayload>(
    {
      aiVersion: AI_VERSION,
      azureReleaseId: process.env.BUILD_BUILDID,
      action: AI_ACTIONS.trackException,
      payload: { exceptionTelemetry },
    }
  )
}

export const trackDependencyData = (
  dependencyTelemetry: IDependencyTelemetry
): void => {
  __internal__dispatchApplicationInsightsEvent<IApplicationInsightsDependencyDataPayload>(
    {
      aiVersion: AI_VERSION,
      action: AI_ACTIONS.trackDependencyData,
      payload: { dependencyTelemetry },
    }
  )
}

export const __internalEvents = {
  clearAuthenticatedUserContext: () => {
    __internal__dispatchApplicationInsightsEvent<undefined>({
      aiVersion: AI_VERSION,
      action: AI_ACTIONS.__internal.clearAuthenticatedUserContext,
      payload: undefined,
    })
  },
}
