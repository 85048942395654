import { CircularProgress } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'

interface LazyLoadingProps {
  testSelector?: string
  timeout?: number
  component?: React.ReactNode
}

const LazyLoading: React.FC<LazyLoadingProps> = ({
  component,
  timeout = 500,
  testSelector,
}) => {
  const [showLoading, setShowLoading] = useState(false)
  const timeoutRef = useRef<ReturnType<typeof setInterval>>(null)

  useEffect(() => {
    if (!showLoading && timeoutRef.current === null) {
      timeoutRef.current = setTimeout(() => {
        setShowLoading(true)
      }, timeout)
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [timeoutRef, showLoading, setShowLoading, timeout])

  if (showLoading) {
    return <>{component ?? <CircularProgress data-testid={testSelector} />}</>
  }

  return null
}

export default LazyLoading
