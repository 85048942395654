import { __api_internals__ } from 'packages/app-shell-communication'
import userManagerAdapter, {
  setKlientUserManager,
  setKundUserManager,
} from 'features/Authentication/userManager'
import { UserType } from 'features/Authentication/helpers'
import { userManagerRedirectToAuthServer } from 'features/Authentication/userManager/redirect'
import { useEventListener } from 'utils/hooks'
import { useHistory } from 'react-router-dom'
import { useAuthentication } from 'features/Authentication/Hooks'

const eventNames = __api_internals__.eventNames

const useLoginFlowEventListeners = () => {
  const history = useHistory<{
    redirectUrl: string
  }>()
  const { logout } = useAuthentication()

  useEventListener(eventNames.userCreationFinished, () => {
    const removePossibleStaleDataAndSignInSilent = async () => {
      await userManagerAdapter.signinSilent()
    }
    void removePossibleStaleDataAndSignInSilent()
  })

  interface AuthenticationRedirectEvent extends CustomEvent {
    detail: { authenticationMethod: string; userType: UserType }
  }

  // Redirect to GrandID
  useEventListener<AuthenticationRedirectEvent>(
    eventNames.redirectToAuthServer,
    (event) => {
      const state = history.location.state
      const arbetsgivarinfoLinkId = new URLSearchParams(
        window.location.search
      ).get('linkId')

      if (event.detail.userType === UserType.client) {
        setKlientUserManager()
      }
      if (event.detail.userType === UserType.customer) {
        setKundUserManager()
      }

      userManagerRedirectToAuthServer({
        ...state,
        arbetsgivarinfoLinkId,
        authenticationMethod: event.detail.authenticationMethod,
      })
    }
  )

  useEventListener(eventNames.abortLogin, () => {
    // We invoke the logout, send the point of entry to backend and let them worry about taking us back
    logout()
  })
}

export default useLoginFlowEventListeners
