import React from 'react'
import { isNil, isEmpty, either, pipe, not } from 'ramda'

import { TableOfContentsLink } from './TableOfContentsLink'
import { TableOfContentLink } from 'state/PublicWebApi/Content'
import { Box, Typography } from '@mui/material'

interface TableOfContentsProps {
  items: TableOfContentLink[]
  title?: string
}

const onlyUnique = (
  value: TableOfContentLink,
  index: number,
  arr: TableOfContentLink[]
) => arr.findIndex((t) => t.headingId === value.headingId) === index

const shouldRender = pipe<TableOfContentLink[], boolean, boolean>(
  either(isNil, isEmpty),
  not
)

export const TableOfContents = ({
  items,
  title = 'Innehåll',
}: TableOfContentsProps) => (
  <>
    {shouldRender(items) ? (
      <Box component="nav" mb={7}>
        <Typography variant="h6" component="h2">
          {title}
        </Typography>
        <Box
          component="ol"
          sx={{
            listStyle: 'none',
            pl: 2,
            borderLeft: '2px solid rgba(204, 204, 204, 1)',
          }}
        >
          {items.filter(onlyUnique).map((item, index) => (
            <TableOfContentsLink key={index} item={item} />
          ))}
        </Box>
      </Box>
    ) : null}
  </>
)
