import classNames from 'classnames'
import React from 'react'
import MenuArea from 'apps/Site/DashboardMenu/MenuArea'

import s from './RightMenu.module.scss'
import { useSliceDispatch, useSliceStateSelector } from 'slices/hooks'
import { DrawerWrapper } from '../NewHeader/Mobile/Menu/Menu.styles'

interface RightMenuProps {
  testSelector?: string
  isNewHeader?: boolean
}

const RightMenu = ({ testSelector, isNewHeader }: RightMenuProps) => {
  const { smallDeviceMenuStatus } = useSliceStateSelector(
    (state) => state.slices.menus
  )
  const trigger = useSliceDispatch()
  const showMenu = smallDeviceMenuStatus === 'right'

  return isNewHeader ? (
    <DrawerWrapper
      data-testid={testSelector || 'right-menu-drawer'}
      hideBackdrop
      open={showMenu}
      anchor="right"
      isMittTrrMenu
    >
      <MenuArea
        onMenuLinkClickInMobileView={() =>
          trigger('setSmallDeviceMenuStatus', 'none')
        }
      />
    </DrawerWrapper>
  ) : (
    <nav
      className={classNames(s.MenuContainer, showMenu ? s.Show : s.Hidden)}
      data-testid={testSelector || 'right-menu-nav'}
    >
      <MenuArea
        onMenuLinkClickInMobileView={() =>
          trigger('setSmallDeviceMenuStatus', 'none')
        }
      />
    </nav>
  )
}

export default RightMenu
