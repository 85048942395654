import React from 'react'
import { styled } from '@mui/material'

export type UnorderedListProps = JSX.IntrinsicElements['ul']

export const StyledUl = styled('ul')(({ theme }) => ({
  marginTop: theme.spacing(0),
  marginBottom: theme.spacing(5),
  paddingInlineStart: theme.spacing(5),
  listStyle: 'none',
  '> li': {
    marginBottom: theme.spacing(2),
    position: 'relative',
    '&::before': {
      content: '""',
      background: theme.palette.primary.main,
      width: theme.spacing(1),
      height: theme.spacing(1),
      position: 'absolute',
      borderRadius: '50%',
      top: theme.spacing(1),
      left: theme.spacing(-3),
    },
    '> ol': {
      marginBottom: 0,
      marginTop: theme.spacing(2),
      paddingInlineStart: '36px',
    },
    '> ul': {
      marginBottom: 0,
      marginTop: theme.spacing(2),
      paddingInlineStart: theme.spacing(3),
    },
  },
}))

export const UnorderedList = (attribs: UnorderedListProps): JSX.Element => {
  return <StyledUl {...attribs} />
}
