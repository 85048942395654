/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Icon as MuiFontIcon } from '@mui/material'
import React from 'react'
import allowedIcons from './allowedIcons.json'

interface AllowedIcons {
  [key: string]: 1
}

const pascalToSnakeCase = (text: string): string => {
  return text
    ?.replace(/(.)([A-Z][a-z]+)/, '$1_$2')
    ?.replace(/([a-z0-9])([A-Z])/, '$1_$2')
    ?.toLowerCase()
}

type IconColor =
  | 'purple'
  | 'green'
  | 'red'
  | 'orange'
  | 'blue'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning'

export interface IconProps {
  iconName: string
  size?: 'small' | 'medium' | 'large'
  color?: IconColor
  customColor?: string
  customSize?: number
}
export const getMuiColor = (color: IconColor) => {
  switch (color) {
    case 'purple':
      return 'primary'
    case 'green':
      return 'success'
    case 'red':
      return 'error'
    case 'orange':
      return 'warning'
    case 'blue':
      return 'info'
    default:
      return color
  }
}

const FontIcon = ({
  iconName,
  size,
  color,
  customColor,
  customSize,
}: IconProps): JSX.Element => {
  const muiColor = getMuiColor(color)
  const snakeCaseIconName = pascalToSnakeCase(iconName)

  const foundIcon = (allowedIcons as AllowedIcons)[snakeCaseIconName]

  return (
    <MuiFontIcon
      fontSize={size}
      color={muiColor}
      sx={{ color: customColor, fontSize: customSize }}
    >
      {foundIcon ? snakeCaseIconName : 'favorite'}
    </MuiFontIcon>
  )
}

export default FontIcon
