import React from 'react'
import { Box, ListItem } from '@mui/material'
import {
  FooterNavigationItem,
  FooterContextSection,
} from 'PublicWebApi/Settings/types'

import SubSection from '../SubSection'

import {
  SectionHeading,
  SectionLink,
  SectionLinksWrapper,
} from './Section.styles'

interface SectionProps {
  section: FooterNavigationItem
}

const Section = ({ section }: SectionProps) => {
  return (
    <Box
      gap={2}
      display="flex"
      flexDirection="column"
      data-testid={`footersection-${section.title}`}
    >
      <SectionHeading href={section.url}>{section.title}</SectionHeading>
      <SectionLinksWrapper>
        {section?.children.map((sectionChild: FooterContextSection, index) => (
          <Box key={`contextsection-${index.toString()}`}>
            {sectionChild.url ? (
              <ListItem>
                <SectionLink
                  data-testid={`link-without-subtitle-${sectionChild.title}`}
                  url={sectionChild.url}
                  text={sectionChild.title}
                />
              </ListItem>
            ) : (
              <SubSection section={sectionChild} />
            )}
          </Box>
        ))}
      </SectionLinksWrapper>
    </Box>
  )
}

export default Section
