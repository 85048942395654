import React, { PropsWithChildren, useCallback, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

export const ScrollWrapper = ({ children }: PropsWithChildren<unknown>) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { listen, location } = useHistory<{ preventScroll?: boolean }>()
  const isMounted = useRef(false)

  useEffect(() => {
    const unlisten = listen(({ hash, state }) => {
      if (state?.preventScroll) return

      if (!hash) {
        window.scrollTo(0, 0)
      }
    })
    return () => {
      unlisten()
    }
  }, [listen])

  const scrollToPosition = useCallback(() => {
    if (location.hash) {
      const el = document.getElementById(decodeURI(location.hash.substr(1)))

      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    }
  }, [location.hash])

  useEffect(() => {
    if (!isMounted.current) {
      scrollToPosition()
      isMounted.current = true
    }
  }, [scrollToPosition, isMounted])

  return <>{children}</>
}
