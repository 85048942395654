import { TestimonialBlock, LoginBlock } from '@internal-helpers'

import {
  CookieWithdrawBlock,
  Editorial as EditorialBlock,
  StatisticsBlock,
  FeaturesBlock,
  CTABlock,
  PhotoTextBlock,
  ComparisonBlock,
  CardsBlock as CardsRowBlock,
  EditorialArticleListBlock,
  FAQBlock,
  VideoTextBlock,
  WidgetBlock,
  DashboardContentBlock,
  DashboardInformationBlock,
} from '../Blocks'

interface IBlockMap {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}

const blockMap: IBlockMap = {
  CookieWithdrawBlock,
  EditorialBlock,
  StatisticsBlock,
  FeaturesBlock,
  CTABlock,
  PhotoTextBlock,
  ComparisonBlock,
  TestimonialBlock,
  LoginBlock,
  CardsRowBlock,
  EditorialArticleListBlock,
  FAQBlock,
  VideoTextBlock,
  WidgetBlock,
  DashboardContentBlock,
  DashboardInformationBlock,
}

export default blockMap
