import React, { PropsWithChildren, useContext } from 'react'
import classnames from 'classnames'

import { DropdownMenuContext } from './DropdownMenu'
import s from './DropdownMenu.module.scss'

interface DropdownMenuToggleProps
  extends Partial<JSX.IntrinsicElements['button']> {
  testSelector?: string
  secondaryAction?: () => void
}

export const DropdownMenuToggle = ({
  children,
  className,
  testSelector,
  secondaryAction,
  ...props
}: PropsWithChildren<DropdownMenuToggleProps>) => {
  const { toggleDropdownMenu, isDropdownVisible } =
    useContext(DropdownMenuContext)

  const handleClick = () => {
    toggleDropdownMenu()
    secondaryAction?.()
  }

  return (
    <button
      onClick={handleClick}
      data-testid={testSelector}
      className={classnames(s.DropdownMenuToggle, className)}
      aria-haspopup="true"
      aria-expanded={isDropdownVisible}
      {...props}
    >
      {children}
    </button>
  )
}
