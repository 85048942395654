import { __api_internals__ } from './Event.names'

enum UserType {
  'client',
  'customer',
}

const eventNames = __api_internals__.eventNames

interface LoginProps {
  redirectUrl?: string
  userType: UserType
  historyAction: 'push' | 'replace'
}

const dispatchLoginEvent = ({
  redirectUrl,
  userType,
  historyAction,
}: LoginProps): void => {
  window.dispatchEvent(
    new CustomEvent(eventNames.login, {
      bubbles: true,
      detail: { redirectUrl, userType, historyAction },
    })
  )
}

/**
 * Dispatches event that starts login flow for anställd
 * @param {string} redirectUrl - optional url for where to redirect after login.
 * @param userType - Allows developer to tell the Public-web if a user should find themselves on client login or company login
 */
export const goToLogin = (
  redirectUrl?: LoginProps['redirectUrl'],
  userType: LoginProps['userType'] = UserType.client,
  historyAction: LoginProps['historyAction'] = 'push'
): void => {
  dispatchLoginEvent({
    redirectUrl: redirectUrl,
    userType: userType,
    historyAction,
  })
}

/**
 * Dispatches event that starts login flow for arbetsgivare
 * @param {string} redirectUrl - optional url for where to redirect after login. If not attached, user will return to where this event was dispatched
 */
export const goToLoginEmployer = (redirectUrl?: string): void => {
  goToLogin(redirectUrl, UserType.customer)
}

/**
 * Dispatches event that starts the logout flow
 */
export const goToLogout = (): void => {
  window.dispatchEvent(new CustomEvent(eventNames.logout, { bubbles: true }))
}

/**
 * Dispatches event that aborts login flow
 */
export const abortLogin = (): void => {
  window.dispatchEvent(
    new CustomEvent(eventNames.abortLogin, { bubbles: true })
  )
}

/**
 * Dispatches event for redirection from Loginflow-MicroFrontend to ID-server.
 * NOTE! Used only by the Loginflow-Microfrontend. All others should use goToLogin/goToLoginEmployer
 * @param {string} authenticationMethod - the chosen authentication method (e.g. BankID on this device or some such)
 * @param userType - Allows developer to tell the Public-web which user-type when redirecting to the Auth server
 */
export const redirectToAuthServer = (
  authenticationMethod = '',
  userType: UserType = UserType.client
): void => {
  window.dispatchEvent(
    new CustomEvent(eventNames.redirectToAuthServer, {
      bubbles: true,
      detail: { authenticationMethod, userType },
    })
  )
}

export const redirectToAuthServerEmployer = (
  authenticationMethod?: string
): void => {
  redirectToAuthServer(authenticationMethod, UserType.customer)
}

/**
 * Dispatches event for creation and updates token
 * NOTE! Used only by the Loginflow-Microfrontend. NO OTHER MF SHOULD USE THIS!!
 * @param redirect - do the subsequential redirect to redirecturl
 */
export const userCreationFinished = (redirect?: boolean): void => {
  window.dispatchEvent(
    new CustomEvent(eventNames.userCreationFinished, {
      bubbles: true,
      detail: redirect,
    })
  )
}
