import React from 'react'
import { Avatar, Icon } from '@mui/material'
import {
  StyledAvatarButton,
  StyledAvatarIcon,
  StyledSearchButton,
} from './HeaderButton.styles'
import { useCachedUserProfileState } from 'state/PublicWebApi/UserProfile'

interface IHeaderButtonProps {
  type: 'avatar' | 'search'
  text: string
  action: () => void
  isMobile: boolean
  isTablet?: boolean
  isLoggedIn?: boolean
  isSideMenuOpen?: boolean
}

interface ISearchButton {
  text: string
  isMobile: boolean
  action?: () => void
}

interface IAvatarButton {
  text: string
  isMobile: boolean
  isTablet?: boolean
  action?: () => void
  isLoggedIn?: boolean
  isSideMenuOpen?: boolean
}

const SearchButton = ({ text, isMobile, action }: ISearchButton) => (
  <StyledSearchButton
    role="link"
    aria-label={text}
    onClick={action}
    data-testid="header-search-button"
  >
    <Icon>search</Icon>
    {!isMobile && text}
  </StyledSearchButton>
)

const AvatarButton = ({
  text,
  action,
  isLoggedIn,
  isSideMenuOpen,
  isMobile,
  isTablet,
}: IAvatarButton) => {
  const { profileImages, imageFileName } = useCachedUserProfileState()

  const ariaAttributesMyTRRButton =
    isMobile || isTablet
      ? {
          'aria-haspopup': true,
          'aria-expanded': isSideMenuOpen,
          'aria-label': isSideMenuOpen
            ? 'Stäng Mitt TRR meny'
            : 'Öppna Mitt TRR meny',
        }
      : { 'aria-label': 'Gå till Mitt TRR', role: 'link' }

  const ariaAttributesLoginButton = { 'aria-label': 'Logga in', role: 'link' }

  return (
    <StyledAvatarButton
      onClick={action}
      {...(isLoggedIn ? ariaAttributesMyTRRButton : ariaAttributesLoginButton)}
      data-testid="header-avatar-button"
    >
      <Avatar
        {...(imageFileName && {
          src: profileImages?.small,
          alt: 'Profilbild',
        })}
        sx={{ height: '24px', width: '24px', backgroundColor: 'transparent' }}
      >
        <StyledAvatarIcon>account_circle</StyledAvatarIcon>
      </Avatar>
      {!isMobile && text}
    </StyledAvatarButton>
  )
}

const HeaderButton = ({
  text,
  type,
  action,
  isLoggedIn,
  isMobile,
  isTablet,
  isSideMenuOpen,
}: IHeaderButtonProps) => {
  return (
    <>
      {type === 'avatar' && (
        <AvatarButton
          text={text}
          action={action}
          isMobile={isMobile}
          isTablet={isTablet}
          isLoggedIn={isLoggedIn}
          isSideMenuOpen={isSideMenuOpen}
        />
      )}
      {type === 'search' && (
        <SearchButton text={text} action={action} isMobile={isMobile} />
      )}
    </>
  )
}

export default HeaderButton
