import React from 'react'
import DownloadingOutlined from '@mui/icons-material/DownloadingOutlined'
import { Link } from '@mui/material'

interface IFileDownloadProps {
  relLink: string
  label?: string
  title?: string
  mediaUrl?: string
}

const FileDownload = ({
  relLink,
  label,
  title = 'Ladda ner',
  mediaUrl = '',
}: IFileDownloadProps): JSX.Element => (
  <Link underline="hover" href={`${mediaUrl}${relLink}`} title={title}>
    <DownloadingOutlined
      fontSize={'inherit'}
      color="primary"
      sx={{ mr: 1, position: 'relative', top: '0.1em' }}
    />
    {label}
  </Link>
)

export default FileDownload
