import { Box } from '@mui/material'
import { widgetType } from 'state/PublicWebApi/Settings'
import React from 'react'
import { WidgetLoader } from 'features/SystemLoaders/WidgetLoader'

const WidgetBlock = ({ content }: { content: { widgetType: widgetType } }) => {
  return (
    <Box
      sx={{
        ':has([data-mf-loaded] > *)': { mb: 3 },
        ':has(.widgetError) > *': { mb: 3 },
      }}
    >
      <WidgetLoader widgetType={content.widgetType} />
    </Box>
  )
}

export default WidgetBlock
