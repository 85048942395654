import { customEventData } from 'packages/app-shell-communication'
import { removeTrailingSlash } from './removeTrailingSlash'
import { getWashedQueryString } from './getWashedQueryString'

export const extractSubViewUrlPart = (e: customEventData) => {
  const subView = e.event_data.find((data) => data.key === 'sub_view_name')
  const subViewValueUrlPart = subView?.value
    ?.toLowerCase()
    ?.split('_')
    ?.join('-')
  return subViewValueUrlPart
}

export const extractSyntheticPathFromCustomEvent = (
  e: customEventData,
  href: string
) => {
  const url = new URL(href)
  const eventItemUrlPart = e.event_item.toLowerCase().split('_').join('-')
  const subViewValueUrlPart = extractSubViewUrlPart(e)
  const prefix = url.pathname === '/' ? '' : '/'
  const syntheticPart = subViewValueUrlPart
    ? `${prefix}${eventItemUrlPart}/${subViewValueUrlPart}`
    : ''

  return (
    removeTrailingSlash(url.pathname) +
    syntheticPart +
    getWashedQueryString(href)
  )
}
