import React, { PropsWithChildren } from 'react'
import {
  useAuthenticationEventListeners,
  useForceMedarbetareLogin,
  useLoginOrLogoutAllOpenTabs,
  useProcessIdServerCallbackResponse,
  useUserManagerEventListeners,
  useDispatchAuthOnMount,
  useClearExpiredUser,
  useAccessTokenExpiringListener,
} from './Hooks'

const AuthenticationHandler = ({ children }: PropsWithChildren) => {
  useUserManagerEventListeners()
  useAccessTokenExpiringListener()
  useAuthenticationEventListeners()
  useDispatchAuthOnMount()
  useForceMedarbetareLogin()
  useLoginOrLogoutAllOpenTabs()

  const callbackProcessCompleted = useProcessIdServerCallbackResponse()
  if (callbackProcessCompleted) {
    return <>{children}</>
  }
  return null
}

const AuthenticationWrapper = ({ children }: PropsWithChildren) => {
  const completed = useClearExpiredUser()
  if (completed) {
    return <AuthenticationHandler>{children}</AuthenticationHandler>
  }
  return null
}

export default AuthenticationWrapper
