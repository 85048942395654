import React, { useCallback, useEffect, useState } from 'react'
import { Box, Typography, styled } from '@mui/material'
import { useAuthentication } from 'features/Authentication/Hooks'
import { matchPath, useLocation } from 'react-router-dom'

import { Link } from '../MenuBlocks'
import MenuBlockMapper from '../MenuBlockMapper'

import { useCachedSettingsState } from 'state/PublicWebApi'
import { MenuBlock, MenuRowBreakBlock } from 'state/PublicWebApi/Settings'
import { useCachedUserProfileState } from 'state/PublicWebApi/UserProfile'

const isMenuRowBreakBlock = (
  menuBlock: MenuBlock
): menuBlock is MenuRowBreakBlock => {
  return menuBlock.blockType === 'RowBreakBlock'
}

export const filterOutDoubleRowBreakBlocks = (
  menuElements: MenuBlock[]
): MenuBlock[] => {
  return menuElements?.filter((menuBlock, index) => {
    return !(
      isMenuRowBreakBlock(menuBlock) &&
      isMenuRowBreakBlock(menuElements[index - 1])
    )
  })
}

const MenuAreaNav = styled('nav')(({ theme }) => ({
  padding: `${theme.spacing(3)} 0`,
  width: '100%',
  height: '100vh',
  position: 'sticky',
  top: 0,
  overflowY: 'auto',
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    padding: `${theme.spacing(6)} 0`,
    width: '288px',
  },
  [theme.breakpoints.down('xl')]: {
    marginRight: theme.spacing(6),
  },
}))

const MenuList = styled('ul')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  listStyle: 'none',
  margin: 0,
  padding: 0,
}))

interface MenuAreaProps {
  onMenuLinkClickInMobileView?: () => void
}

const MenuArea = ({ onMenuLinkClickInMobileView }: MenuAreaProps) => {
  const userProfile = useCachedUserProfileState()
  const companyName = userProfile?.kundProperties?.foretag?.name
  const { myTrrNavigation, trrBusinessNavigation } = useCachedSettingsState()
  const { logout } = useAuthentication()
  const location = useLocation()
  const [activeMenuElement, setActiveMenuElement] = useState(-1)

  const menuHeading =
    myTrrNavigation?.menuHeading || trrBusinessNavigation?.menuHeading
  const menuElements = filterOutDoubleRowBreakBlocks(
    myTrrNavigation?.menuElements || trrBusinessNavigation?.menuElements
  )

  useEffect(() => {
    menuElements.forEach((element, index) => {
      if (
        element.blockType === 'MenuSingleLinkBlock' &&
        element.url === location.pathname
      ) {
        setActiveMenuElement(index)
      } else if (element.blockType === 'MenuGroupBlock') {
        if (
          element.links.some((link) => {
            return matchPath(location.pathname, {
              path: link.url,
              exact: false,
              strict: false,
            })
          }) &&
          index !== activeMenuElement
        ) {
          setActiveMenuElement(index)
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onActiveMenuElementToggle = useCallback(
    (index: number) => {
      index !== activeMenuElement
        ? setActiveMenuElement(index)
        : setActiveMenuElement(-1)
    },
    [activeMenuElement, setActiveMenuElement]
  )

  return (
    <MenuAreaNav data-testid="dashboard-menu-area">
      <Box mb={'24px'}>
        <Typography variant="h4" component="h2" padding={'0 24px'} mb={'8px'}>
          {menuHeading}
        </Typography>
        {companyName && (
          <Typography
            padding="0 24px"
            color="text.secondary"
            variant="subtitle2"
            component="p"
          >
            {companyName}
          </Typography>
        )}
      </Box>
      <MenuList>
        {menuElements?.length > 0 &&
          menuElements.map((menuBlock, index) => (
            <MenuBlockMapper
              key={`menu-area-block-${index.toString()}`}
              {...{
                ...menuBlock,
                ...(onMenuLinkClickInMobileView && {
                  onClickAction: onMenuLinkClickInMobileView,
                }),
                ...{
                  toggleActiveMenuElement: () =>
                    onActiveMenuElementToggle(index),
                  isMenuGroupOpen: activeMenuElement === index,
                },
              }}
            />
          ))}
        <Link
          blockType="MenuSingleLinkBlock"
          heading="Logga ut"
          icon="Logout"
          onClickAction={logout}
        />
      </MenuList>
    </MenuAreaNav>
  )
}

export default MenuArea
