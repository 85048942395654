import React, { useState } from 'react'
import { Box, Button, IconButton } from '@mui/material'
import { deleteCookie, getCookieValue } from 'utils/cookies'

import Close from '@mui/icons-material/Close'
import BannerHeading from './BannerHeading'

const VersionBanner = () => {
  const versionCookieStrings = {
    appshellVersion: getCookieValue('app-shell-version'),
    mfName: getCookieValue('mf-application'),
    mfVersion: getCookieValue('mf-version'),
  }

  const [isVisible, setIsVisible] = useState(true)

  if (
    !Object.values(versionCookieStrings).some((cookie) => cookie) ||
    !isVisible
  ) {
    return null
  }

  const hideBanner = () => setIsVisible(false)

  const restoreVersion = () => {
    deleteCookie('app-shell-version')
    deleteCookie('mf-application')
    deleteCookie('mf-version')
    window.location.assign(window.location.pathname)
  }

  return (
    <Box
      data-testid="version-banner"
      bgcolor={'info.main'}
      display="flex"
      justifyContent="space-between"
      alignItems={'center'}
      flexWrap="wrap"
      px={1}
      py={1}
    >
      <BannerHeading
        appshellVersion={versionCookieStrings.appshellVersion}
        mfName={versionCookieStrings.mfName}
        mfVersion={versionCookieStrings.mfVersion}
      />
      <Box>
        <Button
          onClick={restoreVersion}
          variant="text"
          sx={{
            color: 'white',
            borderColor: 'white',
            textDecoration: 'underline',
          }}
          size="small"
        >
          Gå till huvudversion
        </Button>
        <IconButton
          onClick={hideBanner}
          sx={{ color: 'white' }}
          data-testid="close-banner-button"
        >
          <Close />
        </IconButton>
      </Box>
    </Box>
  )
}

export default VersionBanner
