import React, { useCallback } from 'react'
import { List, ListItem, Typography } from '@mui/material'
import { FoldoutProps } from 'PublicWebApi/Settings/types'
import { useTrackCustomClick } from 'features/Telemetry/GoogleTagManager/Hooks/useTrackCustomClick'

import {
  FoldoutAreaWrapper,
  FoldoutAreaRow,
  FoldoutBanner,
  FoldoutBannerSection,
  FoldoutLink,
  FoldoutLinksSection,
} from './FoldoutArea.styles'

interface FoldoutAreaProps {
  foldoutArea: FoldoutProps[]
  closeFoldout: () => void
}

const FoldoutArea = ({ foldoutArea, closeFoldout }: FoldoutAreaProps) => {
  const trackCustomClick = useTrackCustomClick()

  const onFoldoutLinkClick = useCallback(
    (label: string, href: string) => {
      trackCustomClick('foldout link', {
        label: `Klick på länk i foldout-menyn: -${label}`,
        href: href,
      })
      closeFoldout()
    },
    [trackCustomClick, closeFoldout]
  )

  return (
    <FoldoutAreaWrapper>
      <FoldoutAreaRow>
        {foldoutArea?.slice(0, 3).map((item, index) => (
          <FoldoutLinksSection key={`foldoutlinkssection-${index.toString()}`}>
            <Typography
              data-testid={'foldouttitle-' + `${item.title}`}
              marginBottom={1.25}
              variant="subtitle2"
              component="h3"
              color="text.secondary"
            >
              {item.title}
            </Typography>
            <List>
              {item.children?.slice(0, 5).map((link, index) => (
                <ListItem key={`foldoutlink-${index.toString()}`}>
                  <FoldoutLink
                    onClick={() => onFoldoutLinkClick(link.title, link.url)}
                    data-testid={'foldoutlink-' + `${link.title}`}
                    disableRipple
                    href={link.url}
                  >
                    {link.title}
                  </FoldoutLink>
                </ListItem>
              ))}
            </List>
          </FoldoutLinksSection>
        ))}
        <FoldoutBannerSection>
          <FoldoutBanner />
        </FoldoutBannerSection>
      </FoldoutAreaRow>
    </FoldoutAreaWrapper>
  )
}

export default FoldoutArea
