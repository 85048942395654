import { useMemo } from 'react'
import { omit } from 'ramda'
import { useSliceStateSelector } from 'slices/hooks'
import { useAuthentication } from 'features/Authentication/Hooks'
import { useGetSignalRConnection } from 'apps/Site/hooks'

import { mergeEpiBlockContent } from '../../utils'

import { MicroFrontendSharedState } from './useLegacySharedState.types'
import { EpiEditorialBlock } from 'PublicWebApi/Settings'
import {
  useCachedContentQuery,
  useCachedFeatureFlagsState,
  useCachedUserProfileState,
} from 'PublicWebApi'

const useLegacySharedState = (
  currentKey: string,
  editorBlockContent: EpiEditorialBlock
): MicroFrontendSharedState => {
  const { profile, idToken } = useAuthentication()
  const signalRConnection = useGetSignalRConnection()
  const { data: contentData } = useCachedContentQuery()
  const mergedEpiBlockContent = mergeEpiBlockContent(
    contentData?.properties?.blocks
  )
  const currentPageImages = contentData?.properties?.currentPageImages
  const device = useSliceStateSelector(
    (state) => state.slices.device.deviceSize
  )
  const userProfile = useCachedUserProfileState()
  const enabledFeatures = useCachedFeatureFlagsState()
  const content = useMemo(
    () => ({
      ...mergedEpiBlockContent,
      ...editorBlockContent,
      urlKey: currentKey,
      currentPageImages,
    }),
    [mergedEpiBlockContent, editorBlockContent, currentKey, currentPageImages]
  )

  const authentication = useMemo(
    () => ({
      userRoles: profile.userRoles,
      userGivenName: profile.name,
      idToken: idToken,
      profile: profile,
    }),
    [idToken, profile]
  )

  const user = useMemo(
    () => omit(['setKundPrimaryRequest'], userProfile),
    [userProfile]
  )

  return {
    content,
    device,
    enabledFeatures,
    idToken,
    url: contentData?.properties.url,
    user,
    profileImages: userProfile?.profileImages,
    authentication,
    currentKey,
    signalRConnection,
    isTrrNewConcept: true, // * We're keeping this for a while, to give MF devs a chance to clean their code
  }
}

export default useLegacySharedState
