/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react'
import baseElementMap from './baseElementMap'
import { HTMLMapperProps } from './types'
import { parseBody } from './helpers/parseBody'

/**
 * Map Epi data to React components
 * @param {string} body - HTML body to map to React components
 * @param {Record<string, any>} [replaceValues={}] - values to replace in body
 * @param {WrapperTypes} [wrapper='span'] - Should the resulting components be wrapped in a span or div?
 * @param {string} - custom class name for styling from consuming code
 * @param {string} - base url to use for media links
 * @param {boolean} - session state, logged in or not
 * @param {ElementMap} [elements={}] - Map of elements to extend html mapper with
 */
const HTMLMapper = ({
  body,
  replaceValues = {},
  wrapper: Wrapper = 'span',
  className,
  mediaUrl,
  isLoggedIn,
  elements = {},
  allowedTags,
}: HTMLMapperProps): JSX.Element => {
  const composedElementMap = { ...baseElementMap, ...elements }

  return (
    <Wrapper className={className}>
      {parseBody(body, composedElementMap, {
        replaceValues,
        mediaUrl,
        isLoggedIn,
        allowedTags,
      })}
    </Wrapper>
  )
}

export default HTMLMapper
