import React, { useCallback } from 'react'
import { Box, Typography, styled } from '@mui/material'
import MenuButton from 'apps/Site/components/MenuButton'

import { MenuLinkBlock } from 'state/PublicWebApi/Settings'
import { FontIcon } from '@internal-helpers'

export interface LinkInterface extends Omit<MenuLinkBlock, 'url'> {
  url?: string
  onClickAction?: () => void
  toggleActiveMenuElement?: () => void
}

const LinkWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  width: '100%',
  gap: theme.spacing(2),
}))

const Link = ({
  heading,
  icon,
  url,
  onClickAction,
  toggleActiveMenuElement,
}: LinkInterface) => {
  const onClick = useCallback(() => {
    toggleActiveMenuElement?.()
    onClickAction?.()
  }, [onClickAction, toggleActiveMenuElement])

  return (
    <li>
      <MenuButton
        {...{
          onClickAction: onClick,
          ...(url && { url: url }),
        }}
      >
        <LinkWrapper>
          {icon && <FontIcon iconName={icon} size="small" />}
          <Typography variant="subtitle2" component="span">
            {heading}
          </Typography>
        </LinkWrapper>
      </MenuButton>
    </li>
  )
}

export default Link
