import React from 'react'

import HeroBlock, {
  HeroBlockProps,
} from 'apps/Site/Epi/Blocks/HeroBlock/HeroBlock'
import { HeadingAndPreambleBlock } from 'apps/Site/Epi/Blocks'
import { HeadingAndPreambleBlockContent } from 'apps/Site/Epi/Blocks/HeadingAndPreambleBlock/HeadingAndPreambleBlock'

export interface HeroAreaPanelProps {
  type: string
  properties: HeroBlockProps | HeadingAndPreambleBlockContent
}

const HeroAreaPanel = ({ type, properties }: HeroAreaPanelProps) => {
  switch (type) {
    case 'HeroBlock':
      return <HeroBlock content={properties as HeroBlockProps} />
    case 'HeadingPreambleBlock':
      return (
        <HeadingAndPreambleBlock
          content={properties as HeadingAndPreambleBlockContent}
        />
      )
    default:
      return <></>
  }
}

export default HeroAreaPanel
