import {
  LogLevel,
  HubConnectionBuilder,
  HubConnection,
  IHttpConnectionOptions,
} from '@microsoft/signalr'

import configService from 'utils/configService'

export type hubRoles = 'medarbetare' | 'klient'

export const getConnection = (
  connectionUrl: 'medarbetare' | 'klient',
  settings: IHttpConnectionOptions
): HubConnection =>
  new HubConnectionBuilder()
    .withUrl(`${configService.config.API_URL}/${connectionUrl}`, settings)
    .configureLogging(LogLevel.None)
    .build()

export const getHubTypeFromRole = (roles: string[] | null): hubRoles | null => {
  if (roles) {
    const klientRoles = [
      'PausadKlient',
      'AvslutadKlient',
      'Klient',
      'TIAAktiv',
      'TIAOprovad',
      'TIAAvslutad',
      'TIOAktiv',
      'TIOOprovad',
      'TIOAvslutad',
    ]
    const arbetsgivareRoles = [
      'KundOmfattas',
      'KundOmfattasEj',
      'KundAvtalAvslutat',
    ]
    const medarbetareRoles = ['Medarbetare']

    const matchRole = (roleFilter: string[], roles: string[]) =>
      roles.some((item) => roleFilter.includes(item))

    const isKlient = matchRole(klientRoles, roles)
    const isArbetsgivare = matchRole(arbetsgivareRoles, roles)
    const isMedarbetare = matchRole(medarbetareRoles, roles)

    if (isKlient || isArbetsgivare) {
      return 'klient'
    }
    if (isMedarbetare) {
      return 'medarbetare'
    }
    return null
  }
  return null
}
